import React from 'react'
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useAuthContext } from 'hooks/useAuthContext';
import Spectator from 'layouts/Spectator';
import GuideLayout from 'layouts/Guide';
export default function App() {
    const { user,authIsReady }=useAuthContext();
  return (
    <div>
      {authIsReady && (
        <BrowserRouter basename={"/app"}>
          <Routes>
            {user && user.role === 1 && (
              <>
                <Route path="*" element={<Navigate to="/admin/index" replace />} />
                <Route path="/admin/*" element={<AdminLayout />} />
                <Route path="/spectator/*" element={<Spectator />} />
              </>
            )}
            {user && user.role === 2 && (
              <>
                <Route path="*" element={<Navigate to="/guide/index" replace />} />
                <Route path="/guide/*" element={<GuideLayout />} />
                <Route path="/spectator/*" element={<Spectator />} />
              </>
            )}
            {!user && (
              <>
                <Route path="/spectator/*" element={<Spectator />} />
                <Route path="*" element={<Navigate to="/spectator/index" replace />} />
                <Route path="/auth/*" element={<AuthLayout />} />
              </>
            )}
          </Routes>
        </BrowserRouter>
      )}
    </div>
  )
}
