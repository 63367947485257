import React, { useEffect, useState } from 'react';
import { Table, Col, Card, CardHeader, Row } from 'reactstrap';

const HeadcountTable = () => {
  const [data, setData] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_NODE_SERVER}/api/stats/upcomingHeadcountTableFormat/`);
      const result = await response.json();
      console.log(result)
      setData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  const renderTableRows = (dayData) => {
    const { timeslots } = dayData;
    const timeslotKeys = Object.keys(timeslots);
    
    return (
      <>
        <tr>
          <th scope="row" style={{ fontSize: '1.5em' }}>Adults</th>
          {timeslotKeys.map((slot) => (
            <td key={slot} style={{ fontSize: '1.5em' }}>{timeslots[slot].adults_count}</td>
          ))}
        </tr>
        <tr>
          <th scope="row" style={{ fontSize: '1.5em' }}>Kids</th>
          {timeslotKeys.map((slot) => (
            <td key={slot} style={{ fontSize: '1.5em' }}>{timeslots[slot].children_count}</td>
          ))}
        </tr>
        <tr>
          <th scope="row" style={{ fontSize: '1.5em' }}>Total</th>
          {timeslotKeys.map((slot) => (
            <td key={slot} style={{ fontSize: '1.5em' }}>
              {timeslots[slot].adults_count + timeslots[slot].children_count}
            </td>
          ))}
        </tr>
      </>
    );
  };

  return (
    <div>
      {data.upcomingHeadcount.map((dayData, index) => (
        <Col className="mb-5 pb-5 mb-xl-0" xl="12" key={index}>
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0" style={{ fontSize: '2em' }}>{dayData.day}, {dayData.date}</h3>
                </div>
              </Row>
            </CardHeader>
            <div className="table-responsive">
              <Table className="align-items-center table-flush responsive">
                <thead className="thead-light">
                  <tr>
                    <th style={{ fontSize: '1.5em' }} />
                    <th scope="col" style={{ fontSize: '1.5em' }}>Morning</th>
                    <th scope="col" style={{ fontSize: '1.5em' }}>Late Morning</th>
                    <th scope="col" style={{ fontSize: '1.5em' }}>Afternoon</th>
                    <th scope="col" style={{ fontSize: '1.5em' }}>Late Afternoon</th>
                    <th scope="col" style={{ fontSize: '1.5em' }}>Evening</th>
                    <th scope="col" style={{ fontSize: '1.5em' }}>Birthday 1</th>
                    <th scope="col" style={{ fontSize: '1.5em' }}>Birthday 2</th>
                    <th scope="col" style={{ fontSize: '1.5em' }}>Slot 1</th>
                    <th scope="col" style={{ fontSize: '1.5em' }}>Slot 2</th>
                  </tr>
                </thead>
                <tbody>
                  {renderTableRows(dayData)}
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
      ))}
    </div>
  );
};

export default HeadcountTable;
