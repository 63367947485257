import { useEffect, useState } from "react";
import classnames from "classnames";
import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";
import Header from "components/Headers/Header.js";
import ExportData from "pages/admin/components/ExportData";

const Index = (props) => {
  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState("data1");
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [monthlyRevenue, setMonthlyRevenue] = useState([]);
  const [weeklyRevenue, setWeeklyRevenue] = useState([]);
  const [monthlyBookings, setMonthlyBookings] = useState([]);
  const [chartData1, setChartData1] = useState(null);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
    if (index === 1) {
      setChartData({
        labels: monthlyRevenue.map((item) => item.month_name),
        datasets: [
          {
            label: "Revenue",
            data: monthlyRevenue.map((item) => item.total_revenue),
            fill: true,
          },
        ],
      });
    } else {
      fetch(`${process.env.REACT_APP_NODE_SERVER}/api/stats/weeklyRevenue`)
        .then((response) => response.json())
        .then((data) => {
          // Prepare data for weekly revenue chart
          const weekLabels = [];
          const weekData = [];
  
          data.weeklyRevenue.forEach((week) => {
            week.days.forEach((day) => {
              weekLabels.push(`${week.week} - ${day.day_name}`);
              weekData.push(day.total_revenue);
            });
          });
  
          setChartData({
            labels: weekLabels,
            datasets: [
              {
                label: "Revenue",
                data: weekData,
                fill: true,
              },
            ],
          });
        })
        .catch((error) =>
          console.error("Error fetching weekly data:", error)
        );
    }
  };
  
  useEffect(() => {
    fetch(`${process.env.REACT_APP_NODE_SERVER}/api/stats/monthlyRevenue`)
      .then(response => response.json())
      .then(data => {
        setMonthlyRevenue(data.monthlyRevenue);
        if (activeNav === 1) {
          setChartData({
            labels: data.monthlyRevenue.map(item => item.month_name),
            datasets: [
              {
                label: 'Revenue',
                data: data.monthlyRevenue.map(item => item.total_revenue),
                fill: true,
              },
            ],
          });
        }
      })
      .catch(error => console.error('Error fetching monthly data:', error));
      fetch(`${process.env.REACT_APP_NODE_SERVER}/api/stats/lastWeekRevenue/${new Date().toISOString().split('T')[0]}`)
      .then(response => response.json())
      .then(data => {
        setWeeklyRevenue(data.lastWeekRevenue);
        if (activeNav === 2) {
          // Prepare data for weekly revenue chart
          const weekLabels = data.lastWeekRevenue.map(day => day.day_name);
          const weekData = data.lastWeekRevenue.map(day => day.total_revenue);

          setChartData({
            labels: weekLabels,
            datasets: [
              {
                label: 'Revenue',
                data: weekData,
                fill: true,
              },
            ],
          });
        }
      })
      .catch(error => console.error('Error fetching weekly data:', error));
  }, [activeNav]);
  useEffect(() => {
    // Fetch total bookings for the past 6 months from the API
    fetch(`${process.env.REACT_APP_NODE_SERVER}/api/stats/pastSixMonthsBookings`)
      .then((response) => response.json())
      .then((data) => {
        const monthlyBookings = data.monthlyBookings;
        // Prepare data for total orders chart
        setChartData1({
          labels: monthlyBookings.map((item) => item.monthName),
          datasets: [
            {
              label: "Total Orders",
              data: monthlyBookings.map((item) => item.totalBookings),
              fill: true,
            },
          ],
        })  
      })
      .catch((error) =>
        console.error("Error fetching monthly bookings data:", error)
      );
  }, []);

  
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="text-white mb-0">Sales value</h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 1,
                          })}
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">Month</span>
                          <span className="d-md-none">M</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 2,
                          })}
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">Past Week</span>
                          <span className="d-md-none">W</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Line
                    data={chartData}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        x: {
                          display: true,
                          title: {
                            display: true,
                            text: activeNav === 1 ? 'Month' : 'Week - Day',
                            color: '#ffffff',
                            font: {
                              size: 20,
                            },
                          },
                        },
                        y: {
                          display: true,
                          title: {
                            display: true,
                            text: 'Revenue',
                            color: '#ffffff',
                            font: {
                              size: 20,
                            },
                          },
                        },
                      },
                      plugins: {
                        legend: {
                          display: true,
                          position: 'bottom',
                          labels: {
                            color: '#ffffff',
                          },
                        },
                      },
                    }}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Performance
                    </h6>
                    <h2 className="mb-0">Total orders</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {chartData1 && <div className="chart">
                  <Bar data={chartData1} />
                </div>}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="mt-5">
          <ExportData/>
        </div>
        {/* <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Page visits</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Page name</th>
                    <th scope="col">Visitors</th>
                    <th scope="col">Unique users</th>
                    <th scope="col">Bounce rate</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">/argon/</th>
                    <td>4,569</td>
                    <td>340</td>
                    <td>
                      <i className="fas fa-arrow-up text-success mr-3" /> 46,53%
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">/argon/index.html</th>
                    <td>3,985</td>
                    <td>319</td>
                    <td>
                      <i className="fas fa-arrow-down text-warning mr-3" />{" "}
                      46,53%
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">/argon/charts.html</th>
                    <td>3,513</td>
                    <td>294</td>
                    <td>
                      <i className="fas fa-arrow-down text-warning mr-3" />{" "}
                      36,49%
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">/argon/tables.html</th>
                    <td>2,050</td>
                    <td>147</td>
                    <td>
                      <i className="fas fa-arrow-up text-success mr-3" /> 50,87%
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">/argon/profile.html</th>
                    <td>1,795</td>
                    <td>190</td>
                    <td>
                      <i className="fas fa-arrow-down text-danger mr-3" />{" "}
                      46,53%
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Social traffic</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Referral</th>
                    <th scope="col">Visitors</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Facebook</th>
                    <td>1,480</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">60%</span>
                        <div>
                          <Progress
                            max="100"
                            value="60"
                            barClassName="bg-gradient-danger"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Facebook</th>
                    <td>5,480</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">70%</span>
                        <div>
                          <Progress
                            max="100"
                            value="70"
                            barClassName="bg-gradient-success"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Google</th>
                    <td>4,807</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">80%</span>
                        <div>
                          <Progress max="100" value="80" />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Instagram</th>
                    <td>3,678</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">75%</span>
                        <div>
                          <Progress
                            max="100"
                            value="75"
                            barClassName="bg-gradient-info"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">twitter</th>
                    <td>2,645</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">30%</span>
                        <div>
                          <Progress
                            max="100"
                            value="30"
                            barClassName="bg-gradient-warning"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row> */}
      </Container>
    </>
  );
};

export default Index;
