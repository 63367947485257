import { useFetch } from 'hooks/useFetch'
import React, { useEffect, useState } from 'react'
import {
    Spinner
  } from "reactstrap";
import AllBookingsTable from './components/AllBookingsTable';

export default function AllBookings() {
    const [booking_date,setBookingDate] = useState(localStorage.getItem('Date') ? JSON.parse(localStorage.getItem('Date')) : new Date().toISOString().split('T')[0]);
    const [guide,setGuide] = useState(localStorage.getItem('filters') ? JSON.parse(localStorage.getItem('filters')).filterGuide :'');
    const [slot,setSlot] = useState(localStorage.getItem('filters') ? JSON.parse(localStorage.getItem('filters')).filterSlot :'');
    const [slotName,setSlotName] = useState(localStorage.getItem('filters') ? JSON.parse(localStorage.getItem('filters')).filterSlotName :'');
    const [picnickerName,setPicnickerName] =useState(localStorage.getItem('filters') ? JSON.parse(localStorage.getItem('filters')).filterPicnickerName :'');
    const [email,setEmail] = useState(localStorage.getItem('filters') ? JSON.parse(localStorage.getItem('filters')).filteremail :'');
    const [contact,setContact] = useState(localStorage.getItem('filters') ? JSON.parse(localStorage.getItem('filters')).fitlerContact :'');
    const {data:slotData,fetchIsPending:slotFetchPending} = useFetch(`${process.env.REACT_APP_NODE_SERVER}/api/slot/getAll`)
    const [tab,setTab] = useState('0')
    const {data,fetchIsPending,fetchData} = useFetch(`${process.env.REACT_APP_NODE_SERVER}/api/booking/getAll?booking_date=${booking_date}&guide=${guide}&slot_id=${slot}&name=${picnickerName}&email=${email}&contact=${contact}&slot_name=${slotName}&bookingType=${tab}`);
  const { data: packageData } = useFetch(`${process.env.REACT_APP_NODE_SERVER}/api/package/get/1`);
  
  const [bithdayPrice,setBirthdayPrice] = useState(null);
  useEffect(()=>{
    if(packageData && packageData.status === 200){
      setBirthdayPrice(packageData.package.price);
    }
  },[packageData])

  const handleChangeTab = (selectedTab) => {
      setTab(selectedTab)
    }

    const handleChangeDate = (selectedDate) =>{
      localStorage.setItem("Date",JSON.stringify(selectedDate));
      setBookingDate(selectedDate);
    }
    const handleGuideChange = (selectedGuide) =>{
      setGuide(selectedGuide);
    }
    const handlePicknerNameChange = (name) =>{
      console.log(name)
      setPicnickerName(name);
    }
    const handlePicknerEmailChange = (email) =>{
      setEmail(email);
    }
    
    const handleSlotChange = (slectedSlot) =>{
      setSlot(slectedSlot);
    }
    const handleSlotNameChange = (slot_name) =>{
      setSlotName(slot_name);
    }
    const handleContactChange = (contact) =>{
      setContact(contact);
    }
    const handleReset = () =>{
      setGuide('');
      setSlot('');
      setPicnickerName('');
      setEmail('');
      setContact('');
      setSlotName('');
    }
    useEffect(()=>{
      if(booking_date === ""){
        localStorage.removeItem('Date');
      }
    },[booking_date])
  return (
    <div className='pt-8'>
        {(fetchIsPending || slotFetchPending) &&
          <div className="text-center">
            <Spinner color="primary" style={{ height: '3rem',width: '3rem'}}></Spinner>
          </div>
        }
        {data && !fetchIsPending && slotData && !slotFetchPending &&
        <AllBookingsTable 
          data={data} 
          booking_date={booking_date} 
          guide={guide} 
          picnickerName={picnickerName}
          handleGuideChange={handleGuideChange} 
          slot={slot}
          email={email}
          contact={contact}
          slotName={slotName}
          handleSlotChange={handleSlotChange}
          handleChangeDate={handleChangeDate}
          handlePicknerNameChange={handlePicknerNameChange}
          handlePicknerEmailChange={handlePicknerEmailChange}
          handleContactChange={handleContactChange}
          handleSlotNameChange={handleSlotNameChange}
          handleReset={handleReset}
          slotData={slotData}
        bithdayPrice={bithdayPrice}
        fetchData={fetchData}
        handleChangeTab = {handleChangeTab}
          tab={tab}
          /> }
    </div>
  )
}
