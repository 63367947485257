import { usePost } from "hooks/usePost";
import React, { useEffect, useState } from "react";
import {
  Button,
  CustomInput,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

export default function SchoolPicnic(
  { 
    slotData, 
    selectedDate, 
    handlePicnicBooking, 
    currentDayOfWeek,
    handleSelectedSlotChange,
    handleTotalPriceChange,
    handleNameChange,
    handleEmailChange, 
    handleContactChange,
    selectedSlot,
    totalPrice,
    name,
    email,
    contact,
    toggleModal
  }
) {
  const [adultNum, setAdultNum] = useState(1);
  const [childrenNum, setChildrenNum] = useState(0);
  const [supportStaff, setSupportStaff] = useState(0);
  const { postData, resData, postError, isPending } = usePost();
  const [slotsLeft, setSlotsLeft] = useState(null);
  const [isSlotFull, setIsSlotFull] = useState(false);
  const [withFood, setWithfood] = useState(0);
  const [foodCost, setFoodCost] = useState(250);
  const [alreadyPaid, setAlreadyPaid] = useState(0);
  const [discount,setDiscount] = useState(`noDiscount`);
  const [discountAmount,setDiscountAmount] = useState(0);
  const handleRadioChange = (slot) => {
    console.log(slot);
    handleSelectedSlotChange(slot);
  };
  const checkSlotAvailability = async () => {
    setSlotsLeft(null);
    const payload = {
      booking_date: selectedDate.formattedDate,
      max_adults: selectedSlot.max_adults,
      requestingAdults: Number(adultNum),
      slot_id: selectedSlot.slot_id,
    };
    console.log(payload);
    postData(
      process.env.REACT_APP_NODE_SERVER +
        "/api/slotDetails/checkSlotAvailability",
      payload,
      "POST"
    );
  };
  useEffect(() => {
    console.log(selectedSlot, "selected slot");
    if (selectedSlot !== "") {
      console.log(selectedSlot);
      handleTotalPriceChange(0);
      // setIsSlotFull(true);
      checkSlotAvailability();
    }
  }, [selectedSlot, adultNum, childrenNum,supportStaff]);
  useEffect(() => {
    if (resData && resData.status === 200) {
      // if (!resData.isSlotFull) {
      //   setIsSlotFull(false);
        calcultatePrice();
      // } else {
      //   setIsSlotFull(false);
      //   setSlotsLeft(resData.slotsRemaining);
      // }
    }
    if (postError) {
      NotificationManager.error(postError, "Error", 3000);
    }
  }, [resData, postError, withFood, adultNum, childrenNum, supportStaff,discount]);

  const calcultatePrice = () => {
    let totalPrice = 0;
    if(discount===`noDiscount`){
        totalPrice =
        (adultNum * Number(selectedSlot.adult_price) +
        (withFood === 1 ? adultNum * foodCost : 0)) +
        (childrenNum * Number(selectedSlot.children_price) +
            (withFood === 1 ? childrenNum * foodCost : 0)) +
        (withFood === 1 ? supportStaff * foodCost : 0);
    }else{
        totalPrice =
        (adultNum * Number(selectedSlot.adult_price) +
        (withFood === 1 ? adultNum * foodCost : 0)) +
        (childrenNum * Number(selectedSlot.children_price) +
            (withFood === 1 ? childrenNum * foodCost : 0)) +
        (withFood === 1 ? supportStaff * foodCost : 0);

        // Apply a 9% discount
        const discount = totalPrice * 0.09;
        totalPrice = totalPrice - discount;
        setDiscountAmount(Number((totalPrice * 0.09).toFixed(2)));
    }
    console.log(totalPrice);  
    handleTotalPriceChange(Number(totalPrice));

  };
  useEffect(() => {
    if(discount === "noDiscount"){
        setDiscountAmount(0)
    }
  }, [discount])

  
  const handleFormSubmit = (e) =>{
    e.preventDefault();
    const payload = {
      user_id: Number(contact),
      slot_id: Number(selectedSlot.slot_id),
      adults_count: adultNum,
      children_count: childrenNum,
      below_2_year_count: 0,
      joy_ride_count: 0,
      joy_ride_children_count: 0,
      adult_joy_ride_total: 0,
      children_joy_ride_total: 0,
      order_id: "manual " + new Date(),
      payment_id: "manual " + new Date(),
      single_adult_price:  selectedSlot.adult_price,
      single_child_price:  selectedSlot.children_price,
      total_cost: totalPrice,
      advance_paid:Number(alreadyPaid),
      amount_paid: Number(alreadyPaid),
      amount_pending: totalPrice-Number(alreadyPaid),
      booking_day: currentDayOfWeek,
      booking_date: selectedDate.formattedDate,
      is_birthday: (selectedSlot.slot_id === 60 || selectedSlot.slot_id === 61) ? 1 : 0,
      is_premium :0,
      name: name,
      email: email,
      contact: contact,
      is_special_day: false,
      grade: 0,
      booking_type: 1,
      support_staff_count: supportStaff,
      with_food: withFood,
      is_discount: discount === 'noDiscount' ? 0 : 1,
      discount_amount: discountAmount,
      archive: 0,
    };
    handlePicnicBooking(payload)
  }
  return (
    <div>
      <NotificationContainer />
      <div className="row">
        <FormGroup className="mb-3 col-sm-4">
          <Label for="numberOfAdults">
            <small>Big people (10 years plus)</small>
          </Label>
          <InputGroup className="input-group-alternative">
            <Input
              placeholder="Number of adults"
              type="number"
              name="numberOfAdults"
              value={adultNum}
              onChange={(e) => setAdultNum(e.target.value)}
              min="1"
            />
          </InputGroup>
        </FormGroup>
        <FormGroup className="mb-3 col-sm-4">
          <Label for="numberOfAdults">
            <small>Lil People (2-10 years)</small>
          </Label>
          <InputGroup className="input-group-alternative">
            <Input
              placeholder="Number of children"
              type="number"
              name="numberOfChildren"
              value={childrenNum}
              onChange={(e) => setChildrenNum(e.target.value)}
              min="0"
            />
          </InputGroup>
        </FormGroup>
        <FormGroup className="mb-3 col-sm-4">
          <Label for="numberOfAdults">
            <small>Support Staff</small>
          </Label>
          <InputGroup className="input-group-alternative">
            <Input
              placeholder="Number of children below 2"
              type="number"
              name="numberOfChildren"
              value={supportStaff}
              onChange={(e) => setSupportStaff(e.target.value)}
              min="0"
            />
          </InputGroup>
          <FormText>Free Entry</FormText>
        </FormGroup>
        <div className="col-sm-12">
          <FormGroup>
            <CustomInput
              type="radio"
              id={0}
              name={`witoutFood`}
              label={<span>Without Food</span>}
              checked={withFood === 0}
              onChange={() => setWithfood(0)}
              inline
            />
            <CustomInput
              type="radio"
              id={1}
              name={`withFood`}
              label={<span>With food</span>}
              checked={withFood === 1}
              onChange={() => setWithfood(1)}
              inline
            />
          </FormGroup>
        </div>
        <div className="col-sm-12">
          <FormGroup>
            <CustomInput
              type="radio"
              id={`noDiscount`}
              name={`noDiscount`}
              label={<span>No Discount</span>}
              checked={discount === `noDiscount`}
              onChange={() => setDiscount(`noDiscount`)}
              inline
            />
            <CustomInput
              type="radio"
              id={`discount`}
              name={`discount`}
              label={<span>9% Discount</span>}
              checked={discount === `discount`}
              onChange={() => setDiscount(`discount`)}
              inline
            />
          </FormGroup>
        </div>
        {
          <FormGroup className="mb-3 ml-3">
            <Label for="exampleCheckbox">
              <small>Select Slot Timings</small>
            </Label>
            <div>
              {slotData &&
                slotData.slots.map((slot) => {
                  // Convert from 24-hour format to 12-hour format
                  if (slot.slot_type == 2) {
                    const fromTime12Hour = new Date(
                      `2000-01-01T${slot.from_time}`
                    ).toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    });
                    const toTime12Hour = new Date(
                      `2000-01-01T${slot.to_time}`
                    ).toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    });
  
                    return (
                      <CustomInput
                        key={slot.slot_id}
                        type="radio"
                        id={slot.slot_id}
                        name={slot.slot_name}
                        label={
                          <span>
                            <strong>{slot.slot_name}</strong> - {fromTime12Hour}{" "}
                            to {toTime12Hour}{" "}
                            <span className="pt-0 ml-1" style={{ top: "0" }}>
                              {slot.available_adults > 0 ? (
                                <span
                                  className="text-success top-0"
                                  style={{ top: "0" }}
                                >
                                  ({slot.available_adults} Adult slots are
                                  remaining)
                                </span>
                              ) : (
                                <span
                                  className="text-danger"
                                  style={{ top: "0" }}
                                >
                                  {/* (No slots are available!) */}
                                </span>
                              )}
                            </span>
                          </span>
                        }
                        checked={Number(selectedSlot.slot_id) === slot.slot_id}
                        onChange={() => handleRadioChange(slot)}
                        // inline
                      />
                    );
                 }
                })}
            </div>
          </FormGroup>
        }
      </div>
      <div>
        {isPending && (
          <div className="text-center mt-3">
            {" "}
            <Spinner
              color="primary"
              style={{ height: "3rem", width: "3rem" }}
            ></Spinner>
          </div>
        )}

        {totalPrice > 0 && !isPending && (
          <>
            <p>Booking Summary:</p>
            <div className="table-responsive">
              <table className="align-items-center table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">People</th>
                    <th scope="col">No. of People</th>
                    <th scope="col">Cost Per Person</th>
                    {withFood === 1 && <th scope="col">Food Per Person</th>}
                    <th scope="col">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Big People</td>
                    <td>{adultNum}</td>
                    <td>₹{selectedSlot.adult_price}</td>
                    {withFood === 1 && <td>₹{foodCost}</td>}
                    <td className="text-success">
                      ₹
                      {Number(
                        adultNum * selectedSlot.adult_price +
                          (withFood === 1 ? Number(adultNum * foodCost) : 0)
                      )}
                    </td>
                  </tr>
                  {childrenNum > 0 && (
                    <tr>
                      <td>Lil People</td>
                      <td>{childrenNum}</td>
                      <td>₹{selectedSlot.children_price}</td>
                      {withFood === 1 && <td>₹{foodCost}</td>}
                      <td className="text-success">
                        ₹
                        {Number(
                          childrenNum * selectedSlot.children_price +
                            (withFood === 1 ? Number(childrenNum * foodCost) : 0)
                        )}
                      </td>
                    </tr>
                  )}
                  {supportStaff > 0 && (
                    <tr>
                      <td>Support Staff</td>
                      <td>{supportStaff}</td>
                      <td>₹{0}</td>
                      {withFood === 1 && <td>₹{foodCost}</td>}
                      <td className="text-success">
                        ₹
                        {0 + (withFood === 1 ? Number(supportStaff * foodCost) : 0)}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan={withFood === 1 ? 4 : 3}>Already Paid</td>
                    <td className="text-success">₹{alreadyPaid}</td>
                  </tr>
                  <tr>
                    <td colSpan={withFood === 1 ? 4 : 3}>Pending Amount</td>
                    <td className="text-danger">₹{totalPrice - alreadyPaid}</td>
                  </tr>
                  <tr>
                    <td colSpan={withFood === 1 ? 4 : 3}>Discount</td>
                    <td className="text-danger">₹{discountAmount}</td>
                  </tr>
                  <tr>
                    <td colSpan={withFood === 1 ? 4 : 3}>Final Total</td>
                    <td className="text-success">₹{totalPrice}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
        {!isPending && isSlotFull && (
          <h4 className="text-danger">
            {slotsLeft > 0
              ? `Only ${slotsLeft} adult slot(s) is available`
              : `No slots are available!!`}
          </h4>
        )}
      </div>
      {totalPrice > 0 && (
        <>
          <hr />
          <h4>Fill the following details</h4>
          <div className="row">
            <FormGroup className="mb-3 col-sm-6">
              <Label for="name">
                <small>Name</small>
              </Label>
              <InputGroup className="input-group-alternative">
                <Input
                  placeholder="Your name"
                  type="text"
                  name="name"
                    value={name}
                    onChange={(e) => handleNameChange(e.target.value)}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup className="mb-3 col-sm-6">
              <Label for="email">
                <small>Email</small>
              </Label>
              <InputGroup className="input-group-alternative">
                <Input
                  placeholder="Your email"
                  type="email"
                  name="email"
                    value={email}
                    onChange={(e) => handleEmailChange(e.target.value)}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup className="mb-3 col-sm-6">
              <Label for="contact">
                <small>Contact</small>
              </Label>
              <InputGroup className="input-group-alternative">
                <div
                  className="input-group-text"
                  style={{ background: "#eee" }}
                >
                  +91
                </div>
                <Input
                  placeholder=" Contact number"
                  type="tel"
                  name="contact"
                    value={contact}
                    onChange={(e) => handleContactChange(e.target.value)}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup className="mb-3 col-sm-6">
              <Label for="amountPaid">
                <small>Amount Already Paid</small>
              </Label>
              <InputGroup className="input-group-alternative">
                <Input
                  placeholder=" Amount Already Paid"
                  type="text"
                  name="amountPaid"
                  value={alreadyPaid}
                  onChange={(e) => setAlreadyPaid(e.target.value)}
                />
              </InputGroup>
            </FormGroup>
          </div>
        </>
      )}
      <div className="text-right">
              { (
                <Button className="my-4" color="primary" onClick={handleFormSubmit}>
                  Book Now
                </Button>
              )}
              {/* {orderPending && (
                <Button className="my-4" color="primary" disabled type="button">
                  Booking...
                </Button>
              )} */}
              <Button color="secondary" onClick={()=>toggleModal()}>
                Cancel
              </Button>
            </div>
    </div>
  );
}
