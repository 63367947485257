import { useEffect, useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useLogout } from "hooks/useLogout";

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();
  const {handleLogout} = useLogout();
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Fetch initial data from the server
        fetch(`${process.env.REACT_APP_NODE_SERVER}/api/cancellation/getAll`)
            .then(response => response.json())
            .then(data => {
                console.log(data.cancellationRequests)
                setMessages(data.cancellationRequests.filter(each=> each.is_resolved ===0));
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching initial data:', error);
                setIsLoading(false);
            });

        // Establish WebSocket connection
        const ws = new WebSocket(process.env.REACT_APP_WS);
        console.log(process.env.REACT_APP_WS)
      
        setSocket(ws);

        // Handle WebSocket open event
        ws.onopen = () => {
            console.log('WebSocket connected');
        };

        // Handle incoming WebSocket messages
        ws.onmessage = (event) => {
            const message = JSON.parse(event.data);
            console.log('New message received:', message); // Log the incoming message
            setMessages((prevMessages) => [message.data, ...prevMessages]);
            console.log(messages.length);
            console.log(messages)
        };

        // Handle WebSocket errors
        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        // Cleanup WebSocket connection when component unmounts
        return () => {
            ws.close();
            console.log('WebSocket disconnected');
        };
    }, []); // Only run once on component mount
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
          >
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </NavbarBrand>
        ) : null}
        {/* User */}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav className="nav-link-icon">
              <i className="ni ni-bell-55" />
            </DropdownToggle>
            <DropdownMenu
              aria-labelledby="navbar-default_dropdown_1"
              className="dropdown-menu-arrow"
              right
            >
              <DropdownItem>Action</DropdownItem>
              <DropdownItem>Another action</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Something else here</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img
                    alt="..."
                    src={require("../../assets/img/theme/team-1-800x800.jpg")}
                  />
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </DropdownItem>
              {/* <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-single-02" />
                <span>My profile</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-settings-gear-65" />
                <span>Settings</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-calendar-grid-58" />
                <span>Activity</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-support-16" />
                <span>Support</span>
              </DropdownItem> */}
              <DropdownItem divider />
              <DropdownItem  onClick={()=>handleLogout()}>
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          <Form className="mt-4 mb-3 d-md-none">
            <InputGroup className="input-group-rounded input-group-merge">
              <Input
                aria-label="Search"
                className="form-control-rounded form-control-prepended"
                placeholder="Search"
                type="search"
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <span className="fa fa-search" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Form>
          {/* Navigation */}
          <Nav navbar>
          <NavItem>
          <NavLink
            to='/'
            tag={NavLinkRRD}
            onClick={closeCollapse}
          >
            Dashboard
          </NavLink>
          <NavLink
            to='/admin/price-dashboard'
            tag={NavLinkRRD}
            onClick={closeCollapse}
          >
            Pricing Dashboard
          </NavLink>
          <NavLink
            to='/admin/view-bookings'
            tag={NavLinkRRD}
            onClick={closeCollapse}
          >
           View Bookings
          </NavLink>
          <NavLink
            to='/admin/manual-book'
            tag={NavLinkRRD}
            onClick={closeCollapse}
          >
           Create Manual Bookings
          </NavLink>
          <NavLink
            to='/admin/exclude-dates'
            tag={NavLinkRRD}
            onClick={closeCollapse}
          >
           Exclude Dates
          </NavLink>
          <NavLink
            to='/admin/include-dates'
            tag={NavLinkRRD}
            onClick={closeCollapse}
          >
           Include Dates
          </NavLink>
          <NavLink
            to='/admin/disable-years'
            tag={NavLinkRRD}
            onClick={closeCollapse}
          >
           Disable Future dates
          </NavLink>
          <NavLink
            to='/admin/add-guide'
            tag={NavLinkRRD}
            onClick={closeCollapse}
          >
           Add Guide
          </NavLink>
          <NavLink
            to='/admin/cancellation'
            tag={NavLinkRRD}
            onClick={closeCollapse}
          >
           Cancellation Requests {" "}
            {messages && messages.length> 0 &&<span className="translate-middle badge rounded-pill text-white bg-danger ml-2">
              {messages.length}
            </span>}
          </NavLink>
        </NavItem>
          </Nav>
          {/* Divider */}
          <hr className="my-3" />
          {/* Heading */}
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
