
// reactstrap components
import { useFetch } from "hooks/useFetch";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

const Header = () => {
  const {data:totalUsers} = useFetch(`${process.env.REACT_APP_NODE_SERVER}/api/stats/allUsers`);
  const {data:newUsers} = useFetch(`${process.env.REACT_APP_NODE_SERVER}/api/stats/allNewUsers`);
  const {data:totalRevenue} = useFetch(`${process.env.REACT_APP_NODE_SERVER}/api/stats/totalRevenue`);
  const {data:todaysRevenue} = useFetch(`${process.env.REACT_APP_NODE_SERVER}/api/stats/totalRevenue/${new Date().toISOString().split('T')[0]}`);
  console.log(totalUsers)
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Traffic
                        </CardTitle>
                       { totalUsers && <span className="h2 font-weight-bold mb-0">
                          {totalUsers.total_users}
                        </span>}
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 3.48%
                      </span>{" "}
                      <span className="text-nowrap">Since last month</span>
                    </p> */}
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          New users
                        </CardTitle>
                        {newUsers && <span className="h2 font-weight-bold mb-0">{newUsers.result.length}</span>}
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="ni ni-single-02" />
                        </div>
                      </Col>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-danger mr-2">
                        <i className="fas fa-arrow-down" /> 3.48%
                      </span>{" "}
                      <span className="text-nowrap">Since last week</span>
                    </p> */}
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Revenue
                        </CardTitle>
                        {totalRevenue && <span className="h2 font-weight-bold mb-0">₹{totalRevenue.totalRevenue> 0 ?(totalRevenue.totalRevenue).toLocaleString('en-IN') : 0}</span>}
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-warning mr-2">
                        <i className="fas fa-arrow-down" /> 1.10%
                      </span>{" "}
                      <span className="text-nowrap">Since yesterday</span>
                    </p> */}
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Today's Revenue
                        </CardTitle>
                        {todaysRevenue && <span className="h2 font-weight-bold mb-0">₹{(todaysRevenue.todaysRevenue).toLocaleString('en-IN')}</span>}
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fas fa-percent" />
                        </div>
                      </Col>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-muted text-sm">
                      {todaysRevenue && todaysRevenue.trend ==="greater" && <span className="text-success mr-2">
                        <i className="fas fa-arrow-up" /> 12%
                      </span>}
                      {todaysRevenue && todaysRevenue.trend ==="lesser" && <span className="text-danger mr-2">
                        <i className="fas fa-arrow-down" /> {(todaysRevenue.percentageDifference).toFixed(2)}%
                      </span>}
                      {" "}
                      <span className="text-nowrap">Since last yesterday</span>
                    </p> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
