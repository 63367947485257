import React, { useEffect, useState } from "react";
import "./CancellationRequest.css";
import CancellationRequest from "./CancellationRequest";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";

const Cancellation = () => {
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [visibleCount, setVisibleCount] = useState(10);

  useEffect(() => {
    // Fetch initial data from the server
    fetch(`${process.env.REACT_APP_NODE_SERVER}/api/cancellation/getAll`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data.cancellationRequests);
        setMessages(data.cancellationRequests);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching initial data:", error);
        setIsLoading(false);
      });

    // Establish WebSocket connection
    const ws = new WebSocket(process.env.REACT_APP_WS);
    setSocket(ws);

    // Handle WebSocket open event
    ws.onopen = () => {
      console.log("WebSocket connected");
    };

    // Handle incoming WebSocket messages
    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log("New message received:", message); // Log the incoming message
      setMessages((prevMessages) => [message.data, ...prevMessages]);
      console.log(messages.length);
    };

    // Handle WebSocket errors
    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    // Cleanup WebSocket connection when component unmounts
    return () => {
      ws.close();
      console.log("WebSocket disconnected");
    };
  }, []); // Only run once on component mount

  const handleRemoveMessage = (index) => {
    setMessages((prevMessages) => prevMessages.filter((_, i) => i !== index));
  };

  const loadMore = () => {
    setVisibleCount((prevCount) => prevCount + 10); // Increment visible items by 10
  };

  return (
    <div className="container pt-8">
      <div>
        <h2>Cancellation Requests</h2>
        {isLoading ? (
          <div className="text-center">
            <Spinner
              color="primary"
              style={{ height: "3rem", width: "3rem" }}
            ></Spinner>
          </div>
        ) : messages.length > 0 > 0 ? (
          <div>
            {messages.slice(0, visibleCount).map((msg, index) =>
              msg && msg.cancellation_id && msg.booking_id && msg.order_id ? (
                <Link
                  key={index}
                  to={`/admin/cancellationDetails/${msg.cancellation_id}/${msg.booking_id}/${msg.order_id}`}
                >
                  <CancellationRequest
                    message={msg}
                    onClose={() => handleRemoveMessage(index)}
                  />
                </Link>
              ) : null
            )}
            {/* Load More Button */}
            {visibleCount < messages.length && (
              <div className="text-center mt-3">
                <button
                  onClick={loadMore}
                  className="btn btn-primary"
                >
                  Load More
                </button>
              </div>
            )}
          </div>
        ) : (
          <p>No cancellation requests found.</p>
        )}
      </div>
    </div>
  );
};

export default Cancellation;
