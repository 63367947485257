import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  FormGroup,
  Table,
  Input,
  Button,
  Col,
  Row,
  Card,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from "reactstrap";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { usePost } from "hooks/usePost";
import { useFetch } from "hooks/useFetch";

export default function DisableYears() {
  const [selectedDate, setSelectedDate] = useState("");
  const { postData, resData, postError, isPending } = usePost();
  const { postData: archiveDate, resData: archiveRes, postError: archiveErr, isPending: archivePending } = usePost();
  const { data: fetchedDates, fetchIsPending, fetchErr } = useFetch(`${process.env.REACT_APP_NODE_SERVER}/api/disabledYears/getAll`);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [dateToDelete, setDateToDelete] = useState(null);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = { dates: [selectedDate] };
    postData(`${process.env.REACT_APP_NODE_SERVER}/api/disabledYears/addMultipleYears`, payload, "POST");
  };

  useEffect(() => {
    if (resData && resData.status === 200) {
      NotificationManager.success('Date added successfully!', '', 3000);
      window.location.reload();
    }
    if (postError) {
      NotificationManager.error(postError, '', 3000);
    }
  }, [resData, postError]);

  const handleCancelDelete = () => {
    setDeleteModalOpen(false);
  };

  const handleConfirmDelete = () => {
    archiveDate(`${process.env.REACT_APP_NODE_SERVER}/api/disabledYears/archive/${dateToDelete.disabled_years_id}`, {}, "PUT");
  };

  useEffect(() => {
    if (archiveRes && archiveRes.status === 200) {
      setDeleteModalOpen(false);
      NotificationManager.success('Deleted successfully!', '', 3000);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    if (archiveErr) {
      NotificationManager.error(archiveErr, '', 3000);
    }
  }, [archiveRes, archiveErr]);

  const handleDeleteModal = (date) => {
    setDeleteModalOpen(true);
    setDateToDelete(date);
  };

  return (
    <Container className="pt-8">
      <h3>Add Last date</h3>
      <em><small className="text-info">(e.g. 31/12/2024, All the dates after this date will be disabled)</small></em>
      <NotificationContainer />
      <div className="row mt-4">
        <div className="col-sm-8">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0">Last Date</h3>
                </div>
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Dates</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {fetchIsPending ? (
                  <tr>
                    <td colSpan="2" className="text-center">
                      <Spinner color="primary" />
                    </td>
                  </tr>
                ) : fetchedDates && fetchedDates.dates.length === 0 ? (
                  <tr>
                    <td colSpan="2" className="text-danger">No dates to show</td>
                  </tr>
                ) : (
                  fetchedDates && fetchedDates.dates.map((date) => (
                    <tr key={date.disabled_years_id}>
                      <td>{new Date(date.date).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}</td>
                      <td>
                        <i
                          className="ri-delete-bin-5-line text-danger"
                          title="Delete"
                          style={{ fontSize: '18px', cursor: 'pointer' }}
                          onClick={() => handleDeleteModal(date)}
                        ></i>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </Card>
        </div>
        <div className="col-sm-4" style={{ width: "40%" }}>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Row className="d-flex">
                <Col className="justify-center">
                  <Input
                    type="date"
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                </Col>
              </Row>
            </FormGroup>
            <Button
              className="mt-2"
              color="success"
              disabled={isPending || (fetchedDates && fetchedDates.dates.length > 0)}
              type="submit"
            >
              {!isPending ? "Submit" : "Submitting..."}
            </Button>
            {fetchedDates && fetchedDates.dates.length > 0 && (
              <p className="mt-2"><small className="text-info">To add a new date, delete the previous one!</small></p>
            )}
          </Form>
        </div>
      </div>
      <Modal isOpen={deleteModalOpen} toggle={handleCancelDelete}>
        <ModalHeader toggle={handleCancelDelete}>Confirm Delete</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this date?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleConfirmDelete} disabled={archivePending}>
            {archivePending ? "Deleting..." : "Yes"}
          </Button>
          <Button color="secondary" onClick={handleCancelDelete}>No</Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}
