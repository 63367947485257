import { useFetch } from "hooks/useFetch";
import React, { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/mobile.css";
import * as XLSX from "xlsx";
import {
  Card,
  CardHeader,
  Row,
  PaginationItem,
  PaginationLink,
  Pagination,
  CardFooter,
  Table,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";

export default function ExportData() {
  const [filterModal, setFilterModal] = useState(false);
  const [dateValue, setDateValue] = useState(null);
  const [selectedOption, setSelectedOption] = useState("2");
  const toggleFilterModal = () => setFilterModal(!filterModal);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { data, fetchIsPending, fetchErr } = useFetch(
    `${process.env.REACT_APP_NODE_SERVER}/api/booking/getBookingData/${selectedOption}?&startDate=${startDate}&endDate=${endDate}`
  );

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handlePageChange = (newPage) => {
    const totalItems = data.bookingData.length;
    const maxPage = Math.ceil(totalItems / limit);
    if (newPage >= 1 && newPage <= maxPage) {
      setCurrentPage(newPage);
      setOffset((newPage - 1) * limit);
    }
  };

  useEffect(() => {
    if (dateValue && dateValue.length === 2) {
      console.log(dateValue);
      setStartDate(
        dateValue[0].year + "-" + dateValue[0].month + "-" + dateValue[0].day
      );
      console.log(
        dateValue[0].year + "-" + dateValue[0].month + "-" + dateValue[0].day
      );
      console.log(
        dateValue[1].year + "-" + dateValue[1].month + "-" + dateValue[1].day
      );
      setEndDate(
        dateValue[1].year + "-" + dateValue[1].month + "-" + dateValue[1].day
      );
    }
  }, [dateValue]);
  useEffect(() => {
    if (selectedOption !== "6") {
      setDateValue(null);
    }
    setOffset(0);
    setCurrentPage(1)
  }, [selectedOption]);

  const handleDownloadExcelSheet = () => {
    // Define column names
    const columnNames = [
      "Booking ID",
      "Order ID",
      "Date of Visit",
      "Slot Time",
      "Name",
      "Mobile",
      "Total Picnickers",
      "Big People",
      "Big People Single Amount",
      "Lil Ones",
      "Lil Ones Single Amount",
      "Kids Below 2",
      "Adult Joy Ride",
      "Adult Joyride Amount",
      "Kid Joy Ride",
      "Kids Joyride Amount",
      "Total Amount",
      "Advance Received",
      "Balance Remaining",
      "Balance Received",
      "Payment Method",
      "No Show",
      "Cancellation",
      "Is Birthday",
      "Is Premium",
      "Email",
    ];

    // Prepare data array with column names as the first row
    const mappedData = [
      columnNames,
      ...data.bookingData.map((booking) => [
        booking.booking_id,
        booking.order_id,
        new Date(booking.booking_date).toLocaleDateString("en-GB"),
        "Morning" + " - " + booking.from_time + " - " + booking.to_time,
        booking.name,
        booking.contact,
        Number(booking.adults_count) +
          Number(booking.children_count) +
          Number(booking.below_2_year_count),
        booking.adults_count,
        booking.single_adult_price,
        booking.children_count,
        booking.single_child_price,
        booking.below_2_year_count,
        Number(booking.joy_ride_count) + Number(booking.free_adult_joy_rides),
        "₹" + booking.adult_joy_ride_total,
        Number(booking.joy_ride_children_count) +
          Number(booking.free_child_joy_rides),
        "₹" + booking.children_joy_ride_total,
        "₹" + booking.total_cost,
        "₹" + booking.advance_paid,
        "₹" + booking.amount_pending,
        "₹" + Number(booking.amount_paid - booking.advance_paid),
        booking.payment_mode,
        booking.amount_pending > 0 ? "YES" : "NO",
        booking.archive === 1 ? "YES" : "NO",
        booking.is_birthday === 1 ? "Yes" : "No",
        booking.is_premium === 1 ? "Yes" : "No",
        booking.email,
      ]),
    ];

    // Generate Excel sheet
    const ws = XLSX.utils.aoa_to_sheet(mappedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Bookings");
    XLSX.writeFile(
      wb,
      `bookings(${new Date().toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })}).xlsx`
    );
  };
  return (
    <div>
      <Card className="shadow">
        <CardHeader className="border-0">
          <Row className="align-items-center">
            <div className="col">
              <h3 className="mb-0">All Bookings</h3>
            </div>
            <div className="text-right d-flex align-items-center position-relative">
              <span className="mr-4">
                <Input
                  value={selectedOption}
                  type="select"
                  onChange={handleOptionChange}
                >
                  <option value="1">Yesterday</option>
                  <option value="2">Today</option>
                  <option value="3">Last 7 days</option>
                  <option value="4">This Month</option>
                  <option value="5">Last Month</option>
                  <option value="6">Custom Date</option>
                </Input>
              </span>
              <span className="mr-4">
                {selectedOption === "6" && (
                  <DatePicker
                    value={dateValue}
                    range
                    onChange={setDateValue}
                    open={true}
                  />
                )}
              </span>
              <span className="mr-4">
                <i
                  className="ri-download-2-line"
                  title="Download"
                  onClick={handleDownloadExcelSheet}
                  style={{ fontSize: "22px", cursor: "pointer" }}
                ></i>
              </span>
            </div>
          </Row>
        </CardHeader>
        <div className="table-responsive">
          <Table className="align-items-center table-flush responsive">
            <thead className="thead-light">
              {fetchIsPending && <tr></tr>}
              <tr>
                <th scope="col">Date of Visit</th>
                <th scope="col">Slot</th>
                <th scope="col">Name</th>
                <th scope="col">Mobile</th>
                <th scope="col">Total Picnickers</th>
                <th scope="col">Big People</th>
                <th scope="col">Support Staff</th>
                <th scope="col">Lil' Ones</th>
                <th scope="col">Kids Below 2</th>
                <th scope="col">Adult JR</th>

                <th scope="col">Kid JR</th>

                <th scope="col">Revenue</th>
                <th scope="col">Advance</th>
                <th scope="col">Bal Remaining</th>
                <th scope="col">Bal Received</th>
                <th scope="col">Payment Mode</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.bookingData.slice(offset, offset + limit).map((each) => (
                  <tr key={each.booking_id}>
                    <td>
                      {new Date(each.booking_date).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "2-digit",
                      })}
                    </td>
                    <td>{each.slot_name}</td>
                    <td>{each.name}</td>
                    <td>{each.contact}</td>
                    <td>
                      {Number(each.adults_count) +
                        Number(each.children_count) +
                        Number(each.below_2_year_count) +
                        Number(each.support_staff_count)}
                    </td>
                    <td>{each.adults_count}</td>
                    <td>{each.support_staff_count}</td>
                    <td>{each.children_count}</td>
                    <td>{each.below_2_year_count}</td>
                    <td>
                      {Number(each.joy_ride_count) +
                        Number(each.free_adult_joy_rides)}
                    </td>

                    <td>
                      {Number(each.joy_ride_children_count) +
                        Number(each.free_child_joy_rides)}
                    </td>

                    <td>₹{each.total_cost}</td>
                    <td>₹{each.advance_paid}</td>
                    <td>₹{each.amount_pending}</td>
                    <td>₹{Number(each.amount_paid - each.advance_paid)}</td>
                    <td>{each.payment_mode}</td>
                  </tr>
                ))}
              {data && data.bookingData.length === 0 && (
                <tr>
                  <th className="text-danger">No Bookings Found!</th>
                </tr>
              )}
              {fetchIsPending && (
                <tr>
                  <th>loading...</th>
                </tr>
              )}
              {fetchErr && (
                <tr>
                  <th className="text-danger">{fetchErr}</th>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        {data && (
          <CardFooter className="py-4">
            <nav className="d-flex justify-content-between" aria-label="...">
              <div className="d-flex align-items-center">
                <div className="d-flex mr-2">
                  <small>
                    <strong>Page Limit</strong>
                  </small>
                </div>
                <div className="d-flex mr-2">
                  <Input
                    value={limit}
                    type="select"
                    onChange={(e) => setLimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                  </Input>
                </div>
              </div>
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                    style={{
                      background: currentPage === 1 ? "#C1C1C1" : "none",
                    }}
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem active>
                  <PaginationLink onClick={(e) => e.preventDefault()}>
                    {currentPage}
                  </PaginationLink>
                </PaginationItem>
                {currentPage !== Math.ceil(data.bookingData.length / limit) && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      {currentPage + 1}
                    </PaginationLink>
                  </PaginationItem>
                )}
                {data && (
                  <PaginationItem>
                    <PaginationLink
                      disabled={
                        currentPage ===
                        Math.ceil(data.bookingData.length / limit)
                      }
                      onClick={() => handlePageChange(currentPage + 1)}
                      style={{
                        background:
                          currentPage ===
                          Math.ceil(data.bookingData.length / limit)
                            ? "#C1C1C1"
                            : "none",
                      }}
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                )}
              </Pagination>
            </nav>
          </CardFooter>
        )}
      </Card>
    </div>
  );
}
