import { useFetch } from 'hooks/useFetch'
import React, { useEffect, useState } from 'react'
import { Col, Input, Row, Table } from 'reactstrap'

const GuideOverview = () => {
  const [guide_date, setGuideDate] = useState(localStorage.getItem('Date') ? JSON.parse(localStorage.getItem('Date')) : new Date().toISOString().split('T')[0])
  const {fetchData:fetchDataguide, err:error, data:guideData} = useFetch(`${process.env.REACT_APP_NODE_SERVER}/api/stats/getPerGuideCount?date=${guide_date}`)

  useEffect(() => {
    if (guide_date) {
      fetchDataguide()
    }
  }, [guide_date])
  
  const handleChangeGuideDate = (value) => {
    setGuideDate(value)
  }


  return (
    <div className='pl-2'>
      <Input
                  type="date"
                  value={guide_date}
                  onChange={(e) => handleChangeGuideDate(e.target.value)}
                />
     {guideData && guideData?.result.length >0 && (
        <>
          <Row className='pl-2'>
            <Col sm={12} className='table-responsive'>
            <Table className='table table-flush'>
            <thead className="thead-light">
              <th>Guide Name</th>
              <th>Count</th>
            </thead>
            <tbody>
              {guideData?.result.length == 0 && <tr><td colSpan={2}>No Data available</td></tr>}
              {guideData?.result.length > 0 && guideData?.result?.map((data) => {
                return (
                  <tr>
                    <td>{data.guide_name}</td>
                    <td>{ data.booking_count }</td>
                  </tr>
                )
              }) }
            </tbody>
            </Table>
            </Col>
          </Row>
        </>
      )
      }
    </div>
  )
}

export default GuideOverview
