import { useFetch } from 'hooks/useFetch';
// import { usePost } from 'hooks/usePost';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Spinner,Button } from 'reactstrap';
import successImg from '../../../assets/img/theme/success.png';
import '../../../App.css'
export default function Success() {
    const {order_id,cashfree_order_id,booking_date} = useParams();
    const {data:orderTableData} = useFetch(`${process.env.REACT_APP_NODE_SERVER}/api/orders/getOrderDetails/${order_id}`);
    const {data:cashFreeOrderData} = useFetch(`${process.env.REACT_APP_NODE_SERVER}/api/payment/getAnOrder/${cashfree_order_id}`);
    // const { postData, resData, postError } = usePost();
    // const { postData:updateOrderTable } = usePost();
    // const { postData:sendEmail, resData:emailRes, postError:emailError } = usePost();
    const [isLoading,setIsLoading] = useState(true);

    useEffect(()=>{
        if(orderTableData && cashFreeOrderData){
            if(cashFreeOrderData.order_status === "PAID"){
                // updateOrderTable(`${process.env.REACT_APP_NODE_SERVER}/api/orders/update/${order_id}`,{...orderTableData.order,cf_order_id:cashfree_order_id},'PUT');
                // handleOrder();
                displaySuccessMessage();
            }
        }
    },[orderTableData,cashFreeOrderData])

    const displaySuccessMessage = () =>{
        setIsLoading(false);
    }
    // const handleOrder = () => {
    //     console.log('inside handle order fn');
    //     const payload = {
    //     name: orderTableData.order.name,
    //     email: orderTableData.order.email,  
    //     contact: orderTableData.order.contact,
    //     user_id: orderTableData.order.user_id,
    //     slot_id: orderTableData.order.slot_id,
    //     adults_count: orderTableData.order.adults_count,
    //     children_count: orderTableData.order.children_count,
    //     joy_ride_count: orderTableData.order.joy_ride_count,
    //     joy_ride_children_count: orderTableData.order.joy_ride_children_count,
    //     below_2_year_count: orderTableData.order.below_2_year_count,
    //     order_id: cashFreeOrderData.order_id,
    //     payment_id: cashFreeOrderData.cf_order_id,
    //     booking_day: orderTableData.order.booking_day,
    //     booking_date: booking_date,
    //     is_special_day: orderTableData.order.is_special_day,
    //     grade: orderTableData.order.grade,
    //     archive: orderTableData.order.archive,
    //     single_adult_price: orderTableData.order.single_adult_price,
    //     single_child_price: orderTableData.order.single_child_price,
    //     total_cost: orderTableData.order.total_cost,
    //     amount_paid: Number(cashFreeOrderData.order_amount),
    //     amount_pending: orderTableData.order.amount_pending,
    //   };
    //   setIsLoading(false);
        // postData(`${process.env.REACT_APP_NODE_SERVER}/api/booking/create`,payload,"POST")
    // }

    // useEffect(()=>{
    //     if(resData && resData.status ===200){
    //         console.log('inside useEffect!')
    //         // handleSendEmail();
    //         setIsLoading(false);
    //     }
    //     if(postError){
    //         alert("Error: "+postError);
    //     }
    // },[resData,postError])

    // const handleSendEmail=()=>{
    //     const payload = {
    //         "to": orderTableData.order.email,
    //         "subject": "Booking Confirmation",
    //         html: `
    //         <p>Hello ${orderTableData.order.name},</p>
    //         <p>We're delighted to inform you that your booking for <strong>${new Date(booking_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}</strong> has been successfully confirmed!</p>

    //         <p style="text-align: center;">Booking Details:</p>

    //         <table style="border-collapse: collapse; width: 50%; margin: 0 auto;">
    //             <thead>
    //                 <tr style="background-color: #f8f9fa; color: #212529;">
    //                     <th style="border: 1px solid #dee2e6; padding: 8px; text-align: left;">Details</th>
    //                     <th style="border: 1px solid #dee2e6; padding: 8px; text-align: left;">No. of Seats</th>
    //                 </tr>
    //             </thead>
    //             <tbody>
    //                 <tr style="background-color: #fff; color: #212529;">
    //                     <td style="border: 1px solid #dee2e6; padding: 8px;">${new Date(booking_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}  Big people - From ${new Date(`1970-01-01T${orderTableData.order.from_time}`).toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})} To ${new Date(`1970-01-01T${orderTableData.order.to_time}`).toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})} - ${orderTableData.order.slot_name}</td>
    //                     <td style="border: 1px solid #dee2e6; padding: 8px;">${orderTableData.order.adults_count}</td> 
    //                 </tr>
    //                 <tr style="background-color: #fff; color: #212529;">
    //                     <td style="border: 1px solid #dee2e6; padding: 8px;">${new Date(booking_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}  Lil ones -  From ${new Date(`1970-01-01T${orderTableData.order.from_time}`).toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})} To ${new Date(`1970-01-01T${orderTableData.order.to_time}`).toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})}  - ${orderTableData.order.slot_name}</td>
    //                     <td style="border: 1px solid #dee2e6; padding: 8px;"> ${orderTableData.order.children_count}</td>
    //                 </tr>
    //             </tbody>
    //         </table>
    //         <p style="text-align: center;">Transaction Details:</p>
    //         <table style="border-collapse: collapse; width: 50%; margin: 0 auto;"
    //             <tbody>
    //             <tr style="background-color: #fff; color: #212529;">
    //                     <td style="border: 1px solid #dee2e6; padding: 8px;">Order ID</td>
    //                     <td style="border: 1px solid #dee2e6; padding: 8px;">${cashFreeOrderData.order_id}</td> 
    //                 </tr>
    //                 <tr style="background-color: #fff; color: #212529;">
    //                     <td style="border: 1px solid #dee2e6; padding: 8px;">Customer code</td>
    //                     <td style="border: 1px solid #dee2e6; padding: 8px;">${cashFreeOrderData.cf_order_id}</td> 
    //                 </tr>
    //                 <tr style="background-color: #fff; color: #212529;">
    //                     <td style="border: 1px solid #dee2e6; padding: 8px;">Patron Name</td>
    //                     <td style="border: 1px solid #dee2e6; padding: 8px;">${cashFreeOrderData.customer_details.customer_name}</td>
    //                 </tr>
    //                 <tr style="background-color: #fff; color: #212529;">
    //                     <td style="border: 1px solid #dee2e6; padding: 8px;">Patron Email ID</td>
    //                     <td style="border: 1px solid #dee2e6; padding: 8px;">${cashFreeOrderData.customer_details.customer_email}</td>
    //                 </tr>
    //                 <tr style="background-color: #fff; color: #212529;">
    //                     <td style="border: 1px solid #dee2e6; padding: 8px;">Payment Towards</td>
    //                     <td style="border: 1px solid #dee2e6; padding: 8px;">Japalouppe Equestrian Center Pvt Ltd</td>
    //                 </tr>
    //                 <tr style="background-color: #fff; color: #212529;">
    //                     <td style="border: 1px solid #dee2e6; padding: 8px;">Payment Date / Time</td>
    //                     <td style="border: 1px solid #dee2e6; padding: 8px;">${new Date(cashFreeOrderData.created_at).toISOString().replace('T', ' ').split('.')[0]}</td>
    //                 </tr>
    //                 <tr style="background-color: #fff; color: #212529;">
    //                     <td style="border: 1px solid #dee2e6; padding: 8px;">Amount Paid</td>
    //                     <td style="border: 1px solid #dee2e6; padding: 8px;">₹${cashFreeOrderData.order_amount}</td>
    //                 </tr>
    //                 <tr style="background-color: #fff; color: #212529;">
    //                     <td style="border: 1px solid #dee2e6; padding: 8px;">Message</td>
    //                     <td style="border: 1px solid #dee2e6; padding: 8px;">This is an online advance payment of 50% against the total entry charges. Please show this receipt at the time of check-in. You may pay the remaining 50% in cash or via UPI when you arrive (we do not have a card machine at the farm)
    //                     To add to customer experience, you can also opt for a horse back joy ride at the farm (charged additionally). You can purchase a ticket for this, at the time of customer check-in.<br/>
    //                     Directions to Jap: <a target="_blank" href="https://www.google.com/url?q=https://maps.app.goo.gl/6qqvDN2YCrTNrZEN8?g_st%3Dic&source=gmail&ust=1712977306787000&usg=AOvVaw0uLcq7jzgL11q6VbLxJh7y">click here</a>
    //                     </td>
    //                 </tr>
    //             </tbody>
    //         </table>
    //         <p>Warm regards,</p>
    //         <p>Japalouppe</p>`
    //     }
    //     // return;
    //     sendEmail(`${process.env.REACT_APP_NODE_SERVER}/api/email/sendEmail`,payload,"POST")
    // }

    // useEffect(()=>{
    //     if(emailRes && emailRes.status ===200){
    //         setIsLoading(false);
    //     }
    //     if(postError){
    //         alert("Error: "+emailError);
    //     }
    // },[emailRes,emailError])

  return (
    <div className=' container pt-9' style={{height:"85vh"}}>
        {!isLoading && <div className="text-center">
            <img src={successImg} alt="success" className='pulse' width={120}/>
            <h1 className='mt-3'>Booking Confirmed Successfully!</h1>
            <Link to="/spectator/index"><Button className="mt-3" color="primary">Back</Button></Link>
        </div>}
        {isLoading && <div className="text-center">
            <Spinner color="primary" style={{ height: '3rem',width: '3rem'}}></Spinner>
            <h2 className='mt-3'>Your payment is being processed, do not refresh!</h2>
        </div>}
    </div>
  )
}
