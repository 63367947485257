import { usePost } from "hooks/usePost";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import {
  Card,CardHeader,Table,Row,Col,Input,Button,Modal,ModalHeader,ModalBody,ModalFooter,DropdownMenu,DropdownItem,UncontrolledDropdown,
  DropdownToggle,
  Label,
  CustomInput,
} from "reactstrap";
import "react-notifications/lib/notifications.css";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import { useFetch } from "hooks/useFetch";

export default function AllBookingsTable({
  data,
  slotData,
  booking_date,
  handleChangeDate,
  guide,
  handleGuideChange,
  slot,
  handleSlotChange,
  slotName,
  handleSlotNameChange,
  picnickerName,
  handlePicknerNameChange,
  email,
  contact,
  handlePicknerEmailChange,
  handleContactChange,
  handleReset,
  bithdayPrice,
  fetchData,
  handleChangeTab,
  tab
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [isChangeSlot, setIsChangeSlot] = useState(false);
  const [guideModalOpen, setGuideModalOpen] = useState(false);
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isAddPeople, setIsAddpeople] = useState(false);
  const [below2years, setBelow2years] = useState(0);
  const [selectedRefundType, setSelectedRefundType] = useState(null);
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [adjustedRefundAmt,setAdjustedRefundAmt] = useState(0);
  const [editEmail,setEditEmail] = useState(false);
  const [editContact,setEditContact] = useState(false);
  const { postData, resData, postError, isPending } = usePost();
  const {postData:updateEmail, resData:updateEmailRes, postError:updateEmailErr, isPending:updateEmailPending} = usePost();
  const {postData:updateContact, resData:updateContactRes, postError:updateContactErr, isPending:updateContactPending} = usePost();
  const { postData:postComment, resData:commentRes, postError:commentErr, isPending:commentIsPending } = usePost();
  const { postData:updateBookings, resData:updateBookingRes, postError:updateBookingError, isPending:updateBookingIsPending } = usePost();
  const {
    postData: changeSlot,
    resData: changeSlotRes,
    postError: changeSlotPostErr,
    isPending: changeSlotPending,
  } = usePost();
  const {postData:cancelBooking,resData:cancelRes,postError:cancelErr,isPending:cancelPending} = usePost();
  const { postData: prevBookFetch, resData: prevBookData, postError:prevBookError,isPending:prevBookPending} = usePost();
  const [joyRidePrice, setJoyRidePrice] = useState(null);
  const [joyRideCount, setJoyRideCount] = useState(0);
  const [joyRiderChildrenCount, setJoyRiderChildrenCount] = useState(0);
  const [AdultFreeJoyRideCount, setFreeAdultJoyRideCount] = useState(0);
  const [childFreeJoyRideCount, setChildFreeJoyRideCount] = useState(0);
  const [isJoyRide, setIsJoyRide] = useState(false);
  const [isFreeJoyRide, setIsFreeJoyRide] = useState(false);
  const [paymentMode, setPaymentMode] = useState("");
  const [joyRidePriceChildren, setJoyRidePriceChildren] = useState(null);
  const [selectedGuide, setSelectedGuide] = useState(0);
  const [filterGuide, setFilterGuide] = useState(guide);
  const [filterSlot, setFilterSlot] = useState(slot);
  const [filterSlotName,setFilterSlotName] = useState(slotName)
  const [filterPicnickerName, setFilterPicnickerName] = useState(picnickerName);
  const [filteremail, setFilteremail] = useState(email);
  const [userEmail,setUserEmail] = useState(null);
  const [userContact,setUserContact] = useState(null);
  const [fitlerContact, setFilterContact] = useState(contact);
  const [isOthers, setIsothers] = useState(false);
  const [initalAdultsCount,setInitialAdultsCount] = useState(0);
  const [initalChildrenCount,setInitialChildrenCount] = useState(0);
  const [numOfRefundableAdult, setnumOfRefundableAdult] = useState(0);
  const [numOfRefundableChildren, setnumOfRefundableChildren] = useState(0);
  const [totalRefundableAmount, settotalRefundableAmount] = useState(0);
  const [comment,setComment] = useState('');
  const [others, setOthers] = useState("");
  const slotOrder = { "morning": 1, "late morning": 2, "afternoon": 3, "late afternoon": 4, "evening": 5, "birthday1":6,"birthday2":7 };
  const { data: joyRideData } = useFetch(
    `${process.env.REACT_APP_NODE_SERVER}/api/joyrides/getAll`
  );
  const { data: guides } = useFetch(
    `${process.env.REACT_APP_NODE_SERVER}/api/guide/getAll`
  );
  const { data: slots } = useFetch(
    `${process.env.REACT_APP_NODE_SERVER}/api/slot/getAll`
  );

  const [previously_booked,setPreviouslyBooked] = useState(null)

  const toggleFilter = () => {
    setFilterOpen(!filterOpen);
  };
  const toggleGuideModal = () => {
    setGuideModalOpen(!guideModalOpen);
  };
  const toggleCommentModal = () => {
    console.log('comment modal')
    setCommentModalOpen(!commentModalOpen);
  };

  useEffect(() => {
    if (data?.slotWiseCount?.length >0) {
      data?.slotWiseCount?.sort((a, b) => (slotOrder[a.Slotname?.toLowerCase()] || 99) - (slotOrder[b.Slotname?.toLowerCase()] || 99));
    }
  },[data])

  useEffect(() => {
    if (joyRideData && joyRideData.status === 200) {
      setJoyRidePrice(Number(joyRideData.joy_rides[0].joy_ride_adult_price));
      setJoyRidePriceChildren(
        Number(joyRideData.joy_rides[0].joy_ride_children_price)
      );
    }
  }, [joyRideData]);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handlePriceRevision = () => {
    if (joyRidePrice && joyRidePriceChildren && bithdayPrice) {
      setTotalPrice(
        adults * Number(selectedBooking.single_adult_price) +
          Number(children) * Number(selectedBooking.single_child_price) +
          Number(joyRideCount * joyRidePrice) +
          Number(joyRiderChildrenCount * joyRidePriceChildren) + ((selectedBooking.slot_id === 60 || (selectedBooking.slot_id === 61 ) ? bithdayPrice:0))
      );
    }
  };
  useEffect(() => {
    if (selectedBooking) {
      handlePriceRevision();
    }
  }, [adults, children, selectedBooking, joyRideCount, joyRiderChildrenCount]);

  useEffect(() => {
    if (!modalOpen) {
      setIsAddpeople(false);
      setAdults(0);
      setChildren(0);
      setJoyRideCount(0);
      setJoyRiderChildrenCount(0);
      setFreeAdultJoyRideCount(0);
      setChildFreeJoyRideCount(0);
      setnumOfRefundableAdult(0);
      setnumOfRefundableChildren(0);
      setIsChangeSlot(false);
    }
  }, [modalOpen]);

  // useEffect(() => {
  //   if (!isJoyRide) {
  //     setJoyRideCount(0);
  //     setJoyRiderChildrenCount(0);
  //   }
  // }, [isJoyRide]);
  // useEffect(() => {
  //   if (!isFreeJoyRide) {
  //     setFreeAdultJoyRideCount(0);
  //     setChildFreeJoyRideCount(0);
  //   }
  // }, [isFreeJoyRide]);

  // useEffect(() => {
  //   if (!isAddPeople) {
  //     setAdults(0);
  //     setChildren(0);
  //   }
  // }, [isAddPeople]);

  const openSelectGuideModal = (booking) => {
    setSelectedBooking(booking);
    // console.log(booking);
    toggleGuideModal();
  };
  const openCommentModal = (booking) => {
    setComment(booking.comment)
    setSelectedBooking(booking);
    // console.log(booking);
    toggleCommentModal();
  };
  const openBookingDetails = (booking) => {
    getPreviouslyBookedData(booking.booking_id)
    setSelectedBooking(booking);
    setJoyRideCount(booking.joy_ride_count);
    setJoyRiderChildrenCount(booking.joy_ride_children_count);
    setFreeAdultJoyRideCount(booking.free_adult_joy_rides);
    setChildFreeJoyRideCount(booking.free_child_joy_rides);
    setAdults(booking.adults_count);
    setChildren(booking.children_count);
    setBelow2years(booking.below_2_year_count);
    setPaymentMode(booking.payment_mode);
    setSelectedSlot(booking);
    setInitialAdultsCount(booking.adults_count);
    setInitialChildrenCount(booking.children_count);
    setUserEmail(booking.email)
    setUserContact(booking.contact)
    console.log(booking.payment_note);
    if (booking.payment_mode === "Other") {
      setOthers(booking.payment_note);
      setIsothers(true);
    }

    // console.log(booking);
    toggleModal();
  };
  useEffect(() => {
    console.log(selectedSlot, "selectedSlot");
  }, [selectedSlot]);

  const handlePeopleChange = (e) => {
    switch (e.target.name) {
      case "adults":
        setAdults(e.target.value);
        break;
      case "children":
        setChildren(e.target.value);
        break;
      case "below2year":
        setBelow2years(e.target.value);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (guideModalOpen === false) {
      setSelectedGuide("0");
    }
  }, [guideModalOpen]);


  const getPreviouslyBookedData = (id) => {
    const API = `${process.env.REACT_APP_NODE_SERVER}/api/booking/getAllPreviousBooking?bookingId=`+id;
    prevBookFetch(API,{}, 'GET')
    // console.log("hi")
  }

  useEffect(() => {
    console.log(prevBookData)
    if (prevBookData?.results?.length >0) {
      setPreviouslyBooked(prevBookData.results)
    }
  },[prevBookData])

  const handleGuideAssignment = () => {
    console.log(selectedGuide);
    console.log(selectedBooking, "console log");
    const payload = {
      user_id: selectedBooking.user_id,
      slot_id: selectedBooking.slot_id,
      adults_count: Number(selectedBooking.adults_count),
      children_count: Number(selectedBooking.children_count),
      below_2_year_count: Number(selectedBooking.below_2_year_count),
      joy_ride_count: Number(selectedBooking.joy_ride_count),
      joy_ride_children_count: Number(selectedBooking.joy_ride_children_count),
      adult_joy_ride_total: Number(selectedBooking.adult_joy_ride_total),
      children_joy_ride_total: Number(selectedBooking.children_joy_ride_total),
      free_adult_joy_rides: Number(selectedBooking.free_adult_joy_rides),
      free_child_joy_rides: Number(selectedBooking.free_child_joy_rides),
      order_id: selectedBooking.order_id,
      payment_id: selectedBooking.payment_id,
      single_adult_price: selectedBooking.single_adult_price,
      single_child_price: selectedBooking.single_child_price,
      total_cost: selectedBooking.total_cost,
      amount_paid: selectedBooking.amount_paid,
      amount_pending: selectedBooking.amount_pending,
      payment_mode: selectedBooking.payment_mode,
      payment_note: selectedBooking.payment_note,
      refund_adult_count: selectedBooking.refund_adult_count,
      refund_children_count: selectedBooking.refund_children_count,
      refund_total_amount: selectedBooking.refund_total_amount,
      refund_type: selectedBooking.refund_type,
      booking_day: selectedBooking.booking_day,
      name: selectedBooking.name,
      email: selectedBooking.email,
      contact: selectedBooking.contact,
      is_birthday: selectedBooking.is_birthday,
      is_premium: selectedBooking.is_premium,
      guide: selectedGuide,
      is_special_day: selectedBooking.is_special_day,
      grade: selectedBooking.grade,
      archive: selectedBooking.archive,
    };
    postData(
      `${process.env.REACT_APP_NODE_SERVER}/api/booking/updateGuide/${selectedBooking.booking_id}`,
      payload,
      "PUT"
    );
  };
  const handleUpadateBeforePriceSettle = () => {
    console.log(selectedSlot);
    // return

    const payload = {
      user_id: selectedBooking.user_id,
      slot_id:
        selectedSlot !== null ? selectedSlot.slot_id : selectedBooking.slot_id,
      adults_count: Number(adults),
      children_count: Number(children),
      below_2_year_count: Number(below2years),
      joy_ride_count: Number(joyRideCount),
      joy_ride_children_count: Number(joyRiderChildrenCount),
      adult_joy_ride_total: Number(joyRideCount) * Number(joyRidePrice),
      children_joy_ride_total:
        Number(joyRiderChildrenCount) * Number(joyRidePriceChildren),
      free_adult_joy_rides: Number(AdultFreeJoyRideCount),
      free_child_joy_rides: Number(childFreeJoyRideCount),
      order_id: selectedBooking.order_id,
      payment_id: selectedBooking.payment_id,
      single_adult_price: selectedBooking.single_adult_price,
      single_child_price: selectedBooking.single_child_price,
      total_cost: selectedBooking.total_cost,
      amount_paid: selectedBooking.amount_paid,
      amount_pending: selectedBooking.amount_pending,
      payment_mode: paymentMode,
      payment_note: paymentMode === "Other" ? others : null,
      refund_adult_count:selectedBooking.refund_adult_count,
      refund_children_count:selectedBooking.refund_children_count,
      refund_total_amount:selectedBooking.refund_total_amount,
      adjusted_refund_amount:selectedBooking.adjusted_refund_amount,
      refund_type:selectedBooking.refund_type,
      booking_day: selectedBooking.booking_day,
      name: selectedBooking.name,
      email: selectedBooking.email,
      contact: selectedBooking.contact,
      guide: selectedBooking.guide,
      is_birthday: selectedBooking.is_birthday,
      is_premium: selectedBooking.is_premium,
      is_special_day: selectedBooking.is_special_day,
      grade: selectedBooking.grade,
      archive: selectedBooking.archive,
    };
    console.log(payload);
    changeSlot(
      `${process.env.REACT_APP_NODE_SERVER}/api/booking/update/${selectedBooking.booking_id}`,
      payload,
      "PUT"
    );
  };
  useEffect(() => {
    if (changeSlotRes && changeSlotRes.status === 200) {
      NotificationManager.success("Updated successfully!", "", 3000);
      setIsChangeSlot(false);
    }
    if (changeSlotPostErr) {
      NotificationManager.error(changeSlotPostErr, "", 3000);
    }
  }, [changeSlotRes, changeSlotPostErr]);
  
 
  const handleBookingDetailsUpdate = () => {
     console.log(selectedBooking, "console log");
    const payload = {
      user_id: selectedBooking.user_id,
      slot_id: selectedBooking.slot_id,
      adults_count: Number(selectedBooking.adults_count),
      children_count: Number(selectedBooking.children_count),
      below_2_year_count: Number(selectedBooking.below_2_year_count),
      joy_ride_count: Number(selectedBooking.joy_ride_count),
      joy_ride_children_count: Number(selectedBooking.joy_ride_children_count),
      adult_joy_ride_total: Number(selectedBooking.adult_joy_ride_total),
      children_joy_ride_total: Number(selectedBooking.children_joy_ride_total),
      free_adult_joy_rides: Number(selectedBooking.free_adult_joy_rides),
      free_child_joy_rides: Number(selectedBooking.free_child_joy_rides),
      order_id: selectedBooking.order_id,
      payment_id: selectedBooking.payment_id,
      single_adult_price: selectedBooking.single_adult_price,
      single_child_price: selectedBooking.single_child_price,
      total_cost: selectedBooking.total_cost,
      amount_paid: selectedBooking.total_cost,
      amount_pending: 0,
      payment_mode: paymentMode,
      payment_note: paymentMode === "Other" ? others : null,
      refund_adult_count: selectedBooking.refund_adult_count,
      refund_children_count: selectedBooking.refund_children_count,
      adjusted_refund_amount : 0,
      refund_total_amount: selectedBooking.refund_total_amount,
      refund_type: selectedBooking.refund_type,
      booking_day: selectedBooking.booking_day,
      name: selectedBooking.name,
      email: selectedBooking.email,
      contact: selectedBooking.contact,
      guide: selectedBooking.guide,
      is_birthday: selectedBooking.is_birthday,
      is_premium: selectedBooking.is_premium,
      is_special_day: selectedBooking.is_special_day,
      grade: selectedBooking.grade,
      archive: selectedBooking.archive,
    };
    console.log(payload, "payload");
    postData(
      `${process.env.REACT_APP_NODE_SERVER}/api/booking/update/${selectedBooking.booking_id}`,
      payload,
      "PUT"
    );
  };
  useEffect(() => {
    if (resData && resData.status === 200) {
      NotificationManager.success("Updated successfully!", "", 3000);
      setModalOpen(false);
      setInterval(() => {
        window.location.reload();
      }, 1500);
    }
    if (postError) {
      NotificationManager.error(postError, "", 3000);
    }
  }, [resData, postError]);
  const handleBookingChange = () => {
    //  console.log(selectedBooking, "console log");
    const payload = {
      user_id: selectedBooking.user_id,
      slot_id: selectedBooking.slot_id,
      adults_count: Number(adults),
      children_count: Number(children),
      below_2_year_count: Number(below2years),
      joy_ride_count: Number(joyRideCount),
      joy_ride_children_count: Number(joyRiderChildrenCount),
      adult_joy_ride_total: Number(joyRideCount) * Number(joyRidePrice),
      children_joy_ride_total:
        Number(joyRiderChildrenCount) * Number(joyRidePriceChildren),
      free_adult_joy_rides: Number(AdultFreeJoyRideCount),
      free_child_joy_rides: Number(childFreeJoyRideCount),
      order_id: selectedBooking.order_id,
      payment_id: selectedBooking.payment_id,
      single_adult_price: selectedBooking.single_adult_price,
      single_child_price: selectedBooking.single_child_price,
      total_cost: totalPrice,
      amount_paid: selectedBooking.amount_paid,
      amount_pending: totalPrice - Number(selectedBooking.amount_paid) - totalRefundableAmount +  adjustedRefundAmt + (selectedBooking.refund_type === "noRefund" ? selectedBooking.adjusted_refund_amount : 0 ),
      payment_mode: paymentMode,
      payment_note: paymentMode === "Other" ? others : null,
      refund_adult_count: Number(selectedBooking.refund_adult_count) + Number(numOfRefundableAdult),
      refund_children_count: Number(selectedBooking.refund_children_count) + Number(numOfRefundableChildren),
      refund_total_amount: Number(selectedBooking.refund_total_amount) + totalRefundableAmount,
      adjusted_refund_amount: Number(selectedBooking.adjusted_refund_amount)+ adjustedRefundAmt,
      refund_type: selectedRefundType === null ? selectedBooking.refund_type : selectedRefundType ,
      booking_day: selectedBooking.booking_day,
      name: selectedBooking.name,
      email: selectedBooking.email,
      contact: selectedBooking.contact,
      guide: selectedBooking.guide,
      is_birthday: selectedBooking.is_birthday,
      is_premium: selectedBooking.is_premium,
      is_special_day: selectedBooking.is_special_day,
      grade: selectedBooking.grade,
      archive: selectedBooking.archive,
    };
     console.log(payload, "payload");
    updateBookings(
      `${process.env.REACT_APP_NODE_SERVER}/api/booking/update/${selectedBooking.booking_id}`,
      payload,
      "PUT"
    );
  };
  
  useEffect(() => {
    if (updateBookingRes && updateBookingRes.status === 200) {
      NotificationManager.success("Updated successfully!", "", 3000);
      setModalOpen(false);
      setInterval(() => {
        window.location.reload();
      }, 1500);
    }
    if (updateBookingError) {
      NotificationManager.error(updateBookingError, "", 3000);
    }
  }, [updateBookingRes, updateBookingError]);
  const handleApplyFilter = () => {
    handleGuideChange(filterGuide);
    handleSlotChange(filterSlot);
    handlePicknerNameChange(filterPicnickerName);
    handlePicknerEmailChange(filteremail);
    handleContactChange(fitlerContact);
    handleSlotNameChange(filterSlotName);
    setFilterOpen(false);
    const filters = {filterGuide,filterSlot,filterPicnickerName,filteremail,fitlerContact,filterSlotName}
    localStorage.setItem('filters', JSON.stringify(filters));
  };
  const handleResetFilter = () => {
    localStorage.removeItem('filters');
    handleReset();
    setFilterGuide("");
    setFilterSlot("");
    setFilterPicnickerName("");
    setFilteremail("");
    setFilterContact("");
    setFilterSlotName("");
    setFilterOpen(false);
  };

  const handlepaymentMode = (e) => {
    console.log(e.target.value);
    if (e.target.value === "Other") {
      setIsothers(true);
      setPaymentMode(e.target.value);
    } else {
      setIsothers(false);
      setPaymentMode(e.target.value);
    }
  };
  useEffect(() => {
    if (selectedBooking) {
      handleNonRefundablePrice();
    }
  }, [numOfRefundableAdult, numOfRefundableChildren]);

  useEffect(() => {
    setnumOfRefundableAdult(0);
    setnumOfRefundableChildren(0);
    settotalRefundableAmount(0);
    setAdjustedRefundAmt(0);
  }, [selectedRefundType]);
  const handleRadioChange = (e) => {
    console.log(e.target.value);
    setSelectedRefundType(e.target.value);
  };
  const handleNonRefundablePrice = () => {
    if (selectedRefundType !== "") {
      if (selectedRefundType === "partialRefund") {
        console.log(
          (numOfRefundableAdult * Number(selectedBooking.single_adult_price)) /
            2 +
            (numOfRefundableChildren *
              Number(selectedBooking.single_child_price)) /
              2
        );
        settotalRefundableAmount(
          (numOfRefundableAdult * Number(selectedBooking.single_adult_price)) /
            2 +
            (numOfRefundableChildren *
              Number(selectedBooking.single_child_price)) /
              2
        );
        setAdjustedRefundAmt(
          (numOfRefundableAdult * Number(selectedBooking.single_adult_price)) /
            2 +
            (numOfRefundableChildren *
              Number(selectedBooking.single_child_price)) /
              2
        );
      }
     
      if (selectedRefundType === "noRefund") {
        settotalRefundableAmount(0);
        setAdjustedRefundAmt(
          (numOfRefundableAdult * Number(selectedBooking.single_adult_price)) /
            2 +
            (numOfRefundableChildren *
              Number(selectedBooking.single_child_price)) /
              2
        );
      }
    }
  };
  const handleDownloadExcelSheet = () => {
    // Define column names
    const columnNames = [
      "Booking ID",
      "Date of Visit",
      "Slot Time",
      "Name",
      "Mobile",
      "Quantity",
      "Big People",
      "Big People Amount",
      "Support Staff",
      "Lil Ones",
      "Lil Ones Amount",
      "Adult Joy Ride",
      "Adult Joyride Amount",
      "Kid Joy Ride",
      "Kids Joyride Amount",
      "Total Amount",
      "Advance Received",
      "Balance Remaining",
      "Balance Received",
      "Payment Method",
      "Is Birthday",
      "Is Premium",
      "Email",
    ];

    // Prepare data array with column names as the first row
    const mappedData = [
      columnNames,
      ...data.bookings.map((booking) => [
        booking.booking_id,
        new Date(booking.booking_date).toLocaleDateString("en-GB"),
        booking.slot_name.slice(0, 4) +
          " - " +
          booking.from_time +
          " - " +
          booking.to_time,
        booking.name,
        booking.contact,
        Number(booking.adults_count)+Number(booking.children_count),
        booking.adults_count,
        booking.single_adult_price,
        booking.support_staff_count,
        booking.children_count,
        booking.single_child_price,
        Number(booking.joy_ride_count)+Number(booking.free_adult_joy_rides),
        "₹"+booking.adult_joy_ride_total,
        Number(booking.joy_ride_children_count)+Number(booking.free_child_joy_rides),
        "₹"+booking.children_joy_ride_total,
        "₹"+booking.total_cost,
        "₹"+booking.advance_paid,
        "₹"+booking.amount_pending,
        "₹"+Number(booking.amount_paid-booking.advance_paid),
        booking.payment_mode,
        booking.is_birthday === 1 ? "Yes" : "No",
        booking.is_premium === 1 ? "Yes" : "No",
        booking.email,
      ]),
    ];

    // Generate Excel sheet
    const ws = XLSX.utils.aoa_to_sheet(mappedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Bookings");
    XLSX.writeFile(
      wb,
      `bookings(${new Date(booking_date).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })}).xlsx`
    );
  };
  const handleSlotChangeData = (e) => {
    const selectedValue = JSON.parse(e.target.value);
    setSelectedSlot(selectedValue);
  };
  const handleCancelBooking = () => {
    const confirmed = window.confirm("Are you sure you want to cancel the booking?");
  if(confirmed){
    const payload = {
      "order_id" : selectedBooking.order_id,
      "reason_for_cancellation" : "Admin cancellation",
      "booking_id": selectedBooking.booking_id
    }
    cancelBooking(`${process.env.REACT_APP_NODE_SERVER}/api/cancellation/manual_cancellation`,payload,"POST");
  }

  }
  useEffect(()=>{
    if(cancelRes && cancelRes.status === 200){
      NotificationManager.success("Canelled Successfully", "", 3000);
    }
    if(cancelErr){
      NotificationManager.error(cancelErr, "", 3000);
    }
    
  },[cancelRes,cancelErr])
  const getInitials = (slotName) => {
    const words = slotName.split(' ');
    const initials = words.map(word => word[0]).join('');
     return initials + 'P';
  };
  const handleAddComment = (booking_id) =>{
  postComment(`${process.env.REACT_APP_NODE_SERVER}/api/booking/update-comment/${booking_id}`,{comment},"POST");
  }

  useEffect(()=>{
    if(commentRes && commentRes.status === 200){
      NotificationManager.success("Comment Posted successfully!", "", 3000);
      setTimeout(()=>{
        window.location.reload();
      },1000)
    }
    if(commentErr){
      NotificationManager.error(commentErr, "", 3000);
    }
  },[commentRes,commentErr])

  const handleEditEmail = (booking_id) => {
    console.log(booking_id,userEmail);
    const payload = {
      email: userEmail
    }
    updateEmail(`${process.env.REACT_APP_NODE_SERVER}/api/booking/update_email/${booking_id}`,payload,"PUT")
  }

  const handleEditContact = (booking_id) => {
    console.log(booking_id,userContact);
    const payload = {
      contact: userContact
    }
    updateContact(`${process.env.REACT_APP_NODE_SERVER}/api/booking/update_contact/${booking_id}`,payload,"PUT")
  }

  useEffect(()=>{
    if(updateContactRes && updateContactRes.status === 200){
      NotificationManager.success("Contact updated successfully!", "", 3000);
      setEditContact(false);
      fetchData()
    }
    if(updateContactErr){
      NotificationManager.error(updateContactErr, "", 3000);
    }
  },[updateContactRes,updateContactErr])
  
  useEffect(()=>{
    if(updateEmailRes && updateEmailRes.status === 200){
      NotificationManager.success("Email updated successfully!", "", 3000);
      setEditEmail(false);
      fetchData()
    }
    if(updateEmailErr){
      NotificationManager.error(updateEmailErr, "", 3000);
    }
  },[updateEmailRes,updateEmailErr])

  return (
    <>
      <NotificationContainer />
      <Col className="mb-5 mb-xl-0" xl="12">
        <Card className="shadow">
          <CardHeader className="border-0">
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">All Bookings</h3>
                <div className="col mt-2 mb-2 pl-0">
                  <Button
                    type="button"
                    className={`btn ${tab === "0" && "btn-primary"}`}
                    onClick={() => handleChangeTab("0")}
                  >
                    Picnic
                  </Button>
                  <Button
                    type="button"
                    className={`btn ${tab === "1" && "btn-primary"}`}
                    onClick={() => handleChangeTab("1")}
                  >
                    School Picnic
                  </Button>
                </div>
                <span></span>
                {data.slotWiseCount.map((slot) => (
                  <span className="mr-2" key={slot.Slotname}>
                    <span style={{ fontWeight: "bold" }}>{slot.Slotname}</span>{" "}
                    (Big: {slot.adult}, Lil: {slot.Children}, 2{">"}years:{" "}
                    {slot.below_2_years}, AJR : {slot.AJR}, KJR: {slot.KJR})
                  </span>
                ))}
              </div>
              <div className="text-right d-flex align-items-center position-relative">
                <span className="mr-4">
                  <i
                    onClick={toggleFilter}
                    className="ri-filter-3-line"
                    title="Filters"
                    style={{ fontSize: "22px", cursor: "pointer" }}
                  ></i>
                  {filterGuide ||
                  filterSlot ||
                  filterPicnickerName ||
                  filteremail ||
                  fitlerContact ||
                  filterSlotName ? (
                    <span
                      className="badge badge-pill badge-success ml-1 position-absolute"
                      style={{ top: "-1px", right: "210px" }}
                    >
                      {(filterGuide ? 1 : 0) +
                        (filterSlot ? 1 : 0) +
                        (filterPicnickerName ? 1 : 0) +
                        (filteremail ? 1 : 0) +
                        (fitlerContact ? 1 : 0) +
                        (filterSlotName ? 1 : 0)}
                    </span>
                  ) : null}
                </span>
                <span className="mr-4">
                  <i
                    className="ri-download-2-line"
                    title="Download"
                    onClick={handleDownloadExcelSheet}
                    style={{ fontSize: "22px", cursor: "pointer" }}
                  ></i>
                </span>
                <Input
                  type="date"
                  value={booking_date}
                  onChange={(e) => handleChangeDate(e.target.value)}
                />
              </div>
            </Row>
          </CardHeader>
          <div className="table-responsive">
            <Table className="align-items-center table-flush responsive">
              <thead className="thead-light">
                {data && data.bookings.length > 0 && (
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Comment</th>
                    <th scope="col">Contact</th>
                    <th scope="col">Slot</th>
                    <th scope="col">Big People</th>
                    {tab === "1" && <th>Support Staff</th>}
                    <th scope="col">Lil' Ones</th>
                    <th scope="col">2{">"}Years</th>
                    <th scope="col">AJR</th>
                    <th scope="col">KJR</th>
                    <th scope="col">Amount Paid</th>
                    <th scope="col">Pending</th>
                    <th scope="col">Total</th>
                    <th scope="col">Guide</th>
                    {/* <th scope="col">Add Guide</th> */}
                    {/* <th scope="col">Action</th> */}
                    <th scope="col"></th>
                  </tr>
                )}
                {data && data.bookings.length === 0 && (
                  <tr>
                    <th></th>
                  </tr>
                )}
              </thead>
              <tbody>
                {data &&
                  data.bookings.map((each) => (
                    <tr key={each.booking_id}>
                      <th scope="row">
                        <div
                          className="bookingClientName"
                          onClick={() => openBookingDetails(each)}
                        >
                          {each.name} {each.previously_booked ? "⭐" : ""}
                        </div>
                      </th>
                      <td>
                        <span
                          type="button"
                          className="position-relative"
                          onClick={() => openCommentModal(each)} // Open chat modal on click
                        >
                          <i
                            className="ri-chat-1-line"
                            style={{ fontSize: "23px" }}
                          ></i>
                          <span className="position-absolute top-0 start-100 left-3 translate-middle badge rounded-pill bg-danger text-white px-2">
                            {each.comment && each.comment.length > 0 ? "1" : ""}
                          </span>
                        </span>
                      </td>
                      <td>{each.contact}</td>
                      <td>{getInitials(each.slot_name)}</td>
                      <td>{each.adults_count}</td>
                      {tab === "1" && <td>{each.support_staff_count}</td>}
                      <td>{each.children_count}</td>
                      <td>{each.below_2_year_count}</td>
                      <td>{each.joy_ride_count + each.free_adult_joy_rides}</td>
                      <td>
                        {each.joy_ride_children_count +
                          each.free_child_joy_rides}
                      </td>
                      <td className="text-success">₹{each.amount_paid}</td>
                      <td className="text-danger">₹{each.amount_pending}</td>
                      <td>₹{each.total_cost}</td>
                      <td>
                        {each.guide > 0 ? (
                          <span
                            style={{
                              color: each.guide_color_code
                                ? each.guide_color_code
                                : "#000",
                              fontWeight: "bold",
                            }}
                          >
                            {each.guide_name}
                          </span>
                        ) : (
                          <span>Not Assigned</span>
                        )}
                      </td>
                      {/* <td>
                      <Button size="sm" color="primary" onClick={()=>openSelectGuideModal(each)}>Add Guide</Button>
                    </td> */}
                      {/* <td>
                      <Button size="sm" color="primary" onClick={() => openBookingDetails(each)}>Details</Button>
                    </td> */}
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              onClick={() => openSelectGuideModal(each)}
                            >
                              Assign Guide
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => openBookingDetails(each)}
                            >
                              Details
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => openCommentModal(each)}
                            >
                              Add Comment
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                {data && data.bookings.length === 0 && (
                  <tr>
                    <td className="text-danger">No booking available!</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Card>
      </Col>

      {/* Modal */}
      <Modal
        className="booking-details-modal"
        isOpen={modalOpen}
        toggle={toggleModal}
      >
        <ModalHeader toggle={toggleModal}>Booking Details</ModalHeader>
        <ModalBody>
          {selectedBooking && (
            <div>
              <p>
                Booking Date:{" "}
                {new Date(selectedBooking.booking_date).toLocaleDateString(
                  "en-GB"
                )}{" "}
                <Button
                  color="danger"
                  size="sm"
                  onClick={handleCancelBooking}
                  className="ml-3"
                >
                  Cancel Booking
                </Button>
              </p>
              <p></p>
              <div className="row">
                <div className="col-sm-6">
                  <p>Name: {selectedBooking.name}</p>
                  {!editContact && (
                    <p>
                      Contact Number: {userContact} {""}
                      <i
                        onClick={() => setEditContact(true)}
                        className="ri-edit-2-line text-success"
                        style={{ fontSize: "22px", cursor: "pointer" }}
                        title="Edit Contact"
                      ></i>
                    </p>
                  )}
                  {editContact && (
                    <p>
                      Edit Contact:{" "}
                      <Input
                        value={userContact}
                        className="mb-2"
                        onChange={(e) => setUserContact(e.target.value)}
                      />{" "}
                      <Button
                        color="primary"
                        size="sm"
                        disabled={updateContactPending}
                        onClick={() =>
                          handleEditContact(selectedBooking.booking_id)
                        }
                      >
                        {updateContactPending ? "Updating..." : "Update"}
                      </Button>{" "}
                      <Button
                        onClick={() => setEditContact(false)}
                        size="sm"
                        color="danger"
                      >
                        Cancel
                      </Button>
                    </p>
                  )}
                  {!editEmail && (
                    <p>
                      Email: {userEmail}{" "}
                      <i
                        onClick={() => setEditEmail(true)}
                        className="ri-edit-2-line text-success"
                        style={{ fontSize: "22px", cursor: "pointer" }}
                        title="Edit Email"
                      ></i>
                    </p>
                  )}
                  {editEmail && (
                    <p>
                      Edit Email:{" "}
                      <Input
                        value={userEmail}
                        className="mb-2"
                        onChange={(e) => setUserEmail(e.target.value)}
                      />{" "}
                      <Button
                        color="primary"
                        size="sm"
                        disabled={updateEmailPending}
                        onClick={() =>
                          handleEditEmail(selectedBooking.booking_id)
                        }
                      >
                        {updateEmailPending ? "Updating..." : "Update"}
                      </Button>{" "}
                      <Button
                        onClick={() => setEditEmail(false)}
                        size="sm"
                        color="danger"
                      >
                        Cancel
                      </Button>
                    </p>
                  )}
                </div>
                <div className="col-sm-6">
                  <p>
                    <span>
                      Is Birthday:{" "}
                      {selectedBooking.is_birthday === 1 ? "Yes" : "No"}
                    </span>{" "}
                  </p>
                  <p>
                    <span>
                      Is Premium:{" "}
                      {selectedBooking.is_premium === 1 ? "Yes" : "No"}
                    </span>
                  </p>
                  <p>
                    <>
                      {" "}
                      {!isChangeSlot && selectedSlot && (
                        <>
                          Slot: {selectedSlot.slot_name}{" "}
                          <Button
                            size="sm"
                            color="primary"
                            onClick={() => setIsChangeSlot(!isChangeSlot)}
                          >
                            Change Slot
                          </Button>
                        </>
                      )}
                      {isChangeSlot && selectedSlot && (
                        <>
                          {" "}
                          Select Slot :{" "}
                          <Input
                            type="select"
                            value={JSON.stringify(selectedSlot)}
                            onChange={handleSlotChangeData}
                          >
                            <option value="">Select Slot</option>
                            {slotData &&
                              slotData.slots.map((slot) => (
                                <option
                                  value={JSON.stringify(slot)}
                                  key={slot.slot_id}
                                >
                                  {slot.slot_name} (
                                  {new Date(
                                    `2000-01-01T${slot.from_time}`
                                  ).toLocaleTimeString("en-US", {
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  })}{" "}
                                  -{" "}
                                  {new Date(
                                    `2000-01-01T${slot.to_time}`
                                  ).toLocaleTimeString("en-US", {
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  })}
                                  )
                                </option>
                              ))}
                          </Input>
                          <div className="mt-2">
                            <Button
                              size="sm"
                              color="primary"
                              onClick={() => setIsChangeSlot(!isChangeSlot)}
                            >
                              Cancel
                            </Button>
                            <Button
                              size="sm"
                              color="primary"
                              onClick={handleUpadateBeforePriceSettle}
                            >
                              Save
                            </Button>
                          </div>
                        </>
                      )}
                    </>
                  </p>
                </div>
              </div>
             
              {previously_booked?.length > 0 && (
                <>
                  <hr/>
                  <div className="d-flex">
                    <span className="border-right p-2">Previous Booking Dates:</span>
                    <span className="text-primary pl-2 flex-1">
                      🔖 {previously_booked?.length > 0 && previously_booked[0]?.all_previously_booked_dates
                        ? previously_booked[0].all_previously_booked_dates.split(",").join(" 🔖 ")
                        : <span className="text-warning">No Previous Booking</span>}
                    </span>
                  </div></>
              )}
              <hr />
              {/* <p>Days Left: {selectedBooking.daysLeft}</p> */}
              <Button
                className="my-2"
                color="primary"
                size="sm"
                onClick={() => setIsAddpeople(!isAddPeople)}
              >
                {isAddPeople ? "Cancel" : "Add People"}
              </Button>
              <Button
                className="my-2"
                color="primary"
                size="sm"
                onClick={() => setIsJoyRide(!isJoyRide)}
              >
                {isJoyRide ? "Cancel" : "Add Joy Ride(s)"}
              </Button>
              <Button
                className="my-2"
                color="primary"
                size="sm"
                onClick={() => setIsFreeJoyRide(!isFreeJoyRide)}
              >
                {isFreeJoyRide ? "Cancel" : "Add Complimentary Joy Ride(s)"}
              </Button>
              {/* Add People */}
              {isAddPeople && (
                <>
                  <div
                    className="d-flex align-items-center"
                    style={{ flexDirection: "row" }}
                  >
                    <div className="mr-5">
                      <h4>Add People</h4>
                    </div>
                    <div>
                      <i
                        onClick={() => setIsAddpeople(!isAddPeople)}
                        className="ri-close-circle-line cursor-pointer text-danger"
                        title="Cancel"
                        style={{ fontSize: "30px", cursor: "pointer" }}
                      ></i>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-4">
                      <Label for="numberOfAdults">
                        <small>Adults</small>
                      </Label>
                      <Input
                        type="number"
                        value={adults}
                        name="adults"
                        onChange={(e) => handlePeopleChange(e)}
                        min="0"
                        placeholder="Adult"
                      />
                    </div>
                    <div className="col-4">
                      <Label for="children">
                        <small>Children</small>
                      </Label>
                      <Input
                        type="number"
                        value={children}
                        name="children"
                        onChange={(e) => handlePeopleChange(e)}
                        min="0"
                        placeholder="Children"
                      />
                    </div>
                    <div className="col-4">
                      <Label for="below2year">
                        <small>Below 2 Year</small>
                      </Label>
                      <Input
                        type="number"
                        value={below2years}
                        name="below2year"
                        onChange={(e) => handlePeopleChange(e)}
                        min="0"
                        placeholder="Below 2 Year"
                      />
                    </div>
                    {/* Non Refundable */}
                  </div>
                  <div className="mb-2">
                    <h4>Refund</h4>
                    {/* <CustomInput
                      type="radio"
                      id="fullRefund"
                      name="fullRefund"
                      label="Full Refund"
                      value = 'fullRefund'
                      checked={selectedRefundType === 'fullRefund'}
                      inline
                      onChange={(e)=>handleRadioChange(e)}
                    /> */}
                    <CustomInput
                      type="radio"
                      id="partialRefund"
                      name="partialRefund"
                      value="partialRefund"
                      label="50% Refund"
                      checked={selectedRefundType === "partialRefund"}
                      inline
                      onChange={(e) => handleRadioChange(e)}
                    />
                    <CustomInput
                      type="radio"
                      id="noRefund"
                      value="noRefund"
                      name="noRefund"
                      label="No Refund"
                      checked={selectedRefundType === "noRefund"}
                      inline
                      onChange={(e) => handleRadioChange(e)}
                    />
                    {selectedRefundType !== "" && (
                      <div className="row mt-3">
                        <div className="col-sm-6">
                          <Label for="joyRide">
                            <small>No. of big people for refund</small>
                          </Label>
                          <Input
                            type="number"
                            value={numOfRefundableAdult}
                            name="numOfRefundableAdult"
                            onChange={(e) =>
                              setnumOfRefundableAdult(e.target.value)
                            }
                            min="0"
                          />
                        </div>
                        <div className="col-sm-6">
                          <Label for="joyRide">
                            <small>No. of lil Ones for refund</small>
                          </Label>
                          <Input
                            type="number"
                            value={numOfRefundableChildren}
                            name="numOfRefundableChildren"
                            onChange={(e) =>
                              setnumOfRefundableChildren(e.target.value)
                            }
                            min="0"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
              {/* Add Joy Ride */}
              {isJoyRide && (
                <>
                  <hr />
                  <div
                    className="d-flex align-items-center"
                    style={{ flexDirection: "row" }}
                  >
                    <div className="mr-5">
                      <h4>Add Joy Ride(s)</h4>
                    </div>
                    <div>
                      <i
                        onClick={() => setIsJoyRide(!isJoyRide)}
                        className="ri-close-circle-line cursor-pointer text-danger"
                        title="Cancel"
                        style={{ fontSize: "30px", cursor: "pointer" }}
                      ></i>
                    </div>
                  </div>
                  <div className="mb-4 row">
                    <div className="col-sm-6">
                      <Label for="joyRide">
                        <small>No. Joy Ride(s) Big People</small>
                      </Label>
                      <Input
                        type="number"
                        value={joyRideCount}
                        name="joyRide"
                        onChange={(e) => setJoyRideCount(e.target.value)}
                        min="0"
                      />
                    </div>
                    <div className="col-sm-6">
                      <Label for="joyRide">
                        <small>No. Joy Ride(s) Lil Ones</small>
                      </Label>
                      <Input
                        type="number"
                        value={joyRiderChildrenCount}
                        name="joyRide"
                        onChange={(e) =>
                          setJoyRiderChildrenCount(e.target.value)
                        }
                        min="0"
                      />
                    </div>
                  </div>
                </>
              )}
              {/* Complementry Joy Ride */}
              {isFreeJoyRide && (
                <>
                  <hr />
                  <div
                    className="d-flex align-items-center"
                    style={{ flexDirection: "row" }}
                  >
                    <div className="mr-5">
                      <h4>Add Complementry Joy Ride(s)</h4>
                    </div>
                    <div>
                      <i
                        onClick={() => setIsFreeJoyRide(!isFreeJoyRide)}
                        className="ri-close-circle-line cursor-pointer text-danger"
                        title="Cancel"
                        style={{ fontSize: "30px", cursor: "pointer" }}
                      ></i>
                    </div>
                  </div>
                  <div className="mb-4 row">
                    <div className="col-sm-6">
                      <Label for="joyRide">
                        <small>No. Joy Ride(s) Big People</small>
                      </Label>
                      <Input
                        type="number"
                        value={AdultFreeJoyRideCount}
                        name="joyRide"
                        onChange={(e) =>
                          setFreeAdultJoyRideCount(e.target.value)
                        }
                        min="0"
                      />
                    </div>
                    <div className="col-sm-6">
                      <Label for="joyRide">
                        <small>No. Joy Ride(s) Lil Ones</small>
                      </Label>
                      <Input
                        type="number"
                        value={childFreeJoyRideCount}
                        name="joyRide"
                        onChange={(e) =>
                          setChildFreeJoyRideCount(e.target.value)
                        }
                        min="0"
                      />
                    </div>
                  </div>
                </>
              )}
              {(isAddPeople || isFreeJoyRide || isJoyRide) && (
                <div>
                  <Button onClick={handleBookingChange} color="success">
                    Save
                  </Button>
                </div>
              )}
              <hr />
              <p>Summary:</p>
              <div className="table-responsive">
                <table className="align-items-center table table-bordered responsive">
                  <thead>
                    <tr>
                      <th />
                      <th scope="col">No. of People</th>
                      <th scope="col">Per Person Cost</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Big People</td>
                      <td>{Number(adults)}</td>
                      <td>₹{Number(selectedBooking.single_adult_price)}</td>
                      <td>
                        ₹
                        {Number(adults) *
                          Number(selectedBooking.single_adult_price)}
                      </td>
                    </tr>
                    {Number(children) > 0 && (
                      <tr>
                        <td>Lil' People</td>
                        <td>{Number(children)}</td>
                        <td>₹{Number(selectedBooking.single_child_price)}</td>
                        <td>
                          ₹
                          {Number(children) *
                            Number(selectedBooking.single_child_price)}
                        </td>
                      </tr>
                    )}
                    {Number(below2years) > 0 && (
                      <tr>
                        <td>Below 2 Years</td>
                        <td>{Number(below2years)}</td>
                        <td>₹0</td>
                        <td>₹0</td>
                      </tr>
                    )}
                    {Number(joyRideCount) > 0 && (
                      <tr>
                        <td>Big People Joy Ride</td>
                        <td>{Number(joyRideCount)}</td>
                        <td>₹{Number(joyRidePrice)}</td>
                        <td>₹{Number(joyRideCount) * Number(joyRidePrice)}</td>
                      </tr>
                    )}
                    {Number(joyRiderChildrenCount) > 0 && (
                      <tr>
                        <td>Lil' People Joy Ride</td>
                        <td>{Number(joyRiderChildrenCount)}</td>
                        <td>₹{Number(joyRidePriceChildren)}</td>
                        <td>
                          ₹
                          {Number(joyRiderChildrenCount) *
                            Number(joyRidePriceChildren)}
                        </td>
                      </tr>
                    )}
                    {Number(AdultFreeJoyRideCount) > 0 && (
                      <tr>
                        <td>Complementary Big People Joy Ride</td>
                        <td>{Number(AdultFreeJoyRideCount)}</td>
                        <td>₹0</td>
                        <td>₹0</td>
                      </tr>
                    )}
                    {Number(childFreeJoyRideCount) > 0 && (
                      <tr>
                        <td>Complementary Lil' People Joy Ride</td>
                        <td>{Number(childFreeJoyRideCount)}</td>
                        <td>₹0</td>
                        <td>₹0</td>
                      </tr>
                    )}
                    {(selectedBooking.slot_id === 60 ||
                      selectedBooking.slot_id === 61) &&
                      bithdayPrice && (
                        <tr>
                          <td colSpan={3}>Celebration</td>
                          <td>₹{bithdayPrice}</td>
                        </tr>
                      )}
                    <tr>
                      <td colSpan={3}>Total</td>
                      <td>₹{totalPrice}</td>
                    </tr>
                    <tr>
                      <td colSpan={3}>Already Paid</td>
                      <td className="text-success">
                        ₹{selectedBooking.amount_paid}
                      </td>
                    </tr>
                    {selectedRefundType === "partialRefund" && (
                      <tr>
                        <td colSpan={3}>Refund</td>
                        <td className="text-danger">
                          -₹{totalRefundableAmount}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan={3}>Balance Amount</td>
                      {/* <td className="text-danger">
                        {totalPrice - Number(selectedBooking.amount_paid) < 0
                          ? `-₹${Math.abs(
                              totalPrice - Number(selectedBooking.amount_paid)
                            )}`
                          : `₹${
                              totalPrice -
                              Number(selectedBooking.amount_paid) -
                              totalRefundableAmount 
                              //- `${Number(selectedBooking.amount_pending) === 0 ? 0 : Number(selectedBooking.refund_total_amount)}` 
                              + adjustedRefundAmt + Number(`${Number(selectedBooking.amount_pending) === 0 ? 0 : Number(selectedBooking.adjusted_refund_amount)}`)
                            }`}
                      </td> */}
                      <td className="text-danger">
                        ₹
                        {totalPrice -
                          Number(selectedBooking.amount_paid) -
                          totalRefundableAmount +
                          adjustedRefundAmt +
                          (selectedBooking.refund_type === "noRefund"
                            ? selectedBooking.adjusted_refund_amount
                            : 0)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {selectedBooking.refund_type !== null && (
                <>
                  <p>Refund:</p>
                  <div className="table-responsive">
                    <table className="align-items-center table table-bordered responsive">
                      <thead>
                        <tr>
                          <th>Big People</th>
                          <th>Lil' People</th>
                          <th>Refund Type</th>
                          <th>Total Refund</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{selectedBooking.refund_adult_count}</td>
                          <td>{selectedBooking.refund_children_count}</td>
                          <td>
                            {selectedBooking.refund_type === "partialRefund"
                              ? "50% Refund"
                              : "No Refund"}
                          </td>
                          <td>₹{selectedBooking.refund_total_amount}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              )}
              {/* <h4 className="text-danger">
                Please{" "}
                {totalPrice - Number(selectedBooking.amount_paid) > 0
                  ? "collect"
                  : "pay"}{" "}
                the pending amount of{" "}
                {totalPrice - Number(selectedBooking.amount_paid) < 0
                  ? `-₹${Math.abs(
                      totalPrice - Number(selectedBooking.amount_paid) -
                      totalRefundableAmount - Number(selectedBooking.refund_total_amount)
                    )}`
                  : `₹${
                      totalPrice -
                      Number(selectedBooking.amount_paid) -
                      totalRefundableAmount - `${Number(selectedBooking.amount_pending) === 0 ? 0 : Number(selectedBooking.refund_total_amount)}` + adjustedRefundAmt
                    }`}
              </h4> */}
              <div className="mb-3 mt-3 row">
                <div className="col-sm-6">
                  <Label>Select Payment Mode</Label>
                  <Input
                    value={paymentMode}
                    onChange={(e) => handlepaymentMode(e)}
                    type="select"
                  >
                    <option value="">Select</option>
                    <option value="cash">Cash</option>
                    <option vlaue="qrscan">QR Scan</option>
                    <option vlaue="other">Other</option>
                  </Input>
                </div>
                {isOthers && (
                  <div className="col-sm-6">
                    <Label>Specify Other</Label>
                    <Input
                      value={others}
                      onChange={(e) => setOthers(e.target.value)}
                      type="text"
                    />
                  </div>
                )}
              </div>
              Alreay collected?{" "}
              {!isPending && (
                <Button
                  color="primary"
                  size="sm"
                  onClick={handleBookingDetailsUpdate}
                >
                  Update
                </Button>
              )}
              {isPending && (
                <Button color="primary" size="sm" disabled>
                  Updating
                </Button>
              )}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      {/* Filter */}
      <Modal isOpen={filterOpen} toggle={toggleFilter}>
        <ModalHeader toggle={toggleFilter}>Filter Options</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-sm-6">
              <Label for="Guide">
                <small>Select Guide</small>
              </Label>
              <Input
                type="select"
                value={filterGuide}
                onChange={(e) => setFilterGuide(e.target.value)}
              >
                <option value="">Select Guide</option>
                <option value="0">Not Assigned</option>
                {guides &&
                  guides.guides.map((guide) => (
                    <option value={guide.guide_id} key={guide.guide_id}>
                      {guide.guide_name}
                    </option>
                  ))}
              </Input>
            </div>
            <div className="col-sm-6">
              <Label for="Slot">
                <small>Select Slot</small>
              </Label>
              <Input
                type="select"
                value={filterSlot}
                onChange={(e) => setFilterSlot(e.target.value)}
              >
                <option value="0">Select Slot</option>
                {slots &&
                  slots.slots.map((slot) => (
                    <option value={slot.slot_id} key={slot.slot_id}>
                      {slot.slot_name}
                    </option>
                  ))}
              </Input>
            </div>
            <div className="col-sm-12 mt-2">
              <Label>
                <small>Search by Slot Name</small>
              </Label>
              <Input
                type="text"
                value={filterSlotName}
                onChange={(e) => setFilterSlotName(e.target.value)}
              />
            </div>
            <div className="col sm-6 mt-2">
              <Label>
                <small>picnicker Name</small>
              </Label>
              <Input
                type="text"
                value={filterPicnickerName}
                onChange={(e) => setFilterPicnickerName(e.target.value)}
              />
            </div>
            <div className="col sm-6 mt-2">
              <Label>
                <small>picnicker Email</small>
              </Label>
              <Input
                type="text"
                value={filteremail}
                onChange={(e) => setFilteremail(e.target.value)}
              />
            </div>
            <div className="col-sm-12 mt-2">
              <Label>
                <small>picnicker Contact</small>
              </Label>
              <Input
                type="text"
                value={fitlerContact}
                onChange={(e) => setFilterContact(e.target.value)}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleApplyFilter}>
            Apply Filter
          </Button>{" "}
          <Button color="secondary" onClick={handleResetFilter}>
            Reset
          </Button>
        </ModalFooter>
      </Modal>
      {/* Guide Modal */}
      <Modal isOpen={guideModalOpen} toggle={toggleGuideModal}>
        <ModalHeader toggle={toggleGuideModal}>Select Guide</ModalHeader>
        <ModalBody>
          <Label for="joyRide">
            <small>Select Guide</small>
          </Label>
          <Input
            type="select"
            value={selectedGuide}
            onChange={(e) => setSelectedGuide(e.target.value)}
          >
            <option value="0">Select Guide</option>
            {guides &&
              guides.guides.map((guide) => (
                <option value={guide.guide_id} key={guide.guide_id}>
                  {guide.guide_name}
                </option>
              ))}
          </Input>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleGuideAssignment}>
            Assign Guide
          </Button>{" "}
          <Button color="secondary" onClick={toggleGuideModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {/* Comment Modal */}
      {selectedBooking && (
        <Modal isOpen={commentModalOpen} toggle={toggleCommentModal}>
          <ModalHeader toggle={toggleCommentModal}>Comment</ModalHeader>
          <ModalBody>
            <Label for="joyRide">
              <small>Add Comment</small>
            </Label>
            <Input
              type="textarea"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => handleAddComment(selectedBooking.booking_id)}
            >
              {isPending ? "Adding..." : "Add"}
            </Button>{" "}
            <Button color="secondary" onClick={toggleCommentModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}
