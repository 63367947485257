import { usePost } from "hooks/usePost";
import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  FormGroup,
  Table,
  Input,
  Button,
  Col,
  Row,
  Card,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from "reactstrap";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useFetch } from "hooks/useFetch";
export default function ExcludeDates() {
  const [selectedDates, setSelectedDates] = useState([]);
  const {postData,resData,postError,isPending} = usePost();
  const {postData:archiveDate,resData:archiveRes,postError:archiveErr,isPending:archivePending} = usePost();
  const {data,fetchIsPending} = useFetch(`${process.env.REACT_APP_NODE_SERVER}/api/inclusiveDates/getAll`);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedDate,setSelectedDate] = useState(null);

  const handleDateChange = (event, index) => {
    const newDates = [...selectedDates];
    newDates[index] = event.target.value;
    setSelectedDates(newDates);
  };

  const handleAddDateInput = () => {
    setSelectedDates([...selectedDates, ""]);
  };

  const handleRemoveDateInput = (index) => {
    const newDates = selectedDates.filter((_, i) => i !== index);
    setSelectedDates(newDates);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const filtredDates = selectedDates.filter((each)=> each !== "")
    const payload = {
      dates : filtredDates
    }
    postData(`${process.env.REACT_APP_NODE_SERVER}/api/inclusiveDates/addMultipleDates`,payload,"POST")
  };

  useEffect(()=>{
    if(resData && resData.status ===200){
      NotificationManager.success('Date added successfully!', '', 3000);
      window.location.reload();
    }
    if(postError){
      NotificationManager.error(postError, '', 3000);
    }
  },[resData,postError])

  const handleCancelDelete = () => {
    setDeleteModalOpen(false);
  };
  const handleConfirmDelete = () => {
    console.log(selectedDate);
    archiveDate(`${process.env.REACT_APP_NODE_SERVER}/api/inclusiveDates/archive/${selectedDate.inclusive_date_id}`,{},"PUT")
  };

  useEffect(()=>{
    if(archiveRes && archiveRes.status ===200){
      setDeleteModalOpen(false);
      NotificationManager.success('Deleted updated successfully!', '', 3000);
      setInterval(() => {
          window.location.reload();
      }, 1000);
    }
    if(archiveErr){
      NotificationManager.error(postError, '', 3000);
    }
  },[archiveRes,archiveErr])

  const handleDeleteModal = (date) =>{
    setDeleteModalOpen(true)
    setSelectedDate(date);
    console.log(date);
  }
  return (
    <Container className="pt-8">
      <h3>Include dates in off days</h3>
      <NotificationContainer/>
      <div className="row">
        <div className="col-sm-8">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0">Include Dates</h3>
                </div>
              </Row>
            </CardHeader>
           {data && <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Dates</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {fetchIsPending && <td><Spinner color="primary" style={{ height: '3rem',width: '3rem'}}></Spinner></td>}
                {data.dates.length === 0 && <td className="text-danger">No dates to show</td>}
                {data.dates.map((date)=>(<tr key={date.inclusive_date_id}>
                  <td>{new Date(date.inclusive_date).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}</td>
                  <td> <i className="ri-delete-bin-5-line text-danger" title="Delete" style={{fontSize:'18px',cursor:'pointer'}} onClick={() => handleDeleteModal(date)}></i></td>
                </tr>))}
              </tbody>
            </Table>}
          </Card>
        </div>
        <div className="col-sm-4" style={{ width: "40%" }}>
          <Form onSubmit={handleSubmit}>
            {selectedDates.map((date, index) => (
              <FormGroup key={index}>
                <Row className="d-flex">
                  <Col className="justify-center">
                    {/* <Label for={`date${index}`}>Date {index + 1}</Label> */}
                    <Input
                      type="date"
                      id={`date${index}`}
                      value={date}
                      onChange={(event) => handleDateChange(event, index)}
                    />
                  </Col>
                  <Col>
                    <Button
                      className="justify-center"
                      color="danger"
                      onClick={() => handleRemoveDateInput(index)}
                    >
                      <i className="ri-delete-bin-5-line" title="Delete"></i>
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            ))}
            <Button
              className="mt-2"
              color="primary"
              onClick={handleAddDateInput}
            >
              Add Date
            </Button>
            <Button className="mt-2" color="success" disabled={isPending} type="submit">
              {!isPending ? "Submit" : "submitting..."}
            </Button>
          </Form>
        </div>
      </div>
      <Modal isOpen={deleteModalOpen} toggle={handleCancelDelete}>
        <ModalHeader toggle={handleCancelDelete}>Confirm Delete</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this date?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleConfirmDelete} disabled={archivePending}>{archivePending ? "Deleting..." : "Yes"}</Button>
          <Button color="secondary" onClick={handleCancelDelete}>No</Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}
