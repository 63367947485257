import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  CustomInput,
  Label,
  ModalFooter,
  Button,
  FormText,
  Spinner,
} from "reactstrap";
import "./Booking.css";
import { usePost } from "hooks/usePost";
import ModalComponent from "../admin/components/ModalComponent";
import { useFetch } from "hooks/useFetch";
import { cashfree } from "cashfree/Util";

export default function Booking() {
  const { data: dayData } = useFetch(
    `${process.env.REACT_APP_NODE_SERVER}/api/day/getAll`
  );
  const [modal, setModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [childBelowTwo, setChildBelowTwo] = useState(0);
  const [adultNum, setAdultNum] = useState(1);
  const [childrenNum, setChildrenNum] = useState(0);
  const [slotData, setSlotData] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [orderTableId, setOrderTableId] = useState(null);
  const { postData, resData, postError, isPending } = usePost();
  const { data:fetchedDates} = useFetch(`${process.env.REACT_APP_NODE_SERVER}/api/disabledYears/getAll`);
  const {
    postData: createOrder,
    resData: orderRes,
    postError: orderCreatingError,
    isPending: orderPending,
  } = usePost();
  const [isSlotFull, setIsSlotFull] = useState(false);
  const [slotsLeft, setSlotsLeft] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [specialDates, setSpecialDates] = useState([]);
  const { data: exclusiveDate } = useFetch(
    `${process.env.REACT_APP_NODE_SERVER}/api/exclusiveDates/getAll`
  );
  const { data: inclusiveDate } = useFetch(
    `${process.env.REACT_APP_NODE_SERVER}/api/InclusiveDates/getAll`
  );
  const DISABLED_MONTHS = [];
  // const DISABLED_DATES_ARRAY = [
  //   new Date('2024-05-20'), // April 20, 2024
  //   new Date('2024-05-25'),
  //   new Date('2024-05-30'), // April 25, 2024
  //   // Add more disabled dates as needed
  // ];
  const [DISABLED_DATES_ARRAY, setDISABLED_DATES_ARRAY] = useState(null);
  const [SPECIAL_DATES_ARRAY, setSPECIAL_DATES_ARRAY] = useState(null);
  useEffect(() => {
    if (inclusiveDate && inclusiveDate.status === 200) {
      console.log(inclusiveDate.dates);
      const convertedDates = inclusiveDate.dates.map(
        (dateObj) => new Date(dateObj.inclusive_date)
      );
      setDISABLED_DATES_ARRAY(convertedDates);
      // console.log(convertedDates,'converter')
    }
  }, [inclusiveDate]);
  useEffect(() => {
    if (exclusiveDate && exclusiveDate.status === 200) {
      console.log(exclusiveDate.dates);
      const convertedDates = exclusiveDate.dates.map(
        (dateObj) => new Date(dateObj.exclusive_date)
      );
      setSPECIAL_DATES_ARRAY(convertedDates);
      // console.log(convertedDates,"converted")
    }
  }, [exclusiveDate]);
  // const [sessionId,setSessionId] = useState('');
  const currentDate = new Date(); // Get the current date
  let currentDayOfWeek = currentDate.getDay(); // Get the current day of the week (0-6, where 0 is Sunday)
  if (currentDayOfWeek === 0) {
    // If it's Sunday, map it to 7
    currentDayOfWeek = 7;
  }

  const {
    postData: bookSlot,
    resData: bookingRes,
    postError: bookingError,
    isPending: bookingIsPending,
  } = usePost();
  const [isOpen, setIsOpen] = useState(false);

  const toggleModalComponent = () => {
    setIsOpen(!isOpen);
  };
  const isDisabledReactCalendar = ({ date, view }) => {
    // Disable Tuesdays and Wednesdays
    if (date.getDay() === 2 || date.getDay() === 3) {
      return true;
    }
    // Disable specific dates
    const disabledDates = [new Date("2024-03-10"), new Date("2024-03-18")];

    return disabledDates.some(
      (disabledDate) => date.toDateString() === disabledDate.toDateString()
    );
  };

  const toggleModal = () => {
    if (!modal) {
      setAdultNum(1);
      setChildrenNum(0);
      setChildBelowTwo(0);
      setSelectedSlot("");
      setTotalPrice(0);
    }
    setModal(!modal);
  };

  const handleCalendarClick = async (date) => {
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    const dayOfWeekNumber = date.getDay() === 0 ? 7 : date.getDay();
    const dayOfWeekName = date.toLocaleDateString("en-US", { weekday: "long" });
    console.log(dayOfWeekName, dayOfWeekNumber);
    setSelectedDate({
      formattedDate: formattedDate,
      date: date,
      dayOfWeek: dayOfWeekNumber,
      dayOfWeekName: dayOfWeekName,
    });

    const response = await fetch(
      process.env.REACT_APP_NODE_SERVER +
        "/api/slotDetails/getSlotByDay/" +
        dayOfWeekNumber +
        "/" +
        formattedDate
    );
    console.log(response);
    try {
      setIsLoading(true);
      if (response.status !== 200) {
        throw new Error("Something went wrong!");
      }
      const data = await response.json();
      if (data.status === 200) {
        console.log(data);
        setSlotData(data);
        setIsLoading(false);
        toggleModal();
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleRadioChange = (slot) => {
    console.log(slot);
    setSelectedSlot(slot);
  };
  const checkSlotAvailability = async () => {
    setSlotsLeft(null);
    console.log(selectedSlot, "selected Slot");
    const payload = {
      booking_date: selectedDate.formattedDate,
      max_adults: selectedSlot.max_adults,
      requestingAdults: Number(adultNum),
      slot_id: selectedSlot.slot_id,
    };
    console.log(payload);
    postData(
      process.env.REACT_APP_NODE_SERVER +
        "/api/slotDetails/checkSlotAvailability",
      payload,
      "POST"
    );
  };

  useEffect(() => {
    if (resData && resData.status === 200) {
      if (!resData.isSlotFull) {
        setIsSlotFull(resData.isSlotFull);
        calcultatePrice();
      } else {
        setIsSlotFull(resData.isSlotFull);
        setSlotsLeft(resData.slotsRemaining);
      }
    }
    if (postError) {
      NotificationManager.error(postError, "Error", 3000);
    }
  }, [resData, postError]);

  useEffect(() => {
    if (selectedSlot !== "") {
      console.log(selectedSlot);
      setTotalPrice(0);
      setIsSlotFull(true);
      checkSlotAvailability();
    }
  }, [selectedSlot, adultNum, childrenNum]);

  const calcultatePrice = () => {
    let totalPrice =
      adultNum * Number(selectedSlot.adult_price) +
      childrenNum * Number(selectedSlot.children_price);
    console.log(totalPrice);
    setTotalPrice(totalPrice);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const payload = {
      user_id: Number(contact),
      slot_id: Number(selectedSlot.slot_id),
      adults_count: adultNum,
      children_count: childrenNum,
      below_2_year_count: Number(childBelowTwo),
      joy_ride_count: 0,
      joy_ride_children_count: 0,
      adult_joy_ride_total: 0,
      children_joy_ride_total: 0,
      single_adult_price: selectedSlot.adult_price,
      single_child_price: selectedSlot.children_price,
      total_cost: totalPrice,
      amount_pending: Number(totalPrice) / 2,
      booking_day: currentDayOfWeek,
      booking_date: selectedDate.formattedDate,
      name: name,
      email: email,
      contact: contact,
      is_special_day: false,
      grade: 0,
      booking_type: 0,
      support_staff_count: 0,
      with_food: 0,
      is_discount: 0,
      archive: 0,
    };

    if (
      selectedSlot === "" ||
      totalPrice === 0 ||
      name === "" ||
      email === "" ||
      contact === "" ||
      agreeTerms === false ||
      isSlotFull === true
    ) {
      NotificationManager.error(
        "Please fill the required details!",
        "Error",
        3000
      );
      return;
    }
    bookSlot(
      process.env.REACT_APP_NODE_SERVER + "/api/orders/create",
      payload,
      "POST"
    );
  };
  useEffect(() => {
    if (bookingRes && bookingRes.status === 200) {
      setOrderTableId(bookingRes.order_id);
      getSessionId(bookingRes.order_id);
    }
    if (bookingError) {
      NotificationManager.error(bookingError, "Error", 3000);
    }
  }, [bookingRes, bookingError]);

  const modalBody = () => {
    return (
      <div>
        1. If you request to cancel your booking 48 hours before your scheduled visit, you will receive a 100% refund.
        <br /><br />
        2. For cancellations less than 48 hours prior to the scheduled visit. No refunds shall be possible.
        <br /><br />
        3. For cancelling your booking, we request you submit a cancellation request by clicking on the 'cancel booking' option provided in your booking confirmation email.
        <br/><br />
       4. For any further assistance- please reach out to our WhatsApp helpdesk on <a href="tel:9158004105">9158004105</a>.

      </div>
    );
  };
  const modalTitle = () => {
    return <div>Cancellation Policy</div>;
  };
  const modalBody2 = () => {
    return (
      <div>
        1. If you request to cancel at 48 hours before your scheduled visit, you
        will receive a 100% refund.
        <br />
        <br />
        2. For cancellations Less than 48 hours prior to the scheduled visit. No
        rescheduling or refunds shall be possible
        <br />
        <br />
        3. For any cancellation requests, please contact us directly through
        WhatsApp at 9158004105 or email us at bookings@japalouppe.net, and make
        sure to provide your booking ID for faster assistance.
      </div>
    );
  };
  const modalTitle2 = () => {
    return <div>Cancellation Policy</div>;
  };

  const getSessionId = (orderIdFromTable) => {
    console.log(orderIdFromTable);
    if (
      selectedSlot === "" ||
      totalPrice === 0 ||
      name === "" ||
      email === "" ||
      contact === "" ||
      agreeTerms === false ||
      isSlotFull === true
    ) {
      NotificationManager.error(
        "Please fill the required details!",
        "Error",
        3000
      );
      return;
    }
    const payload = {
      cf_order_id: orderIdFromTable,
      created_at: new Date().toISOString(),
      customer_details: {
        customer_id: contact,
        customer_name: name,
        customer_email: email,
        customer_phone: contact,
      },
      entity: "order",
      order_amount: Number(totalPrice) / 2,
      order_currency: "INR",
      // "order_expiry_time": "2024-05-01T11:19:02+05:30",
      order_meta: {
        return_url: `${process.env.REACT_APP_NODE_SERVER}/spectator/index`,
        // "notify_url": `https://horseridingcamps.net:7000/api/payment/webhook`,
        payment_methods: "cc,dc,ccc,ppc,nb,upi,paypal,app",
      },
      order_tags: {
        orderId: String(orderIdFromTable),
        bookingDate: selectedDate.formattedDate,
      },
      order_note: "Petting farm booking",
      order_status: "ACTIVE",
    };
    createOrder(
      `${process.env.REACT_APP_NODE_SERVER}/api/payment/createAnOrder`,
      payload,
      "POST"
    );
  };
  useEffect(() => {
    if (orderRes && orderRes.status === 200) {
      console.log(orderRes);
      handlePayment(
        orderRes.responseData.payment_session_id,
        orderRes.responseData.order_id
      );
    }
    if (orderCreatingError) {
      NotificationManager.error(orderCreatingError, "", 3000);
    }
  }, [orderCreatingError, orderRes]);
  const handlePayment = (sessionId, orderId) => {
    let checkoutOptions = {
      paymentSessionId: sessionId,
      returnUrl: `${process.env.REACT_APP_REACT_SERVER}/spectator/success/${orderTableId}/${orderId}/${selectedDate.formattedDate}`,
    };
    cashfree.checkout(checkoutOptions).then(function (result) {
      if (result.error) {
        alert(result.error.message);
      }
      if (result.redirect) {
        console.log("Redirection");
      }
    });
  };
  const handleContactChange = (e) => {
    const value = e.target.value;
    // Remove non-digit characters and limit the length to 10
    const sanitizedValue = value.replace(/\D/g, '').slice(0, 10);
    setContact(sanitizedValue);
  };
  useEffect(() => {
    // Simulated fetch from backend API
    const fetchSpecialDates = async () => {
      try {
        // Replace with actual API endpoint
        const response = await fetch(
          `${process.env.REACT_APP_NODE_SERVER}/api/specialDates/getAll`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch special dates");
        }
        console.log(response, "res");
        const data = await response.json();
        console.log(data, "special data");
        setSpecialDates(data.special_dates);
      } catch (error) {
        console.error("Error fetching special dates:", error);
      }
    };

    fetchSpecialDates();
  }, []);
  return (
    <div className="container py-5 d-flex justify-content-center align-items-center">
      <NotificationContainer />
      {dayData && fetchedDates &&
        SPECIAL_DATES_ARRAY &&
        DISABLED_DATES_ARRAY &&
        DISABLED_MONTHS && (
          <Calendar
          tileContent={({ date }) => {
            const today = new Date();
            today.setUTCHours(0, 0, 0, 0); // Set hours to 0 in UTC to compare dates without time
        
            // Format the date to match from_date/to_date format in UTC
            const formattedDate = new Date(
              Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
            )
              .toISOString()
              .split("T")[0];
        
            if (date < today) {
              return null;
            }
        
            // Check if the date is within the range of any special date
            const specialDateInfo = specialDates.find((specialDate) => {
              const fromDate = new Date(
                Date.UTC(
                  new Date(specialDate.from_date).getFullYear(),
                  new Date(specialDate.from_date).getMonth(),
                  new Date(specialDate.from_date).getDate()
                )
              )
                .toISOString()
                .split("T")[0];
              const toDate = new Date(
                Date.UTC(
                  new Date(specialDate.to_date).getFullYear(),
                  new Date(specialDate.to_date).getMonth(),
                  new Date(specialDate.to_date).getDate()
                )
              )
                .toISOString()
                .split("T")[0];
              return formattedDate >= fromDate && formattedDate <= toDate;
            });
        
            const isSpecialDate = specialDateInfo !== undefined;
            const isMarkedAsRegular = specialDateInfo?.mark_as_regular;
        
            // Check if the date is in SPECIAL_DATES_ARRAY
            const isSpecialDate1 = SPECIAL_DATES_ARRAY.some((specialDate) => {
              return (
                new Date(
                  Date.UTC(
                    new Date(specialDate).getFullYear(),
                    new Date(specialDate).getMonth(),
                    new Date(specialDate).getDate()
                  )
                )
                  .toISOString()
                  .split("T")[0] === formattedDate
              );
            });
        
            // Check if the date is disabled
            const isDisabledDate = DISABLED_DATES_ARRAY.some((disabledDate) => {
              const disabledFormattedDate = new Date(
                Date.UTC(
                  disabledDate.getFullYear(),
                  disabledDate.getMonth(),
                  disabledDate.getDate()
                )
              )
                .toISOString()
                .split("T")[0];
              return disabledFormattedDate === formattedDate;
            });
        
            if (isDisabledDate) {
              return null;
            }
        
            const dayOfWeek = date.getDay();
            const adjustedDayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;
            const daydata = dayData?.days.find(
              (day) => day.day_id === adjustedDayOfWeek
            );
        
            if (daydata && daydata.is_weekly_off === 1) {
              const isSpecialDate1 = SPECIAL_DATES_ARRAY.some((specialDate) => {
                return (
                  new Date(
                    Date.UTC(
                      new Date(specialDate).getFullYear(),
                      new Date(specialDate).getMonth(),
                      new Date(specialDate).getDate()
                    )
                  )
                    .toISOString()
                    .split("T")[0] === formattedDate
                );
              });
        
              if (!isSpecialDate1) {
                return null;
              }
            }
        
            const customMonth = date.getUTCMonth() + 1; // JavaScript months are 0-indexed
            if (DISABLED_MONTHS.includes(customMonth)) {
              return null;
            }
            let isAfterFetchedDateDisabled = false;
            if(fetchedDates.dates.length>0){
              const lastDateOf2024 = new Date(fetchedDates.dates[0].date);
              // console.log(lastDateOf2024,"last date after",new Date(2024, 11, 31))
              if (date >= lastDateOf2024) {
                isAfterFetchedDateDisabled= true;
              }
              else {
                isAfterFetchedDateDisabled= false
              }
            }
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                {isAfterFetchedDateDisabled ? null : (
                  isSpecialDate || isSpecialDate1 ? (
                    <span className={isMarkedAsRegular === 1 ? "text-primary" : isMarkedAsRegular === 2 ?"text-warning": "text-success"}>
                      <small>
                        {isMarkedAsRegular === 1 
                        ? "Reg." 
                        : isMarkedAsRegular === 2 
                        ? "Prem." 
                        : "Spec."}
                      </small>
                    </span>
                  ) 
                  : (
                    <span className="text-primary">
                      <small>Reg.</small>
                    </span>
                  )
                )}
              </div>
            );
          }}
          tileDisabled={({ date }) => {
            // Convert JavaScript month (0-indexed) to custom month (1-indexed)
            const customMonth = date.getMonth() + 1;
    
            // Check if the date is within any of the disabled months
            const isDisabledMonth = DISABLED_MONTHS.includes(customMonth);
    
            if (isDisabledMonth) {
              return true; // Disable the tile for the entire disabled month
            }
    
            // Check if the date is in the disabled dates array
            const isDisabledDate = DISABLED_DATES_ARRAY.some((disabledDate) => {
              // Compare dates without considering time
              return disabledDate.toDateString() === date.toDateString();
            });
    
            if (isDisabledDate) {
              return true; // Disable the tile for disabled dates
            }
    
            const dayOfWeek = date.getDay(); // Get the day of the week (0-6, where 0 is Sunday)
            // Adjust dayOfWeek to match your day_id (1-7, where 1 is Monday and 7 is Sunday)
            const adjustedDayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;
            const daydata = dayData?.days.find(
              (day) => day.day_id === adjustedDayOfWeek
            );
            // If dayData exists and is_weekly_off is 1, disable the tile
            if (daydata && daydata.is_weekly_off === 1) {
              // Check if the date is a special date, if yes, don't disable it
              const isSpecialDate = SPECIAL_DATES_ARRAY.some((specialDate) => {
                // Compare dates without considering time
                return specialDate.toDateString() === date.toDateString();
              });
              return !isSpecialDate;
            }
    
            // Disable dates beyond December 31, 2024
            
           
            if(fetchedDates.dates.length>0){
              const lastDateOf2024 = new Date(fetchedDates.dates[0].date);
              // console.log(lastDateOf2024,"last date after",new Date(2024, 11, 31))
              if (date >= lastDateOf2024) {
                return true;
              }
            }
    
            return false; // Enable the tile for non-weekly off days
          }}
            onClickDay={handleCalendarClick}
            minDate={new Date()}
          />
        )}

      <Modal
        isOpen={modal}
        toggle={toggleModal}
        className="booking-details-modal"
      >
        <ModalHeader toggle={toggleModal} tag="h3">
            Booking Date{" "}
            {selectedDate
              ? `${selectedDate.date.getDate().toString().padStart(2, "0")}/${(
                  selectedDate.date.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}/${selectedDate.date.getFullYear()}`
              : ""}
        </ModalHeader>
        {slotData && slotData.slots.length === 0 && (
          <div className="ml-3 mb-5">
            <h2 className="text-warning">Sorry no slots are available!</h2>
            <p>Please select another date.</p>
          </div>
        )}
        {slotData && slotData.slots.length > 0 && (
          <Form role="form" onSubmit={handleFormSubmit}>
            <ModalBody>
              <div className="row">
                <FormGroup className="mb-3 col-sm-4">
                  <Label for="numberOfAdults">
                    <small>Big people (10 years plus)</small>
                  </Label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Number of adults"
                      type="number"
                      name="numberOfAdults"
                      value={adultNum}
                      onChange={(e) => setAdultNum(e.target.value)}
                      min="1"
                    />
                  </InputGroup>
                  {/* <FormText>
                    10 years and above
                  </FormText> */}
                </FormGroup>
                <FormGroup className="mb-3 col-sm-4">
                  <Label for="numberOfAdults">
                    <small>Lil People (2-10 years)</small>
                  </Label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Number of children"
                      type="number"
                      name="numberOfChildren"
                      value={childrenNum}
                      onChange={(e) => setChildrenNum(e.target.value)}
                      min="0"
                    />
                  </InputGroup>
                  {/* <FormText>
                    2-10 years old
                  </FormText> */}
                </FormGroup>
                <FormGroup className="mb-3 col-sm-4">
                  <Label for="numberOfAdults">
                    <small>Below 2 years</small>
                  </Label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Number of children below 2"
                      type="number"
                      name="numberOfChildren"
                      value={childBelowTwo}
                      onChange={(e) => setChildBelowTwo(e.target.value)}
                      min="0"
                    />
                  </InputGroup>
                  <FormText>Free Entry</FormText>
                </FormGroup>
                {
                  <FormGroup className="mb-3 ml-3">
                    <Label for="exampleCheckbox">
                      <small>Select Slot Timings</small>
                    </Label>
                    <div>
                      {slotData &&
                        slotData.slots.map((slot) => {
                          // Convert from 24-hour format to 12-hour format
                          if (slot.slot_type == 1) {
                            const fromTime12Hour = new Date(
                              `2000-01-01T${slot.from_time}`
                            ).toLocaleTimeString("en-US", {
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            });
                            const toTime12Hour = new Date(
                              `2000-01-01T${slot.to_time}`
                            ).toLocaleTimeString("en-US", {
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            });
  
                            return (
                              <CustomInput
                                key={slot.slot_id}
                                type="radio"
                                id={slot.slot_id}
                                name={slot.slot_name}
                                label={
                                  <span>
                                    <strong>{slot.slot_name}</strong> - {fromTime12Hour} to {toTime12Hour} 
                                    {" "}<span className="pt-0 ml-1" style={{top:"0"}}>{slot.available_adults > 0 ? <span className="text-success top-0" style={{top:"0"}}>({slot.available_adults} Adult slots are remaining)</span> : <span className="text-danger"  style={{top:"0"}}>(No slots are available)</span>}</span>
                                  </span>
                                }
                                checked={
                                  Number(selectedSlot.slot_id) === slot.slot_id
                                }
                                onChange={() => handleRadioChange(slot)}
                                // inline
                              />
                            );
                          }
                        })}
                    </div>
                  </FormGroup>
                }
              </div>
              <div>
                {isPending && (
                  <div className="text-center mt-3">
                    {" "}
                    <Spinner
                      color="primary"
                      style={{ height: "3rem", width: "3rem" }}
                    ></Spinner>
                  </div>
                )}

                {totalPrice > 0 && !isPending && (
                  <>
                    <p>Booking Summary:</p>
                    <div className="table-responsive">
                      <table className="align-items-center table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">People</th>
                            <th scope="col">No. of People</th>
                            <th scope="col">Cost Per Person</th>
                            <th scope="col">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Big People</td>
                            <td>{adultNum}</td>
                            <td>₹{selectedSlot.adult_price}</td>
                            <td className="text-success">
                              ₹{Number(adultNum * selectedSlot.adult_price)}
                            </td>
                          </tr>
                          {childrenNum > 0 && (
                            <tr>
                              <td>Lil People</td>
                              <td>{childrenNum}</td>
                              <td>₹{selectedSlot.children_price}</td>
                              <td className="text-success">
                                ₹
                                {Number(
                                  childrenNum * selectedSlot.children_price
                                )}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td colSpan="3">Final Total</td>
                            <td className="text-success">
                              ₹
                              {Number(adultNum * selectedSlot.adult_price) +
                                Number(
                                  childrenNum * selectedSlot.children_price
                                )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    {/* <h4 className="text-success">
                      This will be a 50% (₹{totalPrice / 2}) advance payment. You
                      may pay the remaining 50% (₹{totalPrice / 2}) in cash or via
                      UPI when you arrive at the farm.
                    </h4> */}
                  </>
                )}
                {!isPending && isSlotFull && (
                  <h4 className="text-danger">
                    {slotsLeft > 0
                      ? `Only ${slotsLeft} adult slot(s) is available`
                      : `No slots are available`}
                  </h4>
                )}
              </div>
              {totalPrice > 0 && (
                <>
                  <hr />
                  <h4>Fill the following details</h4>
                  <div className="row">
                    <FormGroup className="mb-3 col-sm-6">
                      <Label for="name">
                        <small>Name</small>
                      </Label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Your name"
                          type="text"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3 col-sm-6">
                      <Label for="email">
                        <small>Email</small>
                      </Label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Your email"
                          type="email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </InputGroup>
                      <FormText><span className="text-info"><i className="ri-information-2-line text-info"></i>Booking confirmation shall be sent on this email id</span></FormText>
                    </FormGroup>
                    <FormGroup className="mb-3 col-sm-12">
                      <Label for="contact">
                        <small>WhatsApp Number</small>
                      </Label>
                      <InputGroup className="input-group-alternative">
                        <div
                          className="input-group-text"
                          style={{ background: "#eee" }}
                        >
                          +91
                        </div>
                        <Input
                          placeholder=" Your WhatsApp Number"
                          type="tel"
                          name="contact"
                          value={contact}
                          max={10}
                          onChange={handleContactChange}
                        />
                      </InputGroup>
                      <FormText><span className="text-info"><i className="ri-information-2-line text-info"></i>Please enter WhatsApp enabled number</span></FormText>
                    </FormGroup>
                    <h4 className="text-success px-3">
                      You will only be making an advance payment.of 50% (₹
                      {totalPrice / 2}) The balance (₹{totalPrice / 2}) will be
                      payable at the farm in cash or via UPI.
                    </h4>
                    <FormGroup check className="ml-3 mt-3">
                      <Label check>
                        <Input
                          type="checkbox"
                          name="agreeTerms"
                          checked={agreeTerms}
                          onChange={(e) => setAgreeTerms(e.target.checked)}
                        />{" "}
                        I have read the
                      </Label>{" "}
                      <span
                        className="text-danger"
                        onClick={toggleModalComponent}
                        style={{ cursor: "pointer" }}
                      >
                        cancellation policy
                      </span>
                    </FormGroup>
                  </div>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              {!orderPending && (
                <Button
                  className="my-4"
                  color="primary"
                  disabled={!agreeTerms}
                  type="submit"
                >
                  Total Payable ₹{totalPrice / 2}
                </Button>
              )}
              {orderPending && (
                <Button className="my-4" color="primary" disabled type="button">
                  Booking...
                </Button>
              )}
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Modal>
      <ModalComponent
        isOpen={isOpen}
        toggleModalComponent={toggleModalComponent}
        body={modalBody()}
        title={modalTitle()}
      />
      {/* <ModalComponent isOpen={isOpen} toggleModalComponent={toggleModalComponent} body={modalBody2()} title={modalTitle2()}/> */}
    </div>
  );
}
