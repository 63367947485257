import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Table,
  Row,
  Col,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Form
} from "reactstrap";
import "react-notifications/lib/notifications.css";
import { useFetch } from "hooks/useFetch";
import { usePost } from "hooks/usePost";
import { Link } from "react-router-dom";

export default function AllBookingTableSpec({
  data,
  booking_date,
  handleChangeDate,
  guide,
  handleGuideChange,
  slot,
  handleSlotChange,
  slotName,
  handleSlotNameChange,
  picnickerName,
  handlePicknerNameChange,
  email,
  contact,
  handlePicknerEmailChange,
  handleContactChange,
  handleReset,
}) {
  const [filterOpen, setFilterOpen] = useState(localStorage.getItem('FilterModalOpen') ? JSON.parse(localStorage.getItem('FilterModalOpen')) : false);
  const [filterGuide, setFilterGuide] = useState(guide);
  const [filterSlot, setFilterSlot] = useState(slot);
  const [filterSlotName, setFilterSlotName] = useState(slotName);
  const [filterPicnickerName, setFilterPicnickerName] = useState(picnickerName);
  const [filteremail, setFilteremail] = useState(email);
  const [fitlerContact, setFilterContact] = useState(contact);
  const [isChatModalOpen, setChatModalOpen] = useState(false); // State for managing chat modal visibility
  const [checkedBookings,setCheckedBookings] = useState([ ]);
  const [adultCount,setAdultCount] = useState(0);
  const [childrenCount,setChildrenCount] = useState(0);
  const [below2YearsCount,setBelow2YearsCount] = useState(0);
  const [selectedBooking,setSelectedBooking] = useState(null);
  const { postData, resData, postError, isPending } = usePost();
  const { data: guides } = useFetch(
    `${process.env.REACT_APP_NODE_SERVER}/api/guide/getAll`
  );
  const { data: slots } = useFetch(
    `${process.env.REACT_APP_NODE_SERVER}/api/slot/getAll`
  );

  const toggleFilter = () => {
    localStorage.setItem("FilterModalOpen", JSON.stringify(!filterOpen));
    setFilterOpen(!filterOpen);
  };

  const handleApplyFilter = () => {
    handleGuideChange(filterGuide);
    handleSlotChange(filterSlot);
    handlePicknerNameChange(filterPicnickerName);
    handlePicknerEmailChange(filteremail);
    handleContactChange(fitlerContact);
    handleSlotNameChange(filterSlotName);
    setFilterOpen(false);
    const filters = { filterGuide, filterSlot, filterPicnickerName, filteremail, fitlerContact, filterSlotName };
    localStorage.setItem('filters', JSON.stringify(filters));
  };

  const handleResetFilter = () => {
    localStorage.removeItem('filters');
    handleReset();
    setFilterGuide("");
    setFilterSlot("");
    setFilterPicnickerName("");
    setFilteremail("");
    setFilterContact("");
    setFilterSlotName("");
    setFilterOpen(false);
  };

  const getInitials = (slotName) => {
    const words = slotName.split(' ');
    const initials = words.map(word => word[0]).join('');
    return initials + 'P';
  };

  // Toggle chat modal visibility
  const toggleChatModal = () => {
    setChatModalOpen(!isChatModalOpen);
    if(selectedBooking && isChatModalOpen){
      postData(`${process.env.REACT_APP_NODE_SERVER}/api/booking/update-mark-as-read/${selectedBooking.booking_id}`,{},"POST");
    }
  };

  useEffect(()=>{
      if(resData && resData.status === 200){
        console.log('Updated as read')
      }
  },resData)
  const openChatModal = (booking) =>{
    setSelectedBooking(booking);
    toggleChatModal();
  }
  const handleCheckbox = (selectedBooking) => {
    setCheckedBookings((prev) => {
      // Check if any booking in the array has the same booking_id as selectedBooking
      const isAlreadyChecked = prev.some(
        (item) => item.booking_id === selectedBooking.booking_id
      );
  
      if (isAlreadyChecked) {
        // If it's already checked, remove it
        return prev.filter((item) => item.booking_id !== selectedBooking.booking_id);
      } else {
        // If it's not checked, add it
        return [...prev, selectedBooking];
      }
    });
  };
  

  useEffect(()=>{
    //console.log(checkedBookings);
    const adultCountNum = checkedBookings.reduce((acc,each)=>{
      return acc = each.adults_count+acc
    },0)
    const childrenCountNum = checkedBookings.reduce((acc,each)=>{
      return acc = each.children_count+acc
    },0)
    const below2YearsCountNum = checkedBookings.reduce((acc,each)=>{
      return acc = each.below_2_year_count+acc
    },0)
    //console.log(adultCountNum,"adult count----",childrenCountNum,"children----",below2YearsCountNum,"Below 2");
    setAdultCount(adultCountNum);
    setChildrenCount(childrenCountNum);
    setBelow2YearsCount(below2YearsCountNum);
  },[checkedBookings])
  
  return (
    <>
      <Col className="mb-5 mb-xl-0 m-3" xl="12">
        {data?.slotWiseCount?.map((slot) => (
          <span className="mr-2" key={slot.Slotname}>
            <span style={{ fontWeight: "bold" }}>
              {slot.Slotname}
            </span> (Big: {slot.adult},
              Lil: {slot.Children},
              2{">"}years: {slot.below_2_years},
              AJR : {slot.AJR},
              KJR: {slot.KJR}
            )
          </span>
        ))}
        <span className='p-2 text-right'><Link to={"/spectator/guideOverview"}>Show Guide Overview</Link></span>

        <Card className="shadow mt-3">
          <CardHeader className="border-0 sticky-top" >
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">All Bookings</h3>
                {adultCount > 0 &&
                  <> 
                    <span style={{ fontWeight: "bold" }}>
                      Count
                    </span>{" "}
                    <span className="mr-2">
                      (
                        Big: {adultCount},
                        Lil: {childrenCount},
                        2{">"}years: {below2YearsCount}
                      )
                    </span>
                    </>}
              </div>
              <div className="text-right d-flex align-items-center position-relative">
                <span className="mr-4">
                  <i
                    onClick={toggleFilter}
                    className="ri-filter-3-line"
                    title="Filters"
                    style={{ fontSize: "22px", cursor: "pointer" }}
                  ></i>
                  {filterGuide ||
                    filterSlot ||
                    filterPicnickerName ||
                    filteremail ||
                    fitlerContact || filterSlotName ? (
                    <span
                      className="badge badge-pill badge-success ml-1 position-absolute"
                      style={{ top: "-1px", right: "165px" }}
                    >
                      {(filterGuide ? 1 : 0) +
                        (filterSlot ? 1 : 0) +
                        (filterPicnickerName ? 1 : 0) +
                        (filteremail ? 1 : 0) +
                        (fitlerContact ? 1 : 0) +
                        (filterSlotName ? 1 : 0)
                      }
                    </span>
                  ) : null}
                </span>
                <Input
                  type="date"
                  value={booking_date}
                  onChange={(e) => handleChangeDate(e.target.value)}
                />
              </div>
            </Row>
          </CardHeader>
          <div className="table-responsive" 
            //style={{ maxHeight: '90vh', overflowY: 'auto' }}
          >
            <Table className="align-items-center table-flush responsive">
              <thead className="thead-light">
                {data && data.bookings.length > 0 && (
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Name</th>
                    <th scope="col">Comments</th>
                    <th scope="col">Contact</th>
                    <th scope="col">Slot</th>
                    <th scope="col">A</th>
                    <th scope="col">SS</th>
                    <th scope="col">K</th>
                    <th scope="col">2 &gt; yrs</th>
                    <th scope="col">AJR</th>
                    <th scope="col">KJR</th>
                    <th scope="col">Guide</th>
                  </tr>
                )}
                {data && data.bookings.length === 0 && (
                  <tr>
                    <th></th>
                  </tr>
                )}
              </thead>
              <tbody>
                {data &&
                  data.bookings.map((each) => (
                    <tr key={each.booking_id}>
                      <th>
                      <Form>
                        <FormGroup
                          check
                          inline
                        >
                          <Input type="checkbox" onChange={()=>{handleCheckbox(each)}}/>
                          <Label check>
                          </Label>
                        </FormGroup>
                      </Form>
                      </th>
                      <th scope="row">
                        <span className="mr-2">{each.amount_pending === 0 ?
                          <i className="ri-check-fill text-success" style={{ fontSize: '16px' }}></i> :
                          <i className="ri-close-line text-danger" style={{ fontSize: '16px' }}></i>}
                        </span>{" "}
                        {each.name}
                      </th>
                      <td>
                        <span
                          type="button"
                          className="position-relative"
                          onClick={()=>openChatModal(each)} // Open chat modal on click
                        >
                          <i className="ri-chat-1-line" style={{ fontSize: '23px' }}></i>
                          <span className="position-absolute top-0 start-100 left-3 translate-middle badge rounded-pill bg-danger text-white px-2">
                            {(each.comment && each.comment.length > 0) ? "1": ""}
                          </span>
                        </span>
                      </td>
                      <td><a href={`tel:${each.contact}`}>{each.contact}</a></td>
                      <td><span className="text-uppercase">{getInitials(each.slot_name)}</span></td>
                      <td>{each.adults_count}</td>
                      <td>{each.support_staff_count}</td>
                      <td>{each.children_count}</td>
                      <td>{each.below_2_year_count}</td>
                      <td>{Number(each.joy_ride_count) + Number(each.free_adult_joy_rides)}</td>
                      <td>{Number(each.joy_ride_children_count) + Number(each.free_child_joy_rides)}</td>
                      <td>
                        {each.guide > 0 ? (
                          <span style={{ color: each.guide_color_code ? each.guide_color_code : '#000', fontWeight: "bold" }}>
                            {each.guide_name}
                          </span>
                        ) : (
                          <span>NA</span>
                        )}
                      </td>
                    </tr>
                  ))}
                {data && data.bookings.length === 0 && (
                  <tr>
                    <td className="text-danger">No booking available!</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Card>
      </Col>

      {/* Filter Modal */}
      <Modal isOpen={filterOpen} toggle={toggleFilter}>
        <ModalHeader toggle={toggleFilter}>Filter Options</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-sm-6">
              <Label for="Guide">
                <small>Select Guide</small>
              </Label>
              <Input
                type="select"
                value={filterGuide}
                onChange={(e) => setFilterGuide(e.target.value)}
              >
                <option value={""}>All</option>
                {guides && guides.length > 0 && guides.map((guide) => (
                  <option key={guide.id} value={guide.id}>{guide.name}</option>
                ))}
              </Input>
            </div>
            <div className="col-sm-6">
              <Label for="Slot">
                <small>Select Slot</small>
              </Label>
              <Input
                type="select"
                value={filterSlot}
                onChange={(e) => setFilterSlot(e.target.value)}
              >
                <option value={""}>All</option>
                {slots && slots.length > 0 && slots.map((slot) => (
                  <option key={slot.slot_id} value={slot.slot_id}>{slot.slot_name}</option>
                ))}
              </Input>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-6">
              <Label for="Name">
                <small>Picnicker Name</small>
              </Label>
              <Input
                type="text"
                value={filterPicnickerName}
                onChange={(e) => setFilterPicnickerName(e.target.value)}
              />
            </div>
            <div className="col-sm-6">
              <Label for="email">
                <small>Picnicker Email</small>
              </Label>
              <Input
                type="email"
                value={filteremail}
                onChange={(e) => setFilteremail(e.target.value)}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-6">
              <Label for="SlotName">
                <small>Slot Name</small>
              </Label>
              <Input
                type="text"
                value={filterSlotName}
                onChange={(e) => setFilterSlotName(e.target.value)}
              />
            </div>
            <div className="col-sm-6">
              <Label for="Contact">
                <small>Contact</small>
              </Label>
              <Input
                type="text"
                value={fitlerContact}
                onChange={(e) => setFilterContact(e.target.value)}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleResetFilter}>
            Reset
          </Button>{" "}
          <Button color="primary" onClick={handleApplyFilter}>
            Apply
          </Button>
        </ModalFooter>
      </Modal>

      {/* Chat Modal */}
      {selectedBooking && <Modal isOpen={isChatModalOpen} toggle={toggleChatModal}>
        <ModalHeader toggle={toggleChatModal}>Comment</ModalHeader>
        <ModalBody>
          {
            (selectedBooking.comment && selectedBooking.comment.length>0) ? 
            (<p>{selectedBooking.comment}</p>)
            : 
            <p>No Comment</p>
          }
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleChatModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>}
    </>
  );
}
