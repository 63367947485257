import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";
import PriceDashboard from "pages/admin/PriceDashboard";
// import Booking from "pages/spectator/Booking";
import AllBookings from "pages/admin/AllBookings";
import Booking from "pages/spectator/Booking";
import Success from "pages/spectator/orderStatus/Success";
import ExcludeDates from "pages/admin/ExcludeDates";
import IncludeDates from "pages/admin/IncludeDates";
import AddGuide from "pages/admin/AddGuide";
import ManualBooking from "pages/admin/ManualBooking";
import Kitchen from "pages/spectator/Kitchen";
import GuideIndex from "pages/Guide/GuideIndex";
import Guide from "pages/spectator/Guide";
import Cancellation from "pages/admin/cancellation/Cancellation";
import CancellationDetails from "pages/admin/cancellation/CancellationDetails";
import CancelBooking from "pages/spectator/CancelBooking";
import DisableYears from "pages/admin/DisableYears";
import GuideOverview from "pages/spectator/GuideOverview";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <Index />,
    layout: "/admin",
  },
  {
    path: "/view-bookings",
    name: "View Bookings",
    icon: "ni ni-tv-2 text-primary",
    component: <AllBookings />,
    layout: "/admin",
  },
  {
    path: "/index",
    name: "Price Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <Booking />,
    layout: "/spectator",
  },
  {
    path: "/price-dashboard",
    name: "Price Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <PriceDashboard />,
    layout: "/admin",
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: <Icons />,
    layout: "/admin",
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: <Maps />,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile />,
    layout: "/admin",
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: <Tables />,
    layout: "/admin",
  },
  {
    path: "/manual-book",
    name: "ManualBooking",
    icon: "ni ni-bullet-list-67 text-red",
    component: <ManualBooking />,
    layout: "/admin",
  },
  {
    path: "/exclude-dates",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: <ExcludeDates />,
    layout: "/admin",
  },
  {
    path: "/add-guide",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: <AddGuide />,
    layout: "/admin",
  },
  {
    path: "/include-dates",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: <IncludeDates />,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: <Register />,
    layout: "/auth",
  },
  {
    path: "/success/:order_id/:cashfree_order_id/:booking_date",
    name: "Price Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <Success />,
    layout: "/spectator",
  },
  {
    path: "/kitchen",
    name: "Price Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <Kitchen />,
    layout: "/spectator",
  },
  {
    path: "/index",
    name: "Guide Index",
    icon: "ni ni-tv-2 text-primary",
    component: <GuideIndex />,
    layout: "/guide",
  },
  {
    path: "/guideOverview",
    name: "Guide Overview",
    component: <GuideOverview />,
    layout:"/spectator"
  },
  {
    path: "/guide",
    name: "Guide",
    icon: "ni ni-tv-2 text-primary",
    component: <Guide />,
    layout: "/spectator",
  },
  {
    path: "/cancellation",
    name: "Guide",
    icon: "ni ni-tv-2 text-primary",
    component: <Cancellation />,
    layout: "/admin",
  },
  {
    path: "/cancellationDetails/:cancellation_id/:booking_id/:order_id",
    name: "Guide",
    icon: "ni ni-tv-2 text-primary",
    component: <CancellationDetails />,
    layout: "/admin",
  },
  {
    path: "/cancel-booking",
    name: "Guide",
    icon: "ni ni-tv-2 text-primary",
    component: <CancelBooking />,
    layout: "/spectator",
  },
  {
    path: "/disable-years",
    name: "Disable Years",
    icon: "ni ni-tv-2 text-primary",
    component: <DisableYears />,
    layout: "/admin",
  },
  
];
export default routes;
