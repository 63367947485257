import { useAuthContext } from "./useAuthContext";

export const useLogout = () =>{
    const {dispatch}=useAuthContext()
    const handleLogout = () =>{
        localStorage.removeItem('user_token');
        localStorage.removeItem('Date');
        localStorage.removeItem('FilterModalOpen');
        dispatch({type:'LOGOUT'});
      } 
      return {handleLogout}
}