import { usePost } from "hooks/usePost";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import {
  Card,
  CardHeader,
  Table,
  Row,
  Col,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  CustomInput,
} from "reactstrap";
import "react-notifications/lib/notifications.css";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import { useFetch } from "hooks/useFetch";

export default function AllBookingTableGuideView({
  data,
  slotData,
  booking_date,
  handleChangeDate,
  guide,
  handleGuideChange,
  slot,
  handleSlotChange,
  slotName,
  handleSlotNameChange,
  picnickerName,
  handlePicknerNameChange,
  email,
  contact,
  handlePicknerEmailChange,
  handleContactChange,
  handleReset,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [isChangeSlot, setIsChangeSlot] = useState(false);
  const [guideModalOpen, setGuideModalOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isAddPeople, setIsAddpeople] = useState(false);
  const [below2years, setBelow2years] = useState(0);
  const [selectedRefundType, setSelectedRefundType] = useState("");
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const { postData, resData, postError, isPending } = usePost();
  const { postData:updateMarkAsRead, resData:updateRes } = usePost();
  const [checkedBookings,setCheckedBookings] = useState([ ]);
  const [adultCount,setAdultCount] = useState(0);
  const [childrenCount,setChildrenCount] = useState(0);
  const [below2YearsCount,setBelow2YearsCount] = useState(0);
  const [isChatModalOpen, setChatModalOpen] = useState(false);
  const {
    postData: changeSlot,
    resData: changeSlotRes,
    postError: changeSlotPostErr,
    isPending: changeSlotPending,
  } = usePost();
  const [joyRidePrice, setJoyRidePrice] = useState(null);
  const [joyRideCount, setJoyRideCount] = useState(0);
  const [joyRiderChildrenCount, setJoyRiderChildrenCount] = useState(0);
  const [AdultFreeJoyRideCount, setFreeAdultJoyRideCount] = useState(0);
  const [childFreeJoyRideCount, setChildFreeJoyRideCount] = useState(0);
  const [isJoyRide, setIsJoyRide] = useState(false);
  const [isFreeJoyRide, setIsFreeJoyRide] = useState(false);
  const [paymentMode, setPaymentMode] = useState("");
  const [joyRidePriceChildren, setJoyRidePriceChildren] = useState(null);
  const [selectedGuide, setSelectedGuide] = useState(0);
  const [filterGuide, setFilterGuide] = useState(guide);
  const [filterSlot, setFilterSlot] = useState(slot);
  const [filterSlotName,setFilterSlotName] = useState(slotName)
  const [filterPicnickerName, setFilterPicnickerName] = useState(picnickerName);
  const [filteremail, setFilteremail] = useState(email);
  const [fitlerContact, setFilterContact] = useState(contact);
  const [isOthers, setIsothers] = useState(false);
  const [numOfRefundableAdult, setnumOfRefundableAdult] = useState(0);
  const [numOfRefundableChildren, setnumOfRefundableChildren] = useState(0);
  const [totalRefundableAmount, settotalRefundableAmount] = useState(0);
  const [others, setOthers] = useState("");
  const { data: joyRideData } = useFetch(
    `${process.env.REACT_APP_NODE_SERVER}/api/joyrides/getAll`
  );
  const { data: guides } = useFetch(
    `${process.env.REACT_APP_NODE_SERVER}/api/guide/getAll`
  );
  const { data: slots } = useFetch(
    `${process.env.REACT_APP_NODE_SERVER}/api/slot/getAll`
  );

  const toggleFilter = () => {
    setFilterOpen(!filterOpen);
  };
  const toggleGuideModal = () => {
    setGuideModalOpen(!guideModalOpen);
  };
  useEffect(() => {
    if (joyRideData && joyRideData.status === 200) {
      setJoyRidePrice(Number(joyRideData.joy_rides[0].joy_ride_adult_price));
      setJoyRidePriceChildren(
        Number(joyRideData.joy_rides[0].joy_ride_children_price)
      );
    }
  }, [joyRideData]);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handlePriceRevision = () => {
    if (joyRidePrice && joyRidePriceChildren) {
      setTotalPrice(
        adults * Number(selectedBooking.single_adult_price) +
          Number(children) * Number(selectedBooking.single_child_price) +
          Number(joyRideCount * joyRidePrice) +
          Number(joyRiderChildrenCount * joyRidePriceChildren)
      );
    }
  };
  useEffect(() => {
    if (selectedBooking) {
      handlePriceRevision();
    }
  }, [adults, children, selectedBooking, joyRideCount, joyRiderChildrenCount]);

  useEffect(() => {
    if (!modalOpen) {
      setIsAddpeople(false);
      setAdults(0);
      setChildren(0);
      setJoyRideCount(0);
      setJoyRiderChildrenCount(0);
      setFreeAdultJoyRideCount(0);
      setChildFreeJoyRideCount(0);
      setnumOfRefundableAdult(0);
      setnumOfRefundableChildren(0);
      setIsChangeSlot(false);
    }
  }, [modalOpen]);

  // useEffect(() => {
  //   if (!isJoyRide) {
  //     setJoyRideCount(0);
  //     setJoyRiderChildrenCount(0);
  //   }
  // }, [isJoyRide]);
  // useEffect(() => {
  //   if (!isFreeJoyRide) {
  //     setFreeAdultJoyRideCount(0);
  //     setChildFreeJoyRideCount(0);
  //   }
  // }, [isFreeJoyRide]);

  // useEffect(() => {
  //   if (!isAddPeople) {
  //     setAdults(0);
  //     setChildren(0);
  //   }
  // }, [isAddPeople]);

  const openSelectGuideModal = (booking) => {
    setSelectedBooking(booking);
    // console.log(booking);
    toggleGuideModal();
  };
  const openBookingDetails = (booking) => {
    setSelectedBooking(booking);
    setJoyRideCount(booking.joy_ride_count);
    setJoyRiderChildrenCount(booking.joy_ride_children_count);
    setFreeAdultJoyRideCount(booking.free_adult_joy_rides);
    setChildFreeJoyRideCount(booking.free_child_joy_rides);
    setAdults(booking.adults_count);
    setChildren(booking.children_count);
    setBelow2years(booking.below_2_year_count);
    setPaymentMode(booking.payment_mode);
    setSelectedSlot(booking);
    console.log(booking.payment_note);
    if (booking.payment_mode === "Other") {
      setOthers(booking.payment_note);
      setIsothers(true);
    }

    // console.log(booking);
    toggleModal();
  };
  useEffect(() => {
    console.log(selectedSlot, "selectedSlot");
  }, [selectedSlot]);

  const handlePeopleChange = (e) => {
    switch (e.target.name) {
      case "adults":
        setAdults(e.target.value);
        break;
      case "children":
        setChildren(e.target.value);
        break;
      case "below2year":
        setBelow2years(e.target.value);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (guideModalOpen === false) {
      setSelectedGuide("0");
    }
  }, [guideModalOpen]);

  const handleGuideAssignment = () => {
    console.log(selectedGuide);
    console.log(selectedBooking, "console log");
    const payload = {
      user_id: selectedBooking.user_id,
      slot_id: selectedBooking.slot_id,
      adults_count: Number(selectedBooking.adults_count),
      children_count: Number(selectedBooking.children_count),
      below_2_year_count: Number(selectedBooking.below_2_year_count),
      joy_ride_count: Number(selectedBooking.joy_ride_count),
      joy_ride_children_count: Number(selectedBooking.joy_ride_children_count),
      adult_joy_ride_total: Number(selectedBooking.adult_joy_ride_total),
      children_joy_ride_total: Number(selectedBooking.children_joy_ride_total),
      free_adult_joy_rides: Number(selectedBooking.free_adult_joy_rides),
      free_child_joy_rides: Number(selectedBooking.free_child_joy_rides),
      order_id: selectedBooking.order_id,
      payment_id: selectedBooking.payment_id,
      single_adult_price: selectedBooking.single_adult_price,
      single_child_price: selectedBooking.single_child_price,
      total_cost: selectedBooking.total_cost,
      amount_paid: selectedBooking.amount_paid,
      amount_pending: selectedBooking.amount_pending,
      payment_mode: selectedBooking.payment_mode,
      payment_note: selectedBooking.payment_note,
      refund_adult_count: selectedBooking.refund_adult_count,
      refund_children_count: selectedBooking.refund_children_count,
      refund_total_amount: selectedBooking.refund_total_amount,
      refund_type: selectedBooking.refund_type,
      booking_day: selectedBooking.booking_day,
      name: selectedBooking.name,
      email: selectedBooking.email,
      contact: selectedBooking.contact,
      is_birthday: selectedBooking.is_birthday,
      guide: selectedGuide,
      is_special_day: selectedBooking.is_special_day,
      grade: selectedBooking.grade,
      archive: selectedBooking.archive,
    };
    postData(
      `${process.env.REACT_APP_NODE_SERVER}/api/booking/updateGuide/${selectedBooking.booking_id}`,
      payload,
      "PUT"
    );
  };

  const handleUpadateBeforePriceSettle = () => {
    console.log(selectedSlot);
    // return

    const payload = {
      user_id: selectedBooking.user_id,
      slot_id:
        selectedSlot !== null ? selectedSlot.slot_id : selectedBooking.slot_id,
      adults_count: Number(adults),
      children_count: Number(children),
      below_2_year_count: Number(below2years),
      joy_ride_count: Number(joyRideCount),
      joy_ride_children_count: Number(joyRiderChildrenCount),
      adult_joy_ride_total: Number(joyRideCount) * Number(joyRidePrice),
      children_joy_ride_total:
        Number(joyRiderChildrenCount) * Number(joyRidePriceChildren),
      free_adult_joy_rides: Number(AdultFreeJoyRideCount),
      free_child_joy_rides: Number(childFreeJoyRideCount),
      order_id: selectedBooking.order_id,
      payment_id: selectedBooking.payment_id,
      single_adult_price: selectedBooking.single_adult_price,
      single_child_price: selectedBooking.single_child_price,
      total_cost: selectedBooking.total_cost,
      amount_paid: selectedBooking.amount_paid,
      amount_pending: selectedBooking.amount_pending,
      payment_mode: paymentMode,
      payment_note: paymentMode === "Other" ? others : null,
      booking_day: selectedBooking.booking_day,
      name: selectedBooking.name,
      email: selectedBooking.email,
      contact: selectedBooking.contact,
      guide: selectedBooking.guide,
      is_birthday:0,
      is_special_day: selectedBooking.is_special_day,
      grade: selectedBooking.grade,
      archive: selectedBooking.archive,
    };
    console.log(payload);
    changeSlot(
      `${process.env.REACT_APP_NODE_SERVER}/api/booking/update/${selectedBooking.booking_id}`,
      payload,
      "PUT"
    );
  };
  useEffect(() => {
    if (changeSlotRes && changeSlotRes.status === 200) {
      NotificationManager.success("Updated successfully!", "", 3000);
      setIsChangeSlot(false);
    }
    if (changeSlotPostErr) {
      NotificationManager.error(changeSlotPostErr, "", 3000);
    }
  }, [changeSlotRes, changeSlotPostErr]);
  
  const handleBookingDetailsUpdate = () => {
    console.log(selectedBooking, "console log");
    const payload = {
      user_id: selectedBooking.user_id,
      slot_id: selectedBooking.slot_id,
      adults_count: Number(adults),
      children_count: Number(children),
      below_2_year_count: Number(below2years),
      joy_ride_count: Number(joyRideCount),
      joy_ride_children_count: Number(joyRiderChildrenCount),
      adult_joy_ride_total: Number(joyRideCount) * Number(joyRidePrice),
      children_joy_ride_total:
        Number(joyRiderChildrenCount) * Number(joyRidePriceChildren),
      free_adult_joy_rides: Number(AdultFreeJoyRideCount),
      free_child_joy_rides: Number(childFreeJoyRideCount),
      order_id: selectedBooking.order_id,
      payment_id: selectedBooking.payment_id,
      single_adult_price: selectedBooking.single_adult_price,
      single_child_price: selectedBooking.single_child_price,
      total_cost: totalPrice,
      amount_paid: totalPrice,
      amount_pending: 0,
      payment_mode: paymentMode,
      payment_note: paymentMode === "Other" ? others : null,
      refund_adult_count: numOfRefundableAdult,
      refund_children_count: numOfRefundableChildren,
      refund_total_amount: totalRefundableAmount,
      refund_type: selectedRefundType,
      booking_day: selectedBooking.booking_day,
      name: selectedBooking.name,
      email: selectedBooking.email,
      contact: selectedBooking.contact,
      guide: selectedBooking.guide,
      is_birthday: 0,
      is_special_day: selectedBooking.is_special_day,
      grade: selectedBooking.grade,
      archive: selectedBooking.archive,
    };
    console.log(payload, "payload");
    postData(
      `${process.env.REACT_APP_NODE_SERVER}/api/booking/update/${selectedBooking.booking_id}`,
      payload,
      "PUT"
    );
  };
  useEffect(() => {
    if (resData && resData.status === 200) {
      NotificationManager.success("Updated successfully!", "", 3000);
      setModalOpen(false);
      setInterval(() => {
        window.location.reload();
      }, 1500);
    }
    if (postError) {
      NotificationManager.error(postError, "", 3000);
    }
  }, [resData, postError]);
  const handleApplyFilter = () => {
    handleGuideChange(filterGuide);
    handleSlotChange(filterSlot);
    handlePicknerNameChange(filterPicnickerName);
    handlePicknerEmailChange(filteremail);
    handleContactChange(fitlerContact);
    handleSlotNameChange(filterSlotName);
    setFilterOpen(false);
    const filters = {filterGuide,filterSlot,filterPicnickerName,filteremail,fitlerContact,filterSlotName}
    localStorage.setItem('filters', JSON.stringify(filters));
  };
  const handleResetFilter = () => {
    localStorage.removeItem('filters');
    handleReset();
    setFilterGuide("");
    setFilterSlot("");
    setFilterPicnickerName("");
    setFilteremail("");
    setFilterContact("");
    setFilterSlotName("");
    setFilterOpen(false);
  };

  const handlepaymentMode = (e) => {
    console.log(e.target.value);
    if (e.target.value === "Other") {
      setIsothers(true);
      setPaymentMode(e.target.value);
    } else {
      setIsothers(false);
      setPaymentMode(e.target.value);
    }
  };
  useEffect(() => {
    if (selectedBooking) {
      handleNonRefundablePrice();
    }
  }, [numOfRefundableAdult, numOfRefundableChildren]);

  useEffect(() => {
    setnumOfRefundableAdult(0);
    setnumOfRefundableChildren(0);
    settotalRefundableAmount(0);
  }, [selectedRefundType]);
  const handleRadioChange = (e) => {
    console.log(e.target.value);
    setSelectedRefundType(e.target.value);
  };
  const handleNonRefundablePrice = () => {
    if (selectedRefundType !== "") {
      if (selectedRefundType === "partialRefund") {
        console.log(
          (numOfRefundableAdult * Number(selectedBooking.single_adult_price)) /
            2 +
            (numOfRefundableChildren *
              Number(selectedBooking.single_child_price)) /
              2
        );
        settotalRefundableAmount(
          (numOfRefundableAdult * Number(selectedBooking.single_adult_price)) /
            2 +
            (numOfRefundableChildren *
              Number(selectedBooking.single_child_price)) /
              2
        );
      }
     
      if (selectedRefundType === "noRefund") {
        settotalRefundableAmount(0);
      }
    }
  };
  const handleDownloadExcelSheet = () => {
    // Define column names
    const columnNames = [
      "Booking ID",
      "Date of Visit",
      "Slot Time",
      "Name",
      "Mobile",
      "Quantity",
      "Big People",
      "Big People Amount",
      "Lil Ones",
      "Lil Ones Amount",
      "Advance",
      "Email",
    ];

    // Prepare data array with column names as the first row
    const mappedData = [
      columnNames,
      ...data.bookings.map((booking) => [
        booking.booking_id,
        new Date(booking.booking_date).toLocaleDateString("en-GB"),
        booking.slot_name.slice(0, 4) +
          " - " +
          booking.from_time +
          " - " +
          booking.to_time,
        booking.name,
        booking.contact,
        Number(booking.adults_count)+Number(booking.children_count),
        booking.adults_count,
        booking.single_adult_price,
        booking.children_count,
        booking.single_child_price,
        booking.amount_paid,
        booking.email,
      ]),
    ];

    // Generate Excel sheet
    const ws = XLSX.utils.aoa_to_sheet(mappedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Bookings");
    XLSX.writeFile(
      wb,
      `bookings(${new Date(booking_date).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })}).xlsx`
    );
  };
  const handleSlotChangeData = (e) => {
    const selectedValue = JSON.parse(e.target.value);
    setSelectedSlot(selectedValue);
  };

  const getInitials = (slotName) => {
    const words = slotName.split(' ');
    const initials = words.map(word => word[0]).join('');
     return initials + 'P';
  };

  const handleCheckbox = (selectedBooking) => {
    setCheckedBookings((prev) => {
      // Check if any booking in the array has the same booking_id as selectedBooking
      const isAlreadyChecked = prev.some(
        (item) => item.booking_id === selectedBooking.booking_id
      );
  
      if (isAlreadyChecked) {
        // If it's already checked, remove it
        return prev.filter((item) => item.booking_id !== selectedBooking.booking_id);
      } else {
        // If it's not checked, add it
        return [...prev, selectedBooking];
      }
    });
  };
  useEffect(()=>{
    //console.log(checkedBookings);
    const adultCountNum = checkedBookings.reduce((acc,each)=>{
      return acc = each.adults_count+acc
    },0)
    const childrenCountNum = checkedBookings.reduce((acc,each)=>{
      return acc = each.children_count+acc
    },0)
    const below2YearsCountNum = checkedBookings.reduce((acc,each)=>{
      return acc = each.below_2_year_count+acc
    },0)
    //console.log(adultCountNum,"adult count----",childrenCountNum,"children----",below2YearsCountNum,"Below 2");
    setAdultCount(adultCountNum);
    setChildrenCount(childrenCountNum);
    setBelow2YearsCount(below2YearsCountNum);
  },[checkedBookings])
  const openChatModal = (booking) =>{
    setSelectedBooking(booking);
    toggleChatModal();
  }

  // Toggle chat modal visibility
  const toggleChatModal = () => {
    setChatModalOpen(!isChatModalOpen);
    if(selectedBooking && isChatModalOpen){
      updateMarkAsRead(`${process.env.REACT_APP_NODE_SERVER}/api/booking/update-mark-as-read/${selectedBooking.booking_id}`,{},"POST");
    }
  };

  useEffect(()=>{
    if(updateRes && updateRes.status === 200){
      console.log('Updated as read')
    }
},updateRes)

  return (
    <>
      <NotificationContainer />
      <Col className="mb-5 mb-xl-0 mt-3" xl="12">
      {data.slotWiseCount.map((slot)=>(<span className="mr-2 ml-2" key={slot.Slotname}><span style={{fontWeight:"bold"}}>{slot.Slotname}</span> 
      (Big: {slot.adult}, Lil: {slot.Children})</span>))}
        <Card className="shadow mt-3">
          <CardHeader className="border-0 sticky-top">
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">All Bookings</h3>
                {adultCount > 0 &&
                  <> 
                    <span style={{ fontWeight: "bold" }}>
                      Count
                    </span>{" "}
                    <span className="mr-2">
                      (
                        Big: {adultCount},
                        Lil: {childrenCount},
                        2{">"}years: {below2YearsCount}
                      )
                    </span>
                    </>}
              </div>
              <div className="text-right d-flex align-items-center position-relative">
                <span className="mr-4">
                  <i
                    onClick={toggleFilter}
                    className="ri-filter-3-line"
                    title="Filters"
                    style={{ fontSize: "22px", cursor: "pointer" }}
                  ></i>
                  {filterGuide ||
                  filterSlot ||
                  filterPicnickerName ||
                  filteremail ||
                  fitlerContact || filterSlotName ? (
                    <span
                      className="badge badge-pill badge-success ml-1 position-absolute"
                      style={{ top: "-1px", right: "165px" }}
                    >
                      {(filterGuide ? 1 : 0) +
                        (filterSlot ? 1 : 0) +
                        (filterPicnickerName ? 1 : 0) +
                        (filteremail ? 1 : 0) +
                        (fitlerContact ? 1 : 0) +
                        (filterSlotName ? 1 : 0)
                        }
                    </span>
                  ) : null}
                </span>
                {/* <span className="mr-4">
                  <i
                    className="ri-download-2-line"
                    title="Download"
                    onClick={handleDownloadExcelSheet}
                    style={{ fontSize: "22px", cursor: "pointer" }}
                  ></i>
                </span> */}
                <Input
                  type="date"
                  value={booking_date}
                  onChange={(e) => handleChangeDate(e.target.value)}
                />
              </div>
            </Row>
          </CardHeader>
          <div className="table-responsive">
            <Table className="align-items-center table-flush responsive">
              <thead className="thead-light">
                {data && data.bookings.length > 0 && (
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Name</th>
                    <th scope="col">Comments</th>
                    <th scope="col">Contact</th>
                    <th scope="col">Slot</th>
                    <th scope="col">A</th>
                    <th scope="col">K</th>
                    <th scope="col">2 &gt; yrs</th>
                    <th scope="col">AJR</th>
                    <th scope="col">KJR</th>
                    <th scope="col">Guide</th>
                    {/* <th scope="col">Add Guide</th> */}
                    {/* <th scope="col">Action</th> */}
                    <th scope="col"></th>
                  </tr>
                )}
                {data && data.bookings.length === 0 && (
                  <tr>
                    <th></th>
                  </tr>
                )}
              </thead>
              <tbody>
                {data &&
                  data.bookings.map((each) => (
                    <tr key={each.booking_id}>
                      <th>
                        <Form>
                          <FormGroup
                            check
                            inline
                          >
                            <Input type="checkbox" onChange={()=>{handleCheckbox(each)}}/>
                            <Label check>
                            </Label>
                          </FormGroup>
                        </Form>
                      </th>
                      <th scope="row">
                        <span className="mr-2">{each.amount_pending === 0 ?
                        <i className="ri-check-fill text-success" style={{fontSize:'16px'}}></i>:
                        <i className="ri-close-line text-danger" style={{fontSize:'16px'}}></i>}
                        </span>{" "}
                        {each.name}
                        </th>
                        <td>
                        <span
                          type="button"
                          className="position-relative"
                          onClick={()=>openChatModal(each)} // Open chat modal on click
                        >
                          <i className="ri-chat-1-line" style={{ fontSize: '23px' }}></i>
                          <span className="position-absolute top-0 start-100 left-3 translate-middle badge rounded-pill bg-danger text-white px-2">
                            {(each.comment && each.comment.length > 0) ? "1": ""}
                          </span>
                        </span>
                      </td>
                      <td><a href={`tel:${each.contact}`}>{each.contact}</a></td>
                      <td><span className="text-uppercase">{getInitials(each.slot_name)}</span></td>
                      <td>{each.adults_count}</td>
                      <td>{each.children_count}</td>
                      <td>{each.below_2_year_count}</td>
                      <td>{Number(each.joy_ride_count)+Number(each.free_adult_joy_rides)}</td>
                      <td>{Number(each.joy_ride_children_count)+Number(each.free_child_joy_rides)}</td>
                      <td>
                        {each.guide > 0 ? (
                          <span style={{color:each.guide_color_code ? each.guide_color_code : '#000',fontWeight:"bold"}}>
                            {each.guide_name}
                          </span>
                        ) : (
                          <span>NA</span>
                        )}
                      </td>
                      <td><Button size="sm" color="primary" onClick={() => openSelectGuideModal(each)}>Assign</Button></td>
                      {/* <td>
                      <Button size="sm" color="primary" onClick={()=>openSelectGuideModal(each)}>Add Guide</Button>
                    </td> */}
                      {/* <td>
                      <Button size="sm" color="primary" onClick={() => openBookingDetails(each)}>Details</Button>
                    </td> */}
                    </tr>
                  ))}
                {data && data.bookings.length === 0 && (
                  <tr>
                    <td className="text-danger">No booking available!</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Card>
      </Col>

      {/* Modal */}
      <Modal
        className="booking-details-modal"
        isOpen={modalOpen}
        toggle={toggleModal}
      >
        <ModalHeader toggle={toggleModal}>Booking Details</ModalHeader>
        <ModalBody>
          {selectedBooking && (
            <div>
              <p>
                Booking Date:{" "}
                {new Date(selectedBooking.booking_date).toLocaleDateString(
                  "en-GB"
                )}
              </p>
              <p>Name: {selectedBooking.name}</p>
              <p>Contact Number: {selectedBooking.contact}</p>
              <p>Email: {selectedBooking.email}</p>
              <p>
                <>
                  {" "}
                  {!isChangeSlot && selectedSlot &&(
                    <>
                      Slot: {selectedSlot.slot_name}{" "}
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() => setIsChangeSlot(!isChangeSlot)}
                      >
                        Change Slot
                      </Button>
                    </>
                  )}
                  {isChangeSlot && selectedSlot && (
                    <>
                      {" "}
                      Select Slot :{" "}
                      <Input
                        type="select"
                        value={JSON.stringify(selectedSlot)}
                        onChange={handleSlotChangeData}
                      >
                        <option value="">Select Slot</option>
                        {slotData &&
                          slotData.slots.map((slot) => (
                            <option
                              value={JSON.stringify(slot)}
                              key={slot.slot_id}
                            >
                              {slot.slot_name} (
                              {new Date(
                                `2000-01-01T${slot.from_time}`
                              ).toLocaleTimeString("en-US", {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              })}{" "}
                              -{" "}
                              {new Date(
                                `2000-01-01T${slot.to_time}`
                              ).toLocaleTimeString("en-US", {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              })}
                              )
                            </option>
                          ))}
                      </Input>
                      <div className="mt-2">
                        <Button
                          size="sm"
                          color="primary"
                          onClick={() => setIsChangeSlot(!isChangeSlot)}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="sm"
                          color="primary"
                          onClick={handleUpadateBeforePriceSettle}
                        >
                          Save
                        </Button>
                      </div>
                    </>
                  )}
                </>
              </p>
              {/* <p>Days Left: {selectedBooking.daysLeft}</p> */}
              <Button
                className="mb-3"
                color="primary"
                size="sm"
                onClick={() => setIsAddpeople(!isAddPeople)}
              >
                {isAddPeople ? "Cancel" : "Add People"}
              </Button>
              <Button
                className="mb-3"
                color="primary"
                size="sm"
                onClick={() => setIsJoyRide(!isJoyRide)}
              >
                {isJoyRide ? "Cancel" : "Add Joy Ride(s)"}
              </Button>
              <Button
                className="mb-3"
                color="primary"
                size="sm"
                onClick={() => setIsFreeJoyRide(!isFreeJoyRide)}
              >
                {isFreeJoyRide ? "Cancel" : "Add Complimentary Joy Ride(s)"}
              </Button>
              {/* Add People */}
              {isAddPeople && (
                <>
                  <div
                    className="d-flex align-items-center"
                    style={{ flexDirection: "row" }}
                  >
                    <div className="mr-5">
                      <h4>Add People</h4>
                    </div>
                    <div>
                      <i
                        onClick={() => setIsAddpeople(!isAddPeople)}
                        className="ri-close-circle-line cursor-pointer text-danger"
                        title="Cancel"
                        style={{ fontSize: "30px", cursor: "pointer" }}
                      ></i>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-4">
                      <Label for="numberOfAdults">
                        <small>Adults</small>
                      </Label>
                      <Input
                        type="number"
                        value={adults}
                        name="adults"
                        onChange={(e) => handlePeopleChange(e)}
                        min="0"
                        placeholder="Adult"
                      />
                    </div>
                    <div className="col-4">
                      <Label for="children">
                        <small>Children</small>
                      </Label>
                      <Input
                        type="number"
                        value={children}
                        name="children"
                        onChange={(e) => handlePeopleChange(e)}
                        min="0"
                        placeholder="Children"
                      />
                    </div>
                    <div className="col-4">
                      <Label for="below2year">
                        <small>Below 2 Year</small>
                      </Label>
                      <Input
                        type="number"
                        value={below2years}
                        name="below2year"
                        onChange={(e) => handlePeopleChange(e)}
                        min="0"
                        placeholder="Below 2 Year"
                      />
                    </div>
                    {/* Non Refundable */}
                  </div>
                  <div>
                    <h4>Refund</h4>
                    {/* <CustomInput
                      type="radio"
                      id="fullRefund"
                      name="fullRefund"
                      label="Full Refund"
                      value = 'fullRefund'
                      checked={selectedRefundType === 'fullRefund'}
                      inline
                      onChange={(e)=>handleRadioChange(e)}
                    /> */}
                    <CustomInput
                      type="radio"
                      id="partialRefund"
                      name="partialRefund"
                      value="partialRefund"
                      label="50% Refund"
                      checked={selectedRefundType === "partialRefund"}
                      inline
                      onChange={(e) => handleRadioChange(e)}
                    />
                    <CustomInput
                      type="radio"
                      id="noRefund"
                      value="noRefund"
                      name="noRefund"
                      label="No Refund"
                      checked={selectedRefundType === "noRefund"}
                      inline
                      onChange={(e) => handleRadioChange(e)}
                    />
                    {selectedRefundType !== "" && (
                      <div className="row mt-3">
                        <div className="col-sm-6">
                          <Label for="joyRide">
                            <small>No. of big people for refund</small>
                          </Label>
                          <Input
                            type="number"
                            value={numOfRefundableAdult}
                            name="numOfRefundableAdult"
                            onChange={(e) =>
                              setnumOfRefundableAdult(e.target.value)
                            }
                            min="0"
                          />
                        </div>
                        <div className="col-sm-6">
                          <Label for="joyRide">
                            <small>No. of lil Ones for refund</small>
                          </Label>
                          <Input
                            type="number"
                            value={numOfRefundableChildren}
                            name="numOfRefundableChildren"
                            onChange={(e) =>
                              setnumOfRefundableChildren(e.target.value)
                            }
                            min="0"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
              {/* Add Joy Ride */}
              {isJoyRide && (
                <>
                  <hr />
                  <div
                    className="d-flex align-items-center"
                    style={{ flexDirection: "row" }}
                  >
                    <div className="mr-5">
                      <h4>Add Joy Ride(s)</h4>
                    </div>
                    <div>
                      <i
                        onClick={() => setIsJoyRide(!isJoyRide)}
                        className="ri-close-circle-line cursor-pointer text-danger"
                        title="Cancel"
                        style={{ fontSize: "30px", cursor: "pointer" }}
                      ></i>
                    </div>
                  </div>
                  <div className="mb-4 row">
                    <div className="col-sm-6">
                      <Label for="joyRide">
                        <small>No. Joy Ride(s) Big People</small>
                      </Label>
                      <Input
                        type="number"
                        value={joyRideCount}
                        name="joyRide"
                        onChange={(e) => setJoyRideCount(e.target.value)}
                        min="0"
                      />
                    </div>
                    <div className="col-sm-6">
                      <Label for="joyRide">
                        <small>No. Joy Ride(s) Lil Ones</small>
                      </Label>
                      <Input
                        type="number"
                        value={joyRiderChildrenCount}
                        name="joyRide"
                        onChange={(e) =>
                          setJoyRiderChildrenCount(e.target.value)
                        }
                        min="0"
                      />
                    </div>
                  </div>
                </>
              )}
              {/* Complementry Joy Ride */}
              {isFreeJoyRide && (
                <>
                  <hr />
                  <div
                    className="d-flex align-items-center"
                    style={{ flexDirection: "row" }}
                  >
                    <div className="mr-5">
                      <h4>Add Complementry Joy Ride(s)</h4>
                    </div>
                    <div>
                      <i
                        onClick={() => setIsFreeJoyRide(!isFreeJoyRide)}
                        className="ri-close-circle-line cursor-pointer text-danger"
                        title="Cancel"
                        style={{ fontSize: "30px", cursor: "pointer" }}
                      ></i>
                    </div>
                  </div>
                  <div className="mb-4 row">
                    <div className="col-sm-6">
                      <Label for="joyRide">
                        <small>No. Joy Ride(s) Big People</small>
                      </Label>
                      <Input
                        type="number"
                        value={AdultFreeJoyRideCount}
                        name="joyRide"
                        onChange={(e) =>
                          setFreeAdultJoyRideCount(e.target.value)
                        }
                        min="0"
                      />
                    </div>
                    <div className="col-sm-6">
                      <Label for="joyRide">
                        <small>No. Joy Ride(s) Lil Ones</small>
                      </Label>
                      <Input
                        type="number"
                        value={childFreeJoyRideCount}
                        name="joyRide"
                        onChange={(e) =>
                          setChildFreeJoyRideCount(e.target.value)
                        }
                        min="0"
                      />
                    </div>
                  </div>
                </>
              )}
              <hr />
              <p>Summary:</p>
              <div className="table-responsive">
                <table className="align-items-center table table-bordered responsive">
                  <thead>
                    <tr>
                      <th />
                      <th scope="col">No. of People</th>
                      <th scope="col">Per Person Cost</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Big People</td>
                      <td>{Number(adults)}</td>
                      <td>₹{Number(selectedBooking.single_adult_price)}</td>
                      <td>
                        ₹
                        {Number(adults) *
                          Number(selectedBooking.single_adult_price)}
                      </td>
                    </tr>
                    {Number(children) > 0 && (
                      <tr>
                        <td>Lil' People</td>
                        <td>{Number(children)}</td>
                        <td>₹{Number(selectedBooking.single_child_price)}</td>
                        <td>
                          ₹
                          {Number(children) *
                            Number(selectedBooking.single_child_price)}
                        </td>
                      </tr>
                    )}
                    {Number(joyRideCount) > 0 && (
                      <tr>
                        <td>Big People Joy Ride</td>
                        <td>{Number(joyRideCount)}</td>
                        <td>₹{Number(joyRidePrice)}</td>
                        <td>₹{Number(joyRideCount) * Number(joyRidePrice)}</td>
                      </tr>
                    )}
                    {Number(joyRiderChildrenCount) > 0 && (
                      <tr>
                        <td>Lil' People Joy Ride</td>
                        <td>{Number(joyRiderChildrenCount)}</td>
                        <td>₹{Number(joyRidePriceChildren)}</td>
                        <td>
                          ₹
                          {Number(joyRiderChildrenCount) *
                            Number(joyRidePriceChildren)}
                        </td>
                      </tr>
                    )}
                    {Number(AdultFreeJoyRideCount) > 0 && (
                      <tr>
                        <td>Complementary Big People Joy Ride</td>
                        <td>{Number(AdultFreeJoyRideCount)}</td>
                        <td>₹0</td>
                        <td>₹0</td>
                      </tr>
                    )}
                    {Number(childFreeJoyRideCount) > 0 && (
                      <tr>
                        <td>Complementary Lil' People Joy Ride</td>
                        <td>{Number(childFreeJoyRideCount)}</td>
                        <td>₹0</td>
                        <td>₹0</td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan={3}>Total</td>
                      <td>₹{totalPrice}</td>
                    </tr>
                    <tr>
                      <td colSpan={3}>Already Paid</td>
                      <td className="text-success">
                        ₹{selectedBooking.amount_paid}
                      </td>
                    </tr>
                    {selectedRefundType === "partialRefund" && (
                      <tr>
                        <td colSpan={3}>Refund</td>
                        <td className="text-danger">
                          -₹{totalRefundableAmount}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan={3}>Balance Amount</td>
                      <td className="text-danger">
                        {totalPrice - Number(selectedBooking.amount_paid) < 0
                          ? `-₹${Math.abs(
                              totalPrice - Number(selectedBooking.amount_paid)
                            )}`
                          : `₹${
                              totalPrice -
                              Number(selectedBooking.amount_paid) -
                              totalRefundableAmount
                            }`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {selectedBooking.refund_type !== null && (
                <>
                  <p>Refund:</p>
                  <div className="table-responsive">
                    <table className="align-items-center table table-bordered responsive">
                      <thead>
                        <tr>
                          <th>Big People</th>
                          <th>Lil' People</th>
                          <th>Refund Type</th>
                          <th>Total Refund</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{selectedBooking.refund_adult_count}</td>
                          <td>{selectedBooking.refund_children_count}</td>
                          <td>
                            {selectedBooking.refund_type === "partialRefund"
                              ? "50% Refund"
                              : "No Refund"}
                          </td>
                          <td>₹{selectedBooking.refund_total_amount}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              )}
              <h4 className="text-danger">
                Please{" "}
                {totalPrice - Number(selectedBooking.amount_paid) > 0
                  ? "collect"
                  : "pay"}{" "}
                the pending amount of{" "}
                {totalPrice - Number(selectedBooking.amount_paid) < 0
                  ? `-₹${Math.abs(
                      totalPrice - Number(selectedBooking.amount_paid)
                    )}`
                  : `₹${
                      totalPrice -
                      Number(selectedBooking.amount_paid) -
                      totalRefundableAmount
                    }`}
              </h4>
              <div className="mb-3 mt-3 row">
                <div className="col-sm-6">
                  <Label>Select Payment Mode</Label>
                  <Input
                    value={paymentMode}
                    onChange={(e) => handlepaymentMode(e)}
                    type="select"
                  >
                    <option value="">Select</option>
                    <option value="cash">Cash</option>
                    <option vlaue="qrscan">QR Scan</option>
                    <option vlaue="other">Other</option>
                  </Input>
                </div>
                {isOthers && (
                  <div className="col-sm-6">
                    <Label>Specify Other</Label>
                    <Input
                      value={others}
                      onChange={(e) => setOthers(e.target.value)}
                      type="text"
                    />
                  </div>
                )}
              </div>
              Alreay collected?{" "}
              {!isPending && (
                <Button
                  color="primary"
                  size="sm"
                  onClick={handleBookingDetailsUpdate}
                >
                  Update
                </Button>
              )}
              {isPending && (
                <Button color="primary" size="sm" disabled>
                  Updating
                </Button>
              )}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      {/* Filter */}
      <Modal isOpen={filterOpen} toggle={toggleFilter}>
        <ModalHeader toggle={toggleFilter}>Filter Options</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-sm-6">
              <Label for="Guide">
                <small>Select Guide</small>
              </Label>
              <Input
                type="select"
                value={filterGuide}
                onChange={(e) => setFilterGuide(e.target.value)}
              >
                <option value="">Select Guide</option>
                <option value="0">Not Assigned</option>
                {guides &&
                  guides.guides.map((guide) => (
                    <option value={guide.guide_id} key={guide.guide_id}>
                      {guide.guide_name}
                    </option>
                  ))}
              </Input>
            </div>
            <div className="col-sm-6">
              <Label for="Slot">
                <small>Select Slot</small>
              </Label>
              <Input
                type="select"
                value={filterSlot}
                onChange={(e) => setFilterSlot(e.target.value)}
              >
                <option value="0">Select Slot</option>
                {slots &&
                  slots.slots.map((slot) => (
                    <option value={slot.slot_id} key={slot.slot_id}>
                      {slot.slot_name}
                    </option>
                  ))}
              </Input>
            </div>
            <div className="col-sm-12 mt-2">
              <Label>
                <small>Search by Slot Name</small>
              </Label>
              <Input
                type="text"
                value={filterSlotName}
                onChange={(e) => setFilterSlotName(e.target.value)}
              />
            </div>
            <div className="col sm-6 mt-2">
              <Label>
                <small>picnicker Name</small>
              </Label>
              <Input
                type="text"
                value={filterPicnickerName}
                onChange={(e) => setFilterPicnickerName(e.target.value)}
              />
            </div>
            <div className="col sm-6 mt-2">
              <Label>
                <small>picnicker Email</small>
              </Label>
              <Input
                type="text"
                value={filteremail}
                onChange={(e) => setFilteremail(e.target.value)}
              />
            </div>
            <div className="col-sm-12 mt-2">
              <Label>
                <small>picnicker Contact</small>
              </Label>
              <Input
                type="text"
                value={fitlerContact}
                onChange={(e) => setFilterContact(e.target.value)}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleApplyFilter}>
            Apply Filter
          </Button>{" "}
          <Button color="secondary" onClick={handleResetFilter}>
            Reset
          </Button>
        </ModalFooter>
      </Modal>
      {/* Guide Modal */}
      <Modal isOpen={guideModalOpen} toggle={toggleGuideModal}>
        <ModalHeader toggle={toggleGuideModal}>Select Guide</ModalHeader>
        <ModalBody>
          <Label for="joyRide">
            <small>Select Guide</small>
          </Label>
          <Input
            type="select"
            value={selectedGuide}
            onChange={(e) => setSelectedGuide(e.target.value)}
          >
            <option value="0">Select Guide</option>
            {guides &&
              guides.guides.map((guide) => (
                <option value={guide.guide_id} key={guide.guide_id}>
                  {guide.guide_name}
                </option>
              ))}
          </Input>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleGuideAssignment}>
            Assign Guide
          </Button>{" "}
          <Button color="secondary" onClick={toggleGuideModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* Chat Modal */}
      {selectedBooking && <Modal isOpen={isChatModalOpen} toggle={toggleChatModal}>
        <ModalHeader toggle={toggleChatModal}>Comment</ModalHeader>
        <ModalBody>
          {
            (selectedBooking.comment && selectedBooking.comment.length>0) ? 
            (<p>{selectedBooking.comment}</p>)
            : 
            <p>No Comment</p>
          }
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleChatModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>}
    </>
  );
}
