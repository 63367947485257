import { useFetch } from 'hooks/useFetch';
import { usePost } from 'hooks/usePost';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardHeader, CardBody, Row, Col, Button } from "reactstrap";
import toast, { Toaster } from 'react-hot-toast';

export default function CancellationDetails() {
  const { cancellation_id, booking_id, order_id } = useParams();
  const { data: cancellationReqData, fetchIsPending } = useFetch(`${process.env.REACT_APP_NODE_SERVER}/api/cancellation/getById/${cancellation_id}/${booking_id}/${order_id}`);
  const {postData,resData,postError,isPending} = usePost();
  const {postData:rejectReq,resData:rejectRes,postError:rejectErr,isPending:rejectIsPending} = usePost();
  const {postData:postDataWh,resData:resDataWh,postError:postErrorWh} = usePost();
  const {postData:archive,resData:archiveRes,postError:archiveErr,isPending:archiveIsPending} = usePost();
  const [statusVal,setStatusVal] = useState("");
  const [currPayload,setCurrPayload] = useState(null);
  const navigate = useNavigate();

  const handleReject = () =>{
    const payload = { 
      is_approved : 0, 
      is_resolved : 1, 
      archive : 0,
      cancellation_id,booking_id, order_id

    }
    setCurrPayload({ 
      is_approved : 0, 
      is_resolved : 1, 
      archive : 0,
      email:cancellationReqData.data.email,
      name:cancellationReqData.data.name,
      cancellation_id,booking_id, order_id

    })
    rejectReq(`${process.env.REACT_APP_NODE_SERVER}/api/cancellation/updateRequest/${cancellation_id}`,payload,"PUT");
  }

  const handleArchive = () => {
    archive(`${process.env.REACT_APP_NODE_SERVER}/api/cancellation/archive/${cancellation_id}`,{},"PUT")
  }

  useEffect(()=>{
    if(archiveRes && archiveRes.status === 200){
      toast.success(`Cancellation Request Revoked Successfully!`);
      setTimeout(() => {
        window.location.href = "/app/admin/cancellation"
      }, 1000);
    }
    if(archiveErr){
      toast.error(`${archiveErr}`);
    }
  },[archiveRes,archiveErr])

  const handleApprove = () => {
    const payload = { 
      is_approved : 1, 
      is_resolved : 1, 
      archive : 0,
      cancellation_id,booking_id, order_id
    }
    setCurrPayload(
      { 
        is_approved : 1, 
        is_resolved : 1, 
        archive : 0,
        email:cancellationReqData.data.email,
        name:cancellationReqData.data.name,
        cancellation_id,booking_id, order_id
      }
    )
    postData(`${process.env.REACT_APP_NODE_SERVER}/api/cancellation/updateRequest/${cancellation_id}`,payload,"PUT");
  }

  const handlwWebhook = (status,payload) =>{
    setStatusVal(status);
    postDataWh(`${process.env.REACT_APP_NODE_SERVER}/api/cancellation/cancelationWebhook`,payload,"POST");
  }
  
  useEffect(()=>{
    if(resDataWh && resDataWh.status === 200){
      toast.success(`${statusVal} Successfully!`);
      setTimeout(()=>{
        window.location.reload();
      },[1000])
    }
    if(postErrorWh){
      toast.error("Error:"+postErrorWh);
    }
  },[resDataWh,postErrorWh])

  useEffect(()=>{
    if(resData && resData.status === 200){
      handlwWebhook("Approved",currPayload);
      // toast.success("Approved Successfully!");
      // setTimeout(()=>{
      //   window.location.reload();
      // },[1000])
    }
    if(postError){
      toast.error("Error:"+postError);
    }
  },[resData,postError])

  useEffect(()=>{
    if(rejectRes && rejectRes.status === 200){
      handlwWebhook("Rejected",currPayload);
      // toast.success("Rejected Successfully!");
      // setTimeout(()=>{
      //   window.location.reload();
      // },[1000]);
    }
    if(rejectErr){
      toast.error("Error:"+rejectErr);
    }
  },[rejectRes,rejectErr])

  const copyToClipboard = (CopiedText) => {
    navigator.clipboard.writeText(CopiedText)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch((err) => {
        toast.error("Failed to copy: ", err);
      });
  };
  return (
    <div className='container pt-8'>
      <Toaster/>
      {fetchIsPending && <p>Loading....</p>}
      {cancellationReqData && <div>
        <div>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="bg-secondary shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                  </Col>
                </Row>
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Patron Details</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-4 md-4">
                  <div>
                    <p>
                      <span className="font-weight-bold">Name:</span> {cancellationReqData.data.name}
                    </p>
                    <p>
                      <span className="font-weight-bold">Contact:</span> <a target="_blank" rel="noreferrer" href={`mailto:${cancellationReqData.data.contact}`}>{cancellationReqData.data.contact}</a>
                    </p>
                    <p>
                      <span className="font-weight-bold">Email:</span> <a target="_blank" rel="noreferrer" href={`mailto:${cancellationReqData.data.email}`}>{cancellationReqData.data.email}</a>
                    </p>
                  </div>
                </CardBody>
              </Card>
              <Card className="bg-secondary shadow mt-3">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                  </Col>
                </Row>
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Order Details</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-4 md-4">
                  <div>
                    <p><span className="font-weight-bold">Booking Date:</span> {new Date(cancellationReqData.data.booking_date).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}</p>
                    <p>
                      <span className="font-weight-bold">Slot:</span> {cancellationReqData.data.slot_name}
                    </p>
                    <p>
                      <span className="font-weight-bold">Advance Paid:</span> ₹{cancellationReqData.data.advance_paid}
                    </p>
                    <p>
                      <span className="font-weight-bold">Amount Pending:</span> ₹{cancellationReqData.data.amount_pending}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="12" className='d-flex'>
                      <div>
                        <h3 className="mb-0">Cancellation Details</h3>
                      </div>
                      <div className='ml-auto'>
                        {cancellationReqData.data.is_resolved === 1 &&
                        <>
                          <p className='badge badge-success mr-2'>Resolved</p>
                          <p className={`badge badge-${cancellationReqData.data.is_approved === 1 ? "success" : "danger"}`}>{cancellationReqData.data.is_approved === 1 ? "Approved": "Not Approved"}</p>
                        </> }
                        {cancellationReqData.data.is_resolved === 0 && <p className='badge badge-danger'>Unresolved</p>}
                      </div>  
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="card-profile-stats d-flex  md-5">
                    <div className='justify-content-center'>
                      <span className="heading">{cancellationReqData.data.adults_count}</span>
                      <span className="description">Big People</span>
                    </div>
                    <div>
                      <span className="heading">{cancellationReqData.data.children_count}</span>
                      <span className="description">Lil People</span>
                    </div>
                    <div>
                      <span className="heading">{cancellationReqData.data.below_2_year_count}</span>
                      <span className="description">Below 2 Years</span>
                    </div>
                    <div>
                      <span className="heading">{cancellationReqData.data.joy_ride_count + cancellationReqData.data.free_adult_joy_rides}</span>
                      <span className="description">Adult Joyride</span>
                    </div>
                    <div>
                      <span className="heading">{cancellationReqData.data.joy_ride_children_count + cancellationReqData.data.free_child_joy_rides}</span>
                      <span className="description">Children Joyride</span>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <h6 className="heading-small text-muted mb-4">
                      <span className="font-weight-bold">Reason for Cancellation:</span>
                    </h6>
                    {cancellationReqData.data.reason_for_cancellation}
                  </div>
                  <hr />
                  <div>
                    <h6 className="heading-small text-muted mb-4">
                      <span className="font-weight-bold">Cashfree Order Details:</span>
                    </h6>
                    <p><span className="font-weight-bold">Order Id:</span> {cancellationReqData.data.order_id} <i title="Copy" style={{cursor:"pointer"}} onClick={()=>copyToClipboard(cancellationReqData.data.order_id)} className="ri-clipboard-line"></i></p>
                    <p><span className="font-weight-bold">Payment Id:</span> {cancellationReqData.data.payment_id} <i title="Copy" style={{cursor:"pointer"}} onClick={()=>copyToClipboard(cancellationReqData.data.payment_id)} className="ri-clipboard-line"></i></p>
                    {cancellationReqData.data.is_resolved === 0 && <p>
                      <Button size="sm" color="success" disabled={isPending} onClick={handleApprove}>{isPending? "Approving...":"Approve"}</Button>
                      <Button size="sm" color="danger" disabled={rejectIsPending} onClick={handleReject}>{rejectIsPending? "Rejecting...":"Reject"}</Button>
                      <Button size="sm" color="warning" disabled={archiveIsPending} onClick={handleArchive}>{archiveIsPending? "Revoking...":"Revoke"}</Button>
                    </p>}
                    {cancellationReqData.data.is_resolved === 1 && <p className='badge badge-success'>Resolved</p>}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>}
    </div>
  );
}
