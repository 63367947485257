import React, { useState, useEffect } from "react";
import {
  Container,
  Form,
  FormGroup,
  Table,
  Input,
  Button,
  Col,
  Row,
  Card,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from "reactstrap";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { usePost } from "hooks/usePost";
import { useFetch } from "hooks/useFetch";
import InputColor from 'react-input-color';

export default function AddGuide() {
  const [selectedGuides, setSelectedGuides] = useState([{ guide_name: "", color: "" }]);
  const { postData, resData, postError, isPending } = usePost();
  const { postData: archiveGuide, resData: archiveRes, postError: archiveErr, isPending: archivePending } = usePost();
  const { postData: updateGuide, resData: updateRes, postError: updateErr, isPending: updatePending } = usePost();
  const { data, fetchIsPending } = useFetch(`${process.env.REACT_APP_NODE_SERVER}/api/guide/getAll`);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedGuide, setSelectedGuide] = useState(null);
  const [guideName, setGuideName] = useState("");
  const [color, setColor] = useState({});

  const handleGuideChange = (event, index) => {
    const newGuides = [...selectedGuides];
    newGuides[index].guide_name = event.target.value;
    setSelectedGuides(newGuides);
  };
  
  const handleColorChange = (color, index) => {
    const newGuides = [...selectedGuides];
    newGuides[index].color = color.hex;
    setSelectedGuides(newGuides);
  };
  
  const handleAddGuideInput = () => {
    setSelectedGuides([...selectedGuides, { guide_name: "", color: "#5e72e4" }]);
  };
  const handleRemoveGuideInput = (index) => {
    const newGuides = selectedGuides.filter((_, i) => i !== index);
    setSelectedGuides(newGuides);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      guides: selectedGuides.filter((guide) => guide.name !== "")
    };
    console.log(payload)
    postData(`${process.env.REACT_APP_NODE_SERVER}/api/guide/createMultiple`, payload, "POST");
  };

  useEffect(() => {
    if (resData && resData.status === 200) {
      NotificationManager.success('Guides added successfully!', '', 3000);
      window.location.reload();
    }
    if (postError) {
      NotificationManager.error(postError, '', 3000);
    }
  }, [resData, postError]);

  const handleCancelDelete = () => {
    setDeleteModalOpen(false);
  };

  const handleConfirmDelete = () => {
    archiveGuide(`${process.env.REACT_APP_NODE_SERVER}/api/guide/archive/${selectedGuide.guide_id}`, {}, "PUT");
  };

  useEffect(() => {
    if (archiveRes && archiveRes.status === 200) {
      setDeleteModalOpen(false);
      NotificationManager.success('Guide deleted successfully!', '', 3000);
      setInterval(() => {
        window.location.reload()
      }, 1000);
    }
    if (archiveErr) {
      NotificationManager.error(archiveErr, '', 3000);
    }
  }, [archiveRes, archiveErr]);

  const handleDeleteModal = (guide) => {
    setDeleteModalOpen(true);
    setSelectedGuide(guide);
  };

  const handleEditModal = (guide) => {
    setEditModalOpen(true);
    setSelectedGuide(guide);
    setGuideName(guide.guide_name);
  };

  const handleCancelEdit = () => {
    setEditModalOpen(false);
    setSelectedGuide(null);
    setGuideName("");
  };

  const handleConfirmEdit = () => {
    const payload = { guide_name: guideName,color:color.hex };
    updateGuide(`${process.env.REACT_APP_NODE_SERVER}/api/guide/update/${selectedGuide.guide_id}`, payload, "PUT");
  };

  useEffect(() => {
    if (updateRes && updateRes.status === 200) {
      setEditModalOpen(false);
      NotificationManager.success('Guide updated successfully!', '', 3000);
      setInterval(() => {
        window.location.reload()
      }, 1000);
    }
    if (updateErr) {
      NotificationManager.error(updateErr, '', 3000);
    }
  }, [updateRes, updateErr]);

  return (
    <Container className="pt-8">
      <NotificationContainer/>
      <div className="row">
        <div className="col-sm-6">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0">All Guides</h3>
                </div>
              </Row>
            </CardHeader>
            {data && (
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Guide Name</th>
                    <th>Color Code</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {fetchIsPending && (
                    <tr>
                      <td><Spinner color="primary" style={{ height: '3rem', width: '3rem' }} /></td>
                    </tr>
                  )}
                  {data.guides.length === 0 && (
                    <tr>
                      <td className="text-danger">No guides to show</td>
                    </tr>
                  )}
                  {data.guides.map((guide) => (
                    <tr key={guide.guide_id}>
                      <td>{guide.guide_name}</td>
                      <td><div
                      style={{
                        width: 20,
                        height: 20,
                        backgroundColor: guide.color,
                      }}
                      />
                      </td>
                      <td>
                        <i
                          className="ri-delete-bin-5-line text-danger"
                          title="Delete"
                          style={{ fontSize: '18px', cursor: 'pointer' }}
                          onClick={() => handleDeleteModal(guide)}
                        ></i>
                      </td>
                      <td>
                        <i
                          className="ri-pencil-line text-success"
                          title="Edit"
                          style={{ fontSize: '18px', cursor: 'pointer' }}
                          onClick={() => handleEditModal(guide)}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Card>
        </div>
        <div className="col-sm-6" style={{ width: "40%" }}>
          <Form onSubmit={handleSubmit}>
          {selectedGuides.map((guide, index) => (
                  <FormGroup key={index}>
                    <Row className="d-flex align-items-center">
                      <Col className="d-flex justify-content-center">
                        <Input
                          type="text"
                          placeholder="Guide Name"
                          value={guide.name}
                          onChange={(event) => handleGuideChange(event, index)}
                          className="mr-3"
                        />
                        <InputColor
                          initialValue={guide.color || "#5e72e4"}
                          onChange={(color) => handleColorChange(color, index)}
                          placement="right"
                        />
                      </Col>
                      <Col>
                        <Button
                          className="justify-center"
                          color="danger"
                          onClick={() => handleRemoveGuideInput(index)}
                        >
                          <i className="ri-delete-bin-5-line" title="Delete"></i>
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                ))}

            <Button
              className="mt-2"
              color="primary"
              onClick={handleAddGuideInput}
            >
              Add Guide
            </Button>
            <Button className="mt-2" color="success" disabled={isPending} type="submit">
              {!isPending ? "Submit" : "Submitting..."}
            </Button>
          </Form>
        </div>
      </div>
      <Modal isOpen={deleteModalOpen} toggle={handleCancelDelete}>
        <ModalHeader toggle={handleCancelDelete}>Confirm Delete</ModalHeader>
        <ModalBody>
          Are you sure you want to delete the guide?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleConfirmDelete} disabled={archivePending}>
            {archivePending ? "Excluding..." : "Yes"}
          </Button>
          <Button color="secondary" onClick={handleCancelDelete}>No</Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={editModalOpen} toggle={handleCancelEdit}>
        <ModalHeader toggle={handleCancelEdit}>Edit Guide</ModalHeader>
        <ModalBody>
          <Form>
          <label><small>Edit Name:</small></label>
            <FormGroup>
              <Input
                type="text"
                placeholder="Guide Name"
                value={guideName}
                onChange={(e) => setGuideName(e.target.value)}
              />
            </FormGroup>
           
            <div><label><small>Edit Color Code:</small></label></div>
            {selectedGuide && <InputColor
              initialValue={selectedGuide.color ? selectedGuide.color : `#5e72e4`}
              onChange={setColor}
              placement="right"
            />}
            <div
              style={{
                width: 50,
                height: 50,
                marginTop: 20,
                backgroundColor: color.rgba,
              }}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleConfirmEdit} disabled={updatePending}>
            {updatePending ? "Updating..." : "Update"}
          </Button>
          <Button color="secondary" onClick={handleCancelEdit}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}
