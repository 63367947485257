import { useFetch } from 'hooks/useFetch';
import { usePost } from 'hooks/usePost';
import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Form, Input, InputGroup, Col } from 'reactstrap';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
export default function JoyRide() {
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    adultPrice: '',
    childrenPrice: ''
  });
  const {data} = useFetch(`${process.env.REACT_APP_NODE_SERVER}/api/joyrides/getAll`);
  const {postData,resData,postError,isPending} = usePost()

  useEffect(()=>{
    if(data && data.status === 200){
        setFormData({adultPrice:data.joy_rides[0].joy_ride_adult_price,childrenPrice:data.joy_rides[0].joy_ride_children_price})
    }
  },[data])

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    const payload = {
        "joy_ride_adult_price": formData.adultPrice,
        "joy_ride_children_price": formData.childrenPrice,
        "archive": 0
    }
    postData(`${process.env.REACT_APP_NODE_SERVER}/api/joyrides/update/${data.joy_rides[0].joy_ride_id}`,payload,"PUT")
  };

useEffect(()=>{
if(resData && resData.status ===200){
    NotificationManager.success('Joy Ride Price Updated Successfully!', '', 3000);
    toggleModal();
}
if(postError){
    NotificationManager.error(postError, '', 3000);
    return
}
},[resData,postError])
  return (
    <>
      <Button className="mb-5" color="primary" onClick={toggleModal}>
        Joy Ride Prices
      </Button>
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Joy Ride Details</ModalHeader>
        <ModalBody>
          {<Form>
            <FormGroup row>
              <Col>
              <label><small>Big People Price</small></label>
                <InputGroup className="input-group-alternative">
                  <Input
                    type="text"
                    name="adultPrice"
                    value={formData.adultPrice}
                    onChange={handleInputChange}
                    placeholder='Big People Price'
                  />
                </InputGroup>
              </Col>
              <Col>
              <label><small>Lil Ones Price</small></label>
                <InputGroup className="input-group-alternative">
                  <Input
                    type="text"
                    name="childrenPrice"
                    value={formData.childrenPrice}
                    onChange={handleInputChange}
                    placeholder='Lil Ones Price'
                  />
                </InputGroup>
              </Col>
            </FormGroup>
          </Form>}
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={handleSubmit} disabled={isPending}>{isPending ? "Submitting..." : "Submit"}</Button>
            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
      <NotificationContainer/>
    </>
  );
}
