import { usePost } from 'hooks/usePost';
import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';

export default function CancelBooking() {
  const [orderId, setOrderId] = useState('');
  const [reason, setReason] = useState('');
  const [orderIdError, setOrderIdError] = useState('');
  const [reasonError, setReasonError] = useState('');
  const { postData, resData, postError, isPending } = usePost();
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const validateForm = () => {
    let isValid = true;
    setOrderIdError('');
    setReasonError('');
    setShowSuccessMsg('');

    if (!orderId.trim()) {
      setOrderIdError('Order ID is required.');
      isValid = false;
    }

    if (!reason.trim()) {
      setReasonError('Reason for cancellation is required.');
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    console.log('Order ID:', orderId);
    console.log('Reason for Cancellation:', reason);
    const payload = {
      order_id: orderId,
      reason_for_cancellation: reason
    };
    postData(`${process.env.REACT_APP_NODE_SERVER}/api/cancellation/create`, payload, "POST");
  };

  useEffect(() => {
    if (resData && resData.status === 200) {
      NotificationManager.success('Cancellation Request sent successfully!', '', 3000);
      setOrderId("");
      setReason("");
      setShowSuccessMsg(true);
    }
  }, [resData]);

  return (
    <div className='container pt-5 bg-white'>
      <NotificationContainer />
      <div>
        <Row className='justify-content-center'>
          <Col className="order-xl-1" xl="6">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="12" className='d-flex'>
                    <div>
                      <h3 className="mb-0">Cancel Booking</h3>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label for="orderId">Order ID</Label>
                    <Input
                      type="text"
                      id="orderId"
                      value={orderId}
                      onChange={(e) => setOrderId(e.target.value)}
                    />
                    {orderIdError && <p className='text-danger'>{orderIdError}</p>}
                  </FormGroup>
                  <FormGroup>
                    <Label for="reason">Reason for Cancellation</Label>
                    <Input
                      type="textarea"
                      id="reason"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    />
                    {reasonError && <p className='text-danger'>{reasonError}</p>}
                  </FormGroup>
                  <p className='text-danger'>{postError}</p>
                  {showSuccessMsg && <p className='text-success'>Your request has been sent to the bookings helpdesk for review.</p>}
                  <Button type="submit" color="primary" disabled={isPending}>{isPending ? "Submitting" : "Submit"}</Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
