import React, { useState, useEffect } from 'react';
import AllBookingTableSpec from './components/AllBookingTableSpec';
import { useFetch } from 'hooks/useFetch';
import { Button, Col, Input, Row, Table } from 'reactstrap';
import { Link } from 'react-router-dom';

export default function Guide() {
  const [booking_date, setBookingDate] = useState(localStorage.getItem('Date') ? JSON.parse(localStorage.getItem('Date')) : new Date().toISOString().split('T')[0]);
  const [guide, setGuide] = useState(localStorage.getItem('filters') ? JSON.parse(localStorage.getItem('filters')).filterGuide : '');
  const [slot, setSlot] = useState(localStorage.getItem('filters') ? JSON.parse(localStorage.getItem('filters')).filterSlot : '');
  const [slotName, setSlotName] = useState(localStorage.getItem('filters') ? JSON.parse(localStorage.getItem('filters')).filterSlotName : '');
  const [picnickerName, setPicnickerName] = useState(localStorage.getItem('filters') ? JSON.parse(localStorage.getItem('filters')).filterPicnickerName : '');
  const [email, setEmail] = useState(localStorage.getItem('filters') ? JSON.parse(localStorage.getItem('filters')).filteremail : '');
  const [contact, setContact] = useState(localStorage.getItem('filters') ? JSON.parse(localStorage.getItem('filters')).fitlerContact : '');
  const [data, setData] = useState(null);
  const [slotData, setSlotData] = useState(null);
  const [fetchIsPending, setFetchIsPending] = useState(true);
  const [slotFetchPending, setSlotFetchPending] = useState(true);
  const slotOrder = { "morning": 1, "late morning": 2, "afternoon": 3, "late afternoon": 4, "evening": 5, "birthday1":6,"birthday2":7 };
  useEffect(() => {
    const fetchSlotData = async () => {
      setSlotFetchPending(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_NODE_SERVER}/api/slot/getAll`);
        const result = await response.json();
        setSlotData(result);
      } catch (error) {
        console.error("Error fetching slot data:", error);
      } finally {
        setSlotFetchPending(false);
      }
    };

    const fetchData = async () => {
      setFetchIsPending(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_NODE_SERVER}/api/booking/getAll?booking_date=${booking_date}&guide=${guide}&slot_id=${slot}&name=${picnickerName}&email=${email}&contact=${contact}&slot_name=${slotName}`);
        const result = await response.json();
        //console.log(data)
        // const res = result
        result?.slotWiseCount?.sort((a, b) => (slotOrder[a.Slotname?.toLowerCase()] || 99) - (slotOrder[b.Slotname?.toLowerCase()] || 99));
        // console.log(slot)
        setData(result);
      } catch (error) {
        console.error("Error fetching booking data:", error);
      } finally {
        setFetchIsPending(false);
      }
    };

    fetchSlotData();
    fetchData();

    const intervalId = setInterval(fetchData, 3000); // Fetch data every 3 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [booking_date, guide, slot, picnickerName, email, contact, slotName]);

  const handleChangeDate = (selectedDate) => {
    localStorage.setItem("Date", JSON.stringify(selectedDate));
    setBookingDate(selectedDate);
  };
  const handleGuideChange = (selectedGuide) => {
    setGuide(selectedGuide);
  };
  const handlePicknerNameChange = (name) => {
    setPicnickerName(name);
  };
  const handlePicknerEmailChange = (email) => {
    setEmail(email);
  };
  const handleSlotChange = (selectedSlot) => {
    setSlot(selectedSlot);
  };
  const handleSlotNameChange = (slot_name) => {
    setSlotName(slot_name);
  };
  const handleContactChange = (contact) => {
    setContact(contact);
  };
  const handleReset = () => {
    setGuide('');
    setSlot('');
    setPicnickerName('');
    setEmail('');
    setContact('');
    setSlotName('');
  };

  // useEffect(() => {
  //   if (data?.slotWiseCount?.length > 0) {
  //     data?.slotWiseCount?.sort((a, b) => (slotOrder[a.Slotname?.toLowerCase()] || 99) - (slotOrder[b.Slotname?.toLowerCase()] || 99));
  //   }
  // },[data])

  return (
    <div className='py-5'>     
      {data &&
        <AllBookingTableSpec 
          data={data} 
          booking_date={booking_date} 
          guide={guide} 
          picnickerName={picnickerName}
          handleGuideChange={handleGuideChange} 
          slot={slot}
          email={email}
          contact={contact}
          slotName={slotName}
          handleSlotChange={handleSlotChange}
          handleChangeDate={handleChangeDate}
          handlePicknerNameChange={handlePicknerNameChange}
          handlePicknerEmailChange={handlePicknerEmailChange}
          handleContactChange={handleContactChange}
          handleSlotNameChange={handleSlotNameChange}
          handleReset={handleReset}
          slotData={slotData}
        />}
    </div>
  );
}
