import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import Header from "./components/Header";
import HeadcountTable from "./components/HeadCountTable";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

export default function Kitchen() {
  const [headCountData, setHeadCountData] = useState([]);
  const [todaysData,setTodaysData] = useState(null);
  const POLL_INTERVAL = 5000;

  const fetchData = () => {
    fetch(`${process.env.REACT_APP_NODE_SERVER}/api/stats/upcomingHeadcount`)
      .then((response) => response.json())
      .then((data) => {
        setTodaysData(data.upcomingHeadcount[0])
        setHeadCountData(data.upcomingHeadcount);
      })
      .catch((error) => console.error("Error fetching data: ", error));
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, POLL_INTERVAL);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div style={{background:"#fff"}}>
      {todaysData && <Header todaysData={todaysData}/>}
      <div>
        <div className="mt-5">
        {/* {headCountData.map((entry, index) => (
          <div key={index} className="col-sm-4 text-center" >
            <h2>{entry.day}</h2>
            <p>{new Date(entry.date).toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' })}</p>
            <PieChart width={400} height={400}>
              <Pie
                data={[
                  { name: "Adults", value: entry.adults_count },
                  { name: "Children", value: entry.children_count },
                ]}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={({ name, value }) => `${name} (${value})`}
                outerRadius={150}
                fill="#8884d8"
                dataKey="value"
              >
                {entry.children_count > 0 && (
                  <Cell
                    key={`children-cell-${index}`}
                    fill={COLORS[0 % COLORS.length]}
                  />
                )}
                {entry.adults_count > 0 && (
                  <Cell
                    key={`adults-cell-${index}`}
                    fill={COLORS[1 % COLORS.length]}
                  />
                )}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </div>
        ))} */}
        <HeadcountTable/>
      </div>
      </div>
    </div>
  );
}
