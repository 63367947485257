import React from 'react';

const CancellationRequest = ({ message, onClose }) => {
    return (
        <div className={`notification d-flex ${message.is_resolved === 0 ? "not-resolved-bg" : "resolved-bg"}`}>
            <div>
            <strong>Cancellation request from {message.name}</strong>
            <p><small>Slot: {message.slot_name}</small></p>
            <p><small>Date: {new Date(message.booking_date).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}</small></p>
            </div>
            {/* <button className="close-btn" onClick={onClose}>✖</button> */}
            <p className={`badge ${message.is_resolved === 0 ? "badge bg-danger text-white" :"badge-success"} ml-auto`}>{message.is_resolved === 0 ? "Unresolved" : "Resolved"}</p>
        </div>
    );
};

export default CancellationRequest;
