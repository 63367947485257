import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  CustomInput,
  Label,
  ModalFooter,
  Button,
  FormText,
  Spinner,
} from "reactstrap";
import "../spectator/Booking.css";
import { usePost } from "hooks/usePost";
import ModalComponent from "../admin/components/ModalComponent";
import { useFetch } from "hooks/useFetch";
import SchoolPicnic from "./components/SchoolPicnic";

export default function ManualBooking() {
  const { data: dayData } = useFetch(
    `${process.env.REACT_APP_NODE_SERVER}/api/day/getAll`
  );
  const [modal, setModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [childBelowTwo, setChildBelowTwo] = useState(0);
  const [adultNum, setAdultNum] = useState(1);
  const [childrenNum, setChildrenNum] = useState(0);
  const [slotData, setSlotData] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [bookingType, setBookingType] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [alreadyPaid, setAlreadyPaid] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const { postData, resData, postError, isPending } = usePost();
  const [manualEnteredAdultPrice, setManualEnteredAdultPrice] = useState(0);
  const [manualEnteredKidPrice, setManualEnteredKidPrice] = useState(0);
  const [picnicType, setPicnicType] = useState(0);
  const {
    postData: createOrder,
    resData: orderRes,
    postError: orderCreatingError,
    isPending: orderPending,
  } = usePost();
  const [isSlotFull, setIsSlotFull] = useState(false);
  const [slotsLeft, setSlotsLeft] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { data: fetchedDates } = useFetch(
    `${process.env.REACT_APP_NODE_SERVER}/api/disabledYears/getAll`
  );
  const { data: exclusiveDate } = useFetch(
    `${process.env.REACT_APP_NODE_SERVER}/api/exclusiveDates/getAll`
  ); //Gives array of dates to exclude from leaves
  const { data: inclusiveDate } = useFetch(
    `${process.env.REACT_APP_NODE_SERVER}/api/InclusiveDates/getAll`
  ); //Gives array of dates to include in leaves
  const DISABLED_MONTHS = [];
  const [DISABLED_DATES_ARRAY, setDISABLED_DATES_ARRAY] = useState(null);
  const [SPECIAL_DATES_ARRAY, setSPECIAL_DATES_ARRAY] = useState(null);
  const { data: packageData } = useFetch(
    `${process.env.REACT_APP_NODE_SERVER}/api/package/get/1`
  );
  const [bithdayPrice, setBirthdayPrice] = useState(null);
  useEffect(() => {
    if (packageData && packageData.status === 200) {
      setBirthdayPrice(packageData.package.price);
    }
  }, [packageData]);
  const [specialDates, setSpecialDates] = useState([]);
  useEffect(() => {
    if (inclusiveDate && inclusiveDate.status === 200) {
      console.log(inclusiveDate.dates);
      const convertedDates = inclusiveDate.dates.map(
        (dateObj) => new Date(dateObj.inclusive_date)
      );
      setDISABLED_DATES_ARRAY(convertedDates);
      // console.log(convertedDates)
    }
  }, [inclusiveDate]);
  useEffect(() => {
    if (exclusiveDate && exclusiveDate.status === 200) {
      //console.log(exclusiveDate.dates)
      const convertedDates = exclusiveDate.dates.map(
        (dateObj) => new Date(dateObj.exclusive_date)
      );
      setSPECIAL_DATES_ARRAY(convertedDates);
      console.log(convertedDates);
    }
  }, [exclusiveDate]);
  // const [sessionId,setSessionId] = useState('');
  const currentDate = new Date(); // Get the current date
  let currentDayOfWeek = currentDate.getDay(); // Get the current day of the week (0-6, where 0 is Sunday)
  if (currentDayOfWeek === 0) {
    // If it's Sunday, map it to 7
    currentDayOfWeek = 7;
  }

  const {
    postData: bookSlot,
    resData: bookingRes,
    postError: bookingError,
    isPending: bookingIsPending,
  } = usePost();
  const [isOpen, setIsOpen] = useState(false);

  const toggleModalComponent = () => {
    setIsOpen(!isOpen);
  };
  const isDisabledReactCalendar = ({ date, view }) => {
    // Disable Tuesdays and Wednesdays
    if (date.getDay() === 2 || date.getDay() === 3) {
      return true;
    }
    // Disable specific dates
    const disabledDates = [new Date("2024-03-10"), new Date("2024-03-18")];

    return disabledDates.some(
      (disabledDate) => date.toDateString() === disabledDate.toDateString()
    );
  };

  const toggleModal = () => {
    if (!modal) {
      setAdultNum(1);
      setChildrenNum(0);
      setChildBelowTwo(0);
      setSelectedSlot("");
      setTotalPrice(0);
      setManualEnteredAdultPrice(0);
      setManualEnteredKidPrice(0);
      setBookingType(0);
      setAlreadyPaid(0);
      setName("");
      setEmail("");
      setContact("");
    }
    setModal(!modal);
  };

  const handleCalendarClick = async (date) => {
    setPicnicType(0)
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    const dayOfWeekNumber = date.getDay() === 0 ? 7 : date.getDay();
    const dayOfWeekName = date.toLocaleDateString("en-US", { weekday: "long" });
    console.log(dayOfWeekName, dayOfWeekNumber);
    setSelectedDate({
      formattedDate: formattedDate,
      date: date,
      dayOfWeek: dayOfWeekNumber,
      dayOfWeekName: dayOfWeekName,
    });

    const response = await fetch(
      process.env.REACT_APP_NODE_SERVER +
        "/api/slotDetails/getSlotByDayForMannualBookings/" +
        dayOfWeekNumber +
        "/" +
        formattedDate
    );
    console.log(response);
    try {
      setIsLoading(true);
      if (response.status !== 200) {
        throw new Error("Something went wrong!");
      }
      const data = await response.json();
      if (data.status === 200) {
        console.log(data);
        setSlotData(data);
        setIsLoading(false);
        toggleModal();
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleRadioChange = (slot) => {
    console.log(slot);
    setSelectedSlot(slot);
  };
  const checkSlotAvailability = async () => {
    setSlotsLeft(null);
    const payload = {
      booking_date: selectedDate.formattedDate,
      max_adults: selectedSlot.max_adults,
      requestingAdults: Number(adultNum),
      slot_id: selectedSlot.slot_id,
    };
    console.log(payload);
    postData(
      process.env.REACT_APP_NODE_SERVER +
        "/api/slotDetails/checkSlotAvailability",
      payload,
      "POST"
    );
  };

  useEffect(() => {
    if (resData && resData.status === 200) {
      if (!resData.isSlotFull) {
        setIsSlotFull(resData.isSlotFull);
        if(picnicType!==1){
          calcultatePrice();
        }
      } else {
        setIsSlotFull(resData.isSlotFull);
        setSlotsLeft(resData.slotsRemaining);
      }
    }
    if (postError) {
      NotificationManager.error(postError, "Error", 3000);
    }
  }, [
    resData,
    postError,
    bookingType,
    manualEnteredAdultPrice,
    manualEnteredKidPrice,
  ]);

  useEffect(() => {
    if (selectedSlot !== "") {
      console.log(selectedSlot);
      setTotalPrice(0);
      setIsSlotFull(true);
      checkSlotAvailability();
    }
  }, [selectedSlot, adultNum, childrenNum]);

  useEffect(() => {
    console.log(alreadyPaid);
    if (alreadyPaid === "") {
      console.log(alreadyPaid);
      setAlreadyPaid(0);
    }
  }, [alreadyPaid]);
  const calcultatePrice = () => {
    if (bithdayPrice) {
      let totalPrice =
        adultNum *
          Number(
            bookingType === 0
              ? selectedSlot.adult_price
              : manualEnteredAdultPrice
          ) +
        childrenNum *
          Number(
            bookingType === 0
              ? selectedSlot.children_price
              : manualEnteredKidPrice
          );
      console.log(totalPrice);
      setTotalPrice(
        Number(totalPrice) +
          (selectedSlot.slot_id === 60 || selectedSlot.slot_id === 61
            ? bithdayPrice
            : 0)
      );
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const payload = {
      user_id: Number(contact),
      slot_id: Number(selectedSlot.slot_id),
      adults_count: adultNum,
      children_count: childrenNum,
      below_2_year_count: Number(childBelowTwo),
      joy_ride_count: 0,
      joy_ride_children_count: 0,
      adult_joy_ride_total: 0,
      children_joy_ride_total: 0,
      order_id: "manual " + new Date(),
      payment_id: "manual " + new Date(),
      single_adult_price:
        bookingType === 0 ? selectedSlot.adult_price : manualEnteredAdultPrice,
      single_child_price:
        bookingType === 0 ? selectedSlot.children_price : manualEnteredKidPrice,
      total_cost: totalPrice,
      advance_paid: Number(alreadyPaid),
      amount_paid: Number(alreadyPaid),
      amount_pending: totalPrice - Number(alreadyPaid),
      booking_day: currentDayOfWeek,
      booking_date: selectedDate.formattedDate,
      is_birthday:
        selectedSlot.slot_id === 60 || selectedSlot.slot_id === 61 ? 1 : 0,
      is_premium: bookingType === 2 ? 1 : 0,
      name: name,
      email: email,
      contact: contact,
      is_special_day: false,
      grade: 0,
      booking_type: 0,
      support_staff_count: 0,
      with_food: 0,
      is_discount: 0,
      discount_amount:0,
      archive: 0,
    };

    if (
      selectedSlot === "" ||
      totalPrice === 0 ||
      name === "" ||
      email === "" ||
      contact === "" ||
      isSlotFull === true
    ) {
      NotificationManager.error(
        "Please fill the required details!",
        "Error",
        3000
      );
      return;
    }
    // return console.log(payload)
    bookSlot(
      process.env.REACT_APP_NODE_SERVER + "/api/booking/create-manual-booking",
      payload,
      "POST"
    );
  };
  const handlePicnicBooking = (payload) => {
    if (
      selectedSlot === "" ||
      totalPrice === 0 ||
      name === "" ||
      email === "" ||
      contact === ""
    ) {
      console.log(selectedSlot)
      if (picnicType == 0 && isSlotFull) {
        NotificationManager.error("Please fill the required details!", "Error", 3000);
        return;
      }
      
      // For non-zero picnicType or when slot is not full, still show the error
      NotificationManager.error("Please fill the required details!", "Error", 3000);
      return;
    }
    // return console.log(payload)
    bookSlot(
      process.env.REACT_APP_NODE_SERVER + "/api/booking/create-manual-booking",
      payload,
      "POST"
    );
  };
  useEffect(() => {
    if (bookingRes && bookingRes.status === 200) {
      // sendEmail(bookingRes.booking_id);
      setModal(false);
      NotificationManager.success("Order Placed Successfully!", "", 3000);
      setTimeout(()=>{
        window.location.reload();
      },[1000])
    }
    if (bookingError) {
      NotificationManager.error(bookingError, "", 3000);
    }
  }, [bookingRes, bookingError]);

  const sendEmail = (booking_id) => {
    postData(
      process.env.REACT_APP_NODE_SERVER + "/api/payment/mannualBookingWebhook",
      { booking_id },
      "POST"
    );
  };
  const handleSelectedSlotChange = (slot) => {
    setSelectedSlot(slot);
  };
  const handleTotalPriceChange = (total) => {
    setTotalPrice(total);
  };
  const handleNameChange = (name) => {
    setName(name);
  };
  const handleEmailChange = (email) => {
    setEmail(email);
  };
  const handleContactChange = (contact) => {
    setContact(contact);
  };
  const handleBookingType = (type) => {
    setBookingType(type);
  };
  const modalBody = () => {
    return (
      <div>
        1. Once booked, guests will be allowed entry only up-to 45mins past
        their slot entry time and any arrivals later than this will be treated
        as a cancellation with no refund.2. Weekend prices will be applicable on
        public holidays and other special days
      </div>
    );
  };
  const modalTitle = () => {
    return <div>Terms and Conditions</div>;
  };
  useEffect(() => {
    // Simulated fetch from backend API
    const fetchSpecialDates = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NODE_SERVER}/api/specialDates/getAll`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch special dates");
        }
        //console.log(response, "res");
        const data = await response.json();
        //console.log(data, "special data");
        setSpecialDates(data.special_dates);
      } catch (error) {
        console.error("Error fetching special dates:", error);
      }
    };

    fetchSpecialDates();
  }, []);

  useEffect(() => {
    if (SPECIAL_DATES_ARRAY) {
      const mapped = SPECIAL_DATES_ARRAY.map(
        (each) => new Date(each).toISOString().split("T")[0]
      );
      console.log(mapped, "mapped");
    }
  }, [SPECIAL_DATES_ARRAY]);
  return (
    <div className="container py-8 d-flex justify-content-center align-items-center">
      <NotificationContainer />
      {dayData &&
        fetchedDates &&
        SPECIAL_DATES_ARRAY &&
        DISABLED_DATES_ARRAY &&
        DISABLED_MONTHS && (
          <Calendar
            tileContent={({ date }) => {
              const today = new Date();
              today.setUTCHours(0, 0, 0, 0); // Set hours to 0 in UTC to compare dates without time

              // Format the date to match from_date/to_date format in UTC
              const formattedDate = new Date(
                Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
              )
                .toISOString()
                .split("T")[0];

              if (date < today) {
                return null;
              }

              // Check if the date is within the range of any special date
              const specialDateInfo = specialDates.find((specialDate) => {
                const fromDate = new Date(
                  Date.UTC(
                    new Date(specialDate.from_date).getFullYear(),
                    new Date(specialDate.from_date).getMonth(),
                    new Date(specialDate.from_date).getDate()
                  )
                )
                  .toISOString()
                  .split("T")[0];
                const toDate = new Date(
                  Date.UTC(
                    new Date(specialDate.to_date).getFullYear(),
                    new Date(specialDate.to_date).getMonth(),
                    new Date(specialDate.to_date).getDate()
                  )
                )
                  .toISOString()
                  .split("T")[0];
                return formattedDate >= fromDate && formattedDate <= toDate;
              });

              const isSpecialDate = specialDateInfo !== undefined;
              const isMarkedAsRegular = specialDateInfo?.mark_as_regular;
              // Check if the date is in SPECIAL_DATES_ARRAY
              const isSpecialDate1 = SPECIAL_DATES_ARRAY.some((specialDate) => {
                return (
                  new Date(
                    Date.UTC(
                      new Date(specialDate).getFullYear(),
                      new Date(specialDate).getMonth(),
                      new Date(specialDate).getDate()
                    )
                  )
                    .toISOString()
                    .split("T")[0] === formattedDate
                );
              });
              // Check if the date is disabled
              const isDisabledDate = DISABLED_DATES_ARRAY.some(
                (disabledDate) => {
                  const disabledFormattedDate = new Date(
                    Date.UTC(
                      disabledDate.getFullYear(),
                      disabledDate.getMonth(),
                      disabledDate.getDate()
                    )
                  )
                    .toISOString()
                    .split("T")[0];
                  return disabledFormattedDate === formattedDate;
                }
              );

              if (isDisabledDate) {
                return null;
              }

              const dayOfWeek = date.getDay();
              const adjustedDayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;
              const daydata = dayData?.days.find(
                (day) => day.day_id === adjustedDayOfWeek
              );

              if (daydata && daydata.is_weekly_off === 1) {
                const isSpecialDate1 = SPECIAL_DATES_ARRAY.some(
                  (specialDate) => {
                    return (
                      new Date(
                        Date.UTC(
                          new Date(specialDate).getFullYear(),
                          new Date(specialDate).getMonth(),
                          new Date(specialDate).getDate()
                        )
                      )
                        .toISOString()
                        .split("T")[0] === formattedDate
                    );
                  }
                );

                if (!isSpecialDate1) {
                  return null;
                }
              }

              const customMonth = date.getUTCMonth() + 1; // JavaScript months are 0-indexed
              if (DISABLED_MONTHS.includes(customMonth)) {
                return null;
              }
              //Check for disabled dates
              let isAfterFetchedDateDisabled = false;
              if (fetchedDates.dates.length > 0) {
                const lastDateOf2024 = new Date(fetchedDates.dates[0].date);
                // console.log(lastDateOf2024,"last date after",new Date(2024, 11, 31))
                if (date >= lastDateOf2024) {
                  isAfterFetchedDateDisabled = true;
                } else {
                  isAfterFetchedDateDisabled = false;
                }
              }
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  {isAfterFetchedDateDisabled ? null : isSpecialDate ||
                    isSpecialDate1 ? (
                    <span
                      className={
                        isMarkedAsRegular === 1
                          ? "text-primary"
                          : isMarkedAsRegular === 2
                          ? "text-warning"
                          : "text-success"
                      }
                    >
                      <small>
                        {isMarkedAsRegular === 1
                          ? "Reg."
                          : isMarkedAsRegular === 2
                          ? "Prem."
                          : "Spec."}
                      </small>
                    </span>
                  ) : (
                    <span className="text-primary">
                      <small>Reg.</small>
                    </span>
                  )}
                </div>
              );
            }}
            tileDisabled={({ date }) => {
              // Convert JavaScript month (0-indexed) to custom month (1-indexed)
              const customMonth = date.getMonth() + 1;

              // Check if the date is within any of the disabled months
              const isDisabledMonth = DISABLED_MONTHS.includes(customMonth);

              if (isDisabledMonth) {
                return true; // Disable the tile for the entire disabled month
              }

              // Check if the date is in the disabled dates array
              const isDisabledDate = DISABLED_DATES_ARRAY.some(
                (disabledDate) => {
                  // Compare dates without considering time
                  return disabledDate.toDateString() === date.toDateString();
                }
              );

              if (isDisabledDate) {
                return true; // Disable the tile for disabled dates
              }

              const dayOfWeek = date.getDay(); // Get the day of the week (0-6, where 0 is Sunday)
              // Adjust dayOfWeek to match your day_id (1-7, where 1 is Monday and 7 is Sunday)
              const adjustedDayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;
              const daydata = dayData?.days.find(
                (day) => day.day_id === adjustedDayOfWeek
              );
              // If dayData exists and is_weekly_off is 1, disable the tile
              if (daydata && daydata.is_weekly_off === 1) {
                // Check if the date is a special date, if yes, don't disable it
                const isSpecialDate = SPECIAL_DATES_ARRAY.some(
                  (specialDate) => {
                    // Compare dates without considering time
                    return specialDate.toDateString() === date.toDateString();
                  }
                );
                return !isSpecialDate;
              }

              // Disable dates beyond December 31, 2024

              if (fetchedDates.dates.length > 0) {
                const lastDateOf2024 = new Date(fetchedDates.dates[0].date);
                // console.log(lastDateOf2024,"last date after",new Date(2024, 11, 31))
                if (date >= lastDateOf2024) {
                  return true;
                }
              }

              return false; // Enable the tile for non-weekly off days
            }}
            onClickDay={handleCalendarClick}
            minDate={new Date()}
          />
        )}
      <Modal
        isOpen={modal}
        toggle={toggleModal}
        className="booking-details-modal"
      >
        <ModalHeader toggle={toggleModal} tag="h3">
          Booking Date{" "}
          {selectedDate
            ? `${selectedDate.date.getDate().toString().padStart(2, "0")}/${(
                selectedDate.date.getMonth() + 1
              )
                .toString()
                .padStart(2, "0")}/${selectedDate.date.getFullYear()}`
            : ""}
        </ModalHeader>
        {slotData && slotData.slots.length === 0 && (
          <div className="ml-3 mb-5">
            <h2 className="text-warning">Sorry no slots are available!</h2>
            <p>Please select another date.</p>
          </div>
        )}
        {slotData && slotData.slots.length > 0 && (
          <Form role="form" onSubmit={handleFormSubmit}>
            <ModalBody>
              <h1 className="text-info">
                Booking Type:{" "}
                <span style={{ textDecoration: "underline" }}>
                  {picnicType === 0
                    ? "Picnic Booking"
                    : "School Picnic Booking"}
                </span>
              </h1>
              <FormGroup className="mb-3">
                <Label for="numberOfAdults">
                  <small>Booking Type</small>
                </Label>
                <InputGroup className="input-group-alternative">
                  <Input
                    type="select"
                    onChange={(e) => setPicnicType(Number(e.target.value))}
                    // style={{
                    //   fontWeight: "bold",
                    //   fontSize: "18px",
                    // }}
                  >
                    <option value="0">Picnic Booking</option>
                    <option value="1">School Picnic Booking</option>
                  </Input>
                </InputGroup>
              </FormGroup>
              {picnicType === 0 && (
                <>
                  <div className="row">
                    <FormGroup className="mb-3 col-sm-4">
                      <Label for="numberOfAdults">
                        <small>Big people (10 years plus)</small>
                      </Label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Number of adults"
                          type="number"
                          name="numberOfAdults"
                          value={adultNum}
                          onChange={(e) => setAdultNum(e.target.value)}
                          min="1"
                        />
                      </InputGroup>
                      {/* <FormText>
                  10 years and above
                </FormText> */}
                    </FormGroup>
                    <FormGroup className="mb-3 col-sm-4">
                      <Label for="numberOfAdults">
                        <small>Lil People (2-10 years)</small>
                      </Label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Number of children"
                          type="number"
                          name="numberOfChildren"
                          value={childrenNum}
                          onChange={(e) => setChildrenNum(e.target.value)}
                          min="0"
                        />
                      </InputGroup>
                      {/* <FormText>
                  2-10 years old
                </FormText> */}
                    </FormGroup>
                    <FormGroup className="mb-3 col-sm-4">
                      <Label for="numberOfAdults">
                        <small>Below 2 years</small>
                      </Label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Number of children below 2"
                          type="number"
                          name="numberOfChildren"
                          value={childBelowTwo}
                          onChange={(e) => setChildBelowTwo(e.target.value)}
                          min="0"
                        />
                      </InputGroup>
                      <FormText>Free Entry</FormText>
                    </FormGroup>
                    {
                      <FormGroup className="mb-3 ml-3">
                        <Label for="exampleCheckbox">
                          <small>Select Slot Timings</small>
                        </Label>
                        <div>
                          {slotData &&
                            slotData.slots.map((slot) => {
                              if (slot.slot_type == 1) {
                                // Convert from 24-hour format to 12-hour format
                                const fromTime12Hour = new Date(
                                  `2000-01-01T${slot.from_time}`
                                ).toLocaleTimeString("en-US", {
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                });
                                const toTime12Hour = new Date(
                                  `2000-01-01T${slot.to_time}`
                                ).toLocaleTimeString("en-US", {
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                });
  
                                return (
                                  <CustomInput
                                    key={slot.slot_id}
                                    type="radio"
                                    id={slot.slot_id}
                                    name={slot.slot_name}
                                    label={
                                      <span>
                                        <strong>{slot.slot_name}</strong> -{" "}
                                        {fromTime12Hour} to {toTime12Hour}{" "}
                                        <span
                                          className="pt-0 ml-1"
                                          style={{ top: "0" }}
                                        >
                                          {slot.available_adults > 0 ? (
                                            <span
                                              className="text-success top-0"
                                              style={{ top: "0" }}
                                            >
                                              ({slot.available_adults} Adult slots
                                              are remaining)
                                            </span>
                                          ) : (
                                            <span
                                              className="text-danger"
                                              style={{ top: "0" }}
                                            >
                                              (No slots are available)
                                            </span>
                                          )}
                                        </span>
                                      </span>
                                    }
                                    checked={
                                      Number(selectedSlot.slot_id) ===
                                      slot.slot_id
                                    }
                                    onChange={() => handleRadioChange(slot)}
                                    // inline
                                  />
                                );
                            }
                            })}
                        </div>
                      </FormGroup>
                    }
                  </div>
                  {selectedSlot && (
                    <div>
                      <small>Select Booking Type</small>
                      <FormGroup>
                        <CustomInput
                          type="radio"
                          id={0}
                          name={`regular`}
                          label={<span>Regular</span>}
                          checked={bookingType === 0}
                          onChange={() => handleBookingType(0)}
                          inline
                        />
                        {/* <CustomInput
                        type="radio"
                        id={1}
                        name={`birthday`}
                        label={
                          <span>Birthday</span>
                        }
                        checked = {bookingType === 1}
                        onChange={()=> handleBookingType(1)}
                        inline
                      /> */}
                        <CustomInput
                          type="radio"
                          id={2}
                          name={`premium`}
                          label={<span>Premium</span>}
                          checked={bookingType === 2}
                          onChange={() => handleBookingType(2)}
                          inline
                        />
                      </FormGroup>
                    </div>
                  )}
                  {bookingType > 1 && (
                    <div className="row">
                      <div className="col-sm-6">
                        <Label>
                          <small>Enter Single Adult Price</small>
                        </Label>
                        <FormGroup>
                          <Input
                            type="number"
                            placeholder="Adult Price"
                            value={manualEnteredAdultPrice}
                            onChange={(e) =>
                              setManualEnteredAdultPrice(e.target.value)
                            }
                          />
                        </FormGroup>
                      </div>
                      <div className="col-sm-6">
                        <Label>
                          <small>Enter Single Kid Price</small>
                        </Label>
                        <FormGroup>
                          <Input
                            type="number"
                            placeholder="Adult Price"
                            value={manualEnteredKidPrice}
                            onChange={(e) =>
                              setManualEnteredKidPrice(e.target.value)
                            }
                          />
                        </FormGroup>
                      </div>
                    </div>
                  )}
                  <div>
                    {isPending && (
                      <div className="text-center mt-3">
                        {" "}
                        <Spinner
                          color="primary"
                          style={{ height: "3rem", width: "3rem" }}
                        ></Spinner>
                      </div>
                    )}

                    {totalPrice > 0 && !isPending && (
                      <>
                        <p>Booking Summary:</p>
                        <div className="table-responsive">
                          <table className="align-items-center table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">People</th>
                                <th scope="col">No. of People</th>
                                <th scope="col">Cost Per Person</th>
                                <th scope="col">Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Big People</td>
                                <td>{adultNum}</td>
                                <td>
                                  ₹
                                  {bookingType === 0
                                    ? selectedSlot.adult_price
                                    : manualEnteredAdultPrice}
                                </td>
                                <td className="text-success">
                                  ₹
                                  {Number(
                                    adultNum *
                                      (bookingType === 0
                                        ? selectedSlot.adult_price
                                        : manualEnteredAdultPrice)
                                  )}
                                </td>
                              </tr>
                              {childrenNum > 0 && (
                                <tr>
                                  <td>Lil People</td>
                                  <td>{childrenNum}</td>
                                  <td>
                                    ₹
                                    {bookingType === 0
                                      ? selectedSlot.children_price
                                      : manualEnteredKidPrice}
                                  </td>
                                  <td className="text-success">
                                    ₹
                                    {Number(
                                      childrenNum *
                                        (bookingType === 0
                                          ? selectedSlot.children_price
                                          : manualEnteredKidPrice)
                                    )}
                                  </td>
                                </tr>
                              )}
                              {(selectedSlot.slot_id === 60 ||
                                selectedSlot.slot_id === 61) &&
                                bithdayPrice && (
                                  <tr>
                                    <td colSpan="3">Celebration</td>
                                    <td className="text-success">
                                      ₹{bithdayPrice}
                                    </td>
                                  </tr>
                                )}
                              <tr>
                                <td colSpan="3">Already Paid</td>
                                <td className="text-success">₹{alreadyPaid}</td>
                              </tr>
                              <tr>
                                <td colSpan="3">Pending Amount</td>
                                <td className="text-danger">
                                  ₹{totalPrice - alreadyPaid}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="3">Final Total</td>
                                <td className="text-success">
                                  ₹ {totalPrice}
                                  {/* {Number(adultNum * (bookingType === 0 ? selectedSlot.adult_price : manualEnteredAdultPrice)) +
                                Number(
                                  childrenNum * (bookingType === 0 ? selectedSlot.children_price : manualEnteredKidPrice)
                                )} */}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        {/* <h4 className="text-success">
                    This will be a 50% (₹{totalPrice / 2}) advance payment. You
                    may pay the remaining 50% (₹{totalPrice / 2}) in cash or via
                    UPI when you arrive at the farm.
                  </h4> */}
                      </>
                    )}
                    {isSlotFull && (
                      <h4 className="text-danger">
                        {slotsLeft > 0
                          ? `Only ${slotsLeft} adult slot(s) is available`
                          : `No slots are available`}
                      </h4>
                    )}
                  </div>
                  {totalPrice > 0 && (
                    <>
                      <hr />
                      <h4>Fill the following details</h4>
                      <div className="row">
                        <FormGroup className="mb-3 col-sm-6">
                          <Label for="name">
                            <small>Name</small>
                          </Label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              placeholder="Your name"
                              type="text"
                              name="name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-3 col-sm-6">
                          <Label for="email">
                            <small>Email</small>
                          </Label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              placeholder="Your email"
                              type="email"
                              name="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-3 col-sm-6">
                          <Label for="contact">
                            <small>Contact</small>
                          </Label>
                          <InputGroup className="input-group-alternative">
                            <div
                              className="input-group-text"
                              style={{ background: "#eee" }}
                            >
                              +91
                            </div>
                            <Input
                              placeholder=" Contact number"
                              type="tel"
                              name="contact"
                              value={contact}
                              onChange={(e) => setContact(e.target.value)}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-3 col-sm-6">
                          <Label for="amountPaid">
                            <small>Amount Already Paid</small>
                          </Label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              placeholder=" Amount Paid"
                              type="text"
                              name="amountPaid"
                              value={alreadyPaid}
                              onChange={(e) => setAlreadyPaid(e.target.value)}
                            />
                          </InputGroup>
                        </FormGroup>
                      </div>
                    </>
                  )}
                </>
              )}
              {picnicType === 1 && (
                <SchoolPicnic
                  slotData={slotData}
                  selectedDate={selectedDate}
                  handlePicnicBooking={handlePicnicBooking}
                  currentDayOfWeek={currentDayOfWeek}
                  handleSelectedSlotChange={handleSelectedSlotChange}
                  selectedSlot={selectedSlot}
                  totalPrice={totalPrice}
                  name={name}
                  email={email}
                  contact={contact}
                  handleTotalPriceChange={handleTotalPriceChange}
                  handleNameChange={handleNameChange}
                  handleEmailChange={handleEmailChange}
                  handleContactChange={handleContactChange}
                  toggleModal={toggleModal}
                />
              )}
            </ModalBody>
            {picnicType === 0 && (
              <ModalFooter>
                {!orderPending && (
                  <Button className="my-4" color="primary" type="submit">
                    Book Now
                  </Button>
                )}
                {orderPending && (
                  <Button
                    className="my-4"
                    color="primary"
                    disabled
                    type="button"
                  >
                    Booking...
                  </Button>
                )}
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>
            )}
          </Form>
        )}
      </Modal>
      <ModalComponent
        isOpen={isOpen}
        toggleModalComponent={toggleModalComponent}
        body={modalBody()}
        title={modalTitle()}
      />
      {/* <ModalComponent isOpen={isOpen} toggleModalComponent={toggleModalComponent} body={modalBody2()} title={modalTitle2()}/> */}
    </div>
  );
}
